import {dajxHttp} from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc  [商城模块]我的优惠券
 * @param type 类型 默认1:未使用 2:已使用 3:已过期
 * @param pageSize 分页显示数据条数 默认10
 * @param page 分页页码 默认1
 * @returns {Promise<*>}
 {
    "code": 1,
    "message": "success!",
    "data": {
        "totalPage": 1,
        "currentPage": 1,
        "info": [{
            // 优惠券ID
            "id": "8",
            //开始使用时间
            "use_start_time": "1605715200",
            //结束使用时间
            "use_end_time": "1606723614",
            //类型 1:无门槛 2:满减 3:折扣
            "type": "1",
            //备注
            "remarks": "",
            //优惠券金额(折扣券为折扣,例0.85表示8.5折)
            "coupon_amount": "1",
            //优惠券使用限制金额(无门槛或折扣券始终为0,满减券为满减条件)
            "coupon_limit_amount": 0,
            //优惠券子码ID数组(多个)
            "coupon_children_ids": ["17"],
            //优惠券关联的商品ID数组(多个)
            "__coupon_product_ids": "i",
            "coupon_product_ids": ["120"]
        }]
    }
}
 */
export async function shopCouponList(type,pageSize,page) {
  return await post({
    url: '/api/shop-coupon/list',
    data: {
      "type":type,
      "pageSize":pageSize,
      "page":page,
    }
  });
}

/**
 * @desc  [商城模块]领取优惠券
 * @param id 优惠券ID(商品列表领取优惠券可传本参数,通过优惠券ID领取优惠券)(id和code参数请根据业务场景使用其中任意一个参数,如果2个参数同时存在,id参数的优先级高于code,code参数无效,会被接口忽略)
 * @param code 优惠券子码(优惠券子码兑换优惠券,可传本参数,通过优惠券子码领取优惠券)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "coupon_children_id": 33631
    }
  }
}
 */
export async function shopCouponPick(id,code) {
  return await post({
    url: '/api/shop-coupon/pick',
    data: {
      "id":id,
      "code":code,
    }
  });
}
