import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/user';
import * as apis from '../../../api/scms/user';

export const infos = {

  'getUserConchLog': {
    '__proto__': base.getUserConchLog,
    'api': apis.getUserConchLog,
    'desc': "获取用户贝壳明细列表",
    'url': '/scmsapi/user/getUserConchLog',
    'defaults': {
      page: '',
      page_size: '',
      uid: '',
      type: '',
      create_time_start: '',
      create_time_end: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'exportUserConchLogList': {
    '__proto__': base.exportUserConchLogList,
    'api': apis.exportUserConchLogList,
    'desc': "导出用户贝壳明细列表(本接口请采用get请求，请求参数与/scmsapi/user/getUserConchLog接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserConchLogList',
    'defaults': {
      page: '',
      page_size: '',
      uid: '',
      type: '',
      create_time_start: '',
      create_time_end: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取用户列表",
    'url': '/scmsapi/user/list',
    'defaults': {
      page: '',
      page_size: '',
      keyword: '',
      uid: '',
      name: '',
      phone: '',
      startTime: '',
      endTime: '',
      sex: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'edit': {
    '__proto__': base.edit,
    'api': apis.edit,
    'desc': "编辑用户信息",
    'url': '/scmsapi/user/edit',
    'defaults': {
      uid: '',
      nickname: '',
      remark_name: '',
      sex: '',
      birthday: '',
      phone: '',
      status: '',
      time: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "获取用户信息",
    'url': '/scmsapi/user/info',
    'defaults': {
      uid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getUserIntegralLog': {
    '__proto__': base.getUserIntegralLog,
    'api': apis.getUserIntegralLog,
    'desc': "获取用户积分明细列表",
    'url': '/scmsapi/user/getUserIntegralLog',
    'defaults': {
      page: '',
      page_size: '',
      uid: '',
      type: '',
      order_no: '',
      create_time_start: '',
      create_time_end: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': "删除用户",
    'url': '/scmsapi/user/del',
    'defaults': {
      uid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'exportUserList': {
    '__proto__': base.exportUserList,
    'api': apis.exportUserList,
    'desc': "导出用户列表(本接口请采用get请求，请求参数与/scmsapi/user/list接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserList',
    'defaults': {
      page: '',
      page_size: '',
      keyword: '',
      uid: '',
      name: '',
      phone: '',
      startTime: '',
      endTime: '',
      sex: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'exportUserIntegralLogList': {
    '__proto__': base.exportUserIntegralLogList,
    'api': apis.exportUserIntegralLogList,
    'desc': "导出用户积分明细列表(本接口请采用get请求，请求参数与/scmsapi//user/getUserIntegralLog接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserIntegralLogList',
    'defaults': {
      page: '',
      page_size: '',
      uid: '',
      type: '',
      order_no: '',
      create_time_start: '',
      create_time_end: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'changeConch': {
    '__proto__': base.changeConch,
    'api': apis.changeConch,
    'desc': "管理员修改用户贝壳值\n",
    'url': '/scmsapi/user/changeConch',
    'defaults': {
      uid: '',
      delta: '',
      comment: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
