import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/admin';
import * as apis from '../../../api/scms/admin';

export const infos = {

  'register': {
    '__proto__': base.register,
    'api': apis.register,
    'desc': "注册用户",
    'url': '/scmsapi/admin/register',
    'defaults': {
      username: '',
      password: '',
      confirm_pwd: '',
      nickname: '',
      role_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'index': {
    '__proto__': base.index,
    'api': apis.index,
    'desc': "用户列表",
    'url': '/scmsapi/admin/index',
    'defaults': {
      id: '',
      status: '',
      username: '',
      role_id: '',
      page: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updateStatus': {
    '__proto__': base.updateStatus,
    'api': apis.updateStatus,
    'desc': "用户启用禁用",
    'url': '/scmsapi/admin/updateStatus',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'resetPwd': {
    '__proto__': base.resetPwd,
    'api': apis.resetPwd,
    'desc': "重置密码-密码变为123456",
    'url': '/scmsapi/admin/resetPwd',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': "用户删除",
    'url': '/scmsapi/admin/delete',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'adminGetSupplierList': {
    '__proto__': base.adminGetSupplierList,
    'api': apis.adminGetSupplierList,
    'desc': " 获取供应商列表",
    'url': '/scmsapi/admin/getSupplierList',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'uploadImage': {
    '__proto__': base.uploadImage,
    'api': apis.uploadImage,
    'desc': " 图片上传接口\n注意:本接口实际上是请求的[客户端api项目]提供的/upload/image接口,\n所以接口的请求参数和返回参数，需要以[客户端api项目]的/upload/image接口文档为准",
    'url': '/scmsapi/upload/image',
    'defaults': {
      file_base64: '',
      scene: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
