import { dajxHttp } from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc 数据 checks
 *
 * @param filters 支持的过滤项：'bag', 'user', 'sql'; 如果配置了，则只检查指定的项
 * @returns
 */
export function check(filters) {
  let params = {
    url: "/api/blindbox/test/check",
    data: {filters}
  };
  return post(params);
}

/**
 * @desc 系统崩溃后的数据修复
 *
 * @returns
 */
export function fix() {
  let params = {
    url: "/api/blindbox/test/fix",
    data: {}
  };
  return post(params);
}
