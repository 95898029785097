import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc 所有角色
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": [{
        "id": 0, // 角色id,
        "title": "供货商",
        "mark": "supplier_mark" // 唯一标记
    }]
}
 */
export function getRoles() {
  let params = {
    url: '/scmsapi/role/getRoles',
    data: {}
  };
  return post(params);
}

/**
 * @desc 角色对应菜单
 * @param id 角色id
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": [{
        "id": 1,
        "title": "菜单名",
        "mark": "菜单标记",
        "checked": true, // 对应角色菜单是否被选中 true=选中 false=未选中
    }]
}
 */
export function menu(id) {
  let params = {
    url: '/scmsapi/role/menu',
    data: {
      id
    }
  };
  return post(params);
}


/**
 * @desc 开关角色对应菜单
 * @param role_id 角色id
 * @param menu_id 菜单id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function setMenu(role_id,menu_id) {
  let params = {
    url: '/scmsapi/role/setMenu',
    data: {
      role_id,
      menu_id
    }
  };
  return post(params);
}
