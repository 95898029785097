import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc  获取省份列表
 * @returns {Promise<unknown>}
 {
  "code": 200,
  "msg": "success",
  "data": [
    {
      // 省份ID
      "id": 334,
      // 省份名称
      "city": "上海市"
    }
  ]
}
 */
export function cityGetProvinceList() {
  let params = {
    url: '/scmsapi/city/getProvinceList',
    data: {}
  };
  return post(params);
}
