import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/blindbox/test';
import * as apis from '../../../../api/client/blindbox/test';

export const infos = {

  'check': {
    '__proto__': base.check,
    'api': apis.check,
    'desc': "数据 checks\n",
    'url': '/api/blindbox/test/check',
    'defaults': {
      filters: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'fix': {
    '__proto__': base.fix,
    'api': apis.fix,
    'desc': "系统崩溃后的数据修复\n",
    'url': '/api/blindbox/test/fix',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
