import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc  创建商品分类
 * @param name 分类名称
 * @param sort 排序序号
 * @param product_category_image 商品分类图片
   product_category_image: {
       // 图片key[/upload/image接口上传图片后获得]
       "file_key": "xxx",
       // 图片可访问路径[/upload/image接口上传图片后获得]
       "url": "xxx"
   },
 * @returns {Promise<*>}
 {
    "code": 200,
    "msg": "success",
    "data": {
        "id": 2,
    }
}
 */
export function create(name,sort,product_category_image) {
    let params = {
        url: '/scmsapi/product_category/create',
        data: {
            "name":name,
            "sort":sort,
            "product_category_image":product_category_image,
        }
    };
    return post(params);
}

/**
 * @desc  获取商品分类列表
 * @param id 商品类型id
 * @param name 分类名称(模糊查询)
 * @param page 分页页码 默认1
 * @param page_size 分页每页数据条数 默认10
 * @param status 状态 1=启用 2=禁用
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 5,
    "per_page": 15,
    "current_page": 1,
    "last_page": 1,
     "__data": "k",
    "data": [
      {
        // 分类ID
        "id": 1,
        //分类名称
        "name": "日用品",
        //排序序号
        "sort": 1,
        //状态 1=启用 2=禁用
        "status": 2,
        //创建时间
        "create_time": "2021-03-08 12:26:04",
        //创建者
        "creator": {
            //创建者账号
          "username": "admin",
            //创建者角色
          "role_name": "超级管理员"
        },
        // 商品分类图片
        product_category_image: "https://admin.bb.uninote.com.cn/oss?path=xxx3"
      }
    ]
  }
}
 */
export function list(id,name,page,page_size,status) {
    let params = {
        url: '/scmsapi/product_category/list',
        data: {
            "id":id,
            "name":name,
            "page":page,
            "page_size":page_size,
            "status":status,
        }
    };
    return post(params);
}

/**
 * @desc  编辑商品分类
 * @param name 分类名称
 * @param sort 排序序号
 * @param id 分类ID
 * @param product_category_image 商品分类图片
 product_category_image: {
       // 图片key,新增的图片必传[/upload/image接口上传图片后获得],原商品分类的图片可传空字符串
       "file_key": "xxx",
       // 图片可访问路径,新增的图片从[/upload/image接口上传图片后获得],原商品分类的图片从商品分类列表信息[/product_category/list]接口获得
       "url": "xxx"
   },
 * @returns {Promise<*>}
 {
    "code": 200,
    "msg": "success",
    "data": null
}
 */
export function update(name,sort,id,product_category_image) {
    let params = {
        url: '/scmsapi/product_category/update',
        data: {
            "name":name,
            "sort":sort,
            "id":id,
            "product_category_image":product_category_image,
        }
    };
    return post(params);
}

/**
 * @desc  更新商品分类状态(启用|禁用|删除)
 * @param status 状态 1=启用 2=禁用 3=删除
 * @param id 分类ID
 * @returns {Promise<*>}
 {
    "code": 200,
    "msg": "success",
    "data": null
}
 */
export function updateStatus(status,id) {
    let params = {
        url: '/scmsapi/product_category/updateStatus',
        data: {
            "status":status,
            "id":id,
        }
    };
    return post(params);
}
