import {dajxHttp} from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc  [商城模块]商品详情
 * @param id 商品id
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
       // 商品ID
      "id": "12046",
      "title": "猪头肉",
      "description": "xxxxxxxxxxxxxxxxxxxxxxxx",
      "unit": "个",
      // 发货模式 1:现货发货模式 2:预售发货模式
      "shipping_mode": "1",
      // 发货天数
      "shipping_days": "3",
      // 是否支持7天无理由退款 1:支持 2:不支持
      "is_7day_return_supported": "1",
       // 商品图片
      "productImages": [
        {
           // 	图片类型 1=商品入户图 2=商品bannner图 3=商品介绍图
          "type": "1",
          "url": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2020/12/f1cecad5ef235345f04934a9bacef0ef.png"
        }
      ],
      // 	单价最低的Sku
      "productSkuByMinPrice": {
        // 商品SKUID
        "id": "3741",
        // 库存
        "inventory_quantity": "10",
        // sku 图片
        "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg",
        // 	商品SKU的规格，包含多个对象，每个对象字段如展开所示
        "product_spec": [
          {
            // 规格名称对象
            "product_spec_key": {
              // 规格名称ID
              "id": 513,
              // 	规格名称
              "title": "重量"
            },
            // 规格种类对象
            "product_spec_value": {
              // 规格种类ID
              "id": 935,
              // 	规格种类
              "value": "1KG"
            }
          }
        ],
        // 商品SKU价格
        "price": "1"
      },
      // 	商品促销活动
      "productPromotion": {
        // 促销活动ID
        "id": "12",
        // 开始时间
        "start_time": "1607443200",
        // 结束时间
        "end_time": "1609344000",
        //商品促销活动sku
        "productPromotionSku": [
          {
            // 商品skuid
            "product_sku_id": "3741",
            // 促销价格
            "promotion_price": "12300",
            //促销数量
            "promotion_number": "123",
            //实际销售数量
            "used_number": "6"
          }
        ]
      },
      // 	优惠券使用说明
      "coupon_text": {
        // 标题
        "title": "优惠券使用说明",
        //内容
        "text_list": [
          "1、每位用户可以领取多张优惠券；"
        ]
      },
      // 优惠卷列表，包含多个对象，每个对象字段如展开所示
      "coupon_list": [
        {
          // 优惠卷ID
          "id": "324",
          // 开始使用时间
          "use_start_time": "1607309101",
          // 结束使用时间
          "use_end_time": "1609344000",
          // 类型 1:无门槛 2:满减 3:折扣
          "type": "1",
          "remarks": "阳仔确定优惠券",
          // 优惠券金额(折扣券为折扣,例0.85表示8.5折)
          "coupon_amount": "23",
          // 优惠券使用限制金额(无门槛或折扣券始终为0,满减券为满减条件)
          "coupon_limit_amount": 0,
          // 	用户是否已领取 true:已领取 false:未领取
          "user_is_pick": false
        }
      ]
    }
  }
}
 */
export async function productInfo(id) {
  return await post({
    url: '/api/product/info',
    data: {
      "id":id,
    }
  });
}

/**
 * @desc  [商城模块]商品SKU列表
 * @param id 商品id
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 商品ID
      "id": "12046",
      //商品SKU列表，包含多个对象，每个对象字段如展开所示
      "productSku": [
        {
          // 商品SKUID
          "id": "3741",
          "product_spec": [
            {
              "product_spec_key": {
                "id": 513,
                "title": "重量"
              },
              "product_spec_value": {
                "id": 935,
                "value": "1KG"
              }
            }
          ],
          // 状态 1=正常 2=禁用
          "status": "1",
          // 	shu价格
          "price": "1",
          // 库存
          "inventory_quantity": "10",
          // sku 图片
          "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
        }
      ],
      //商品规格名称列表，包含多个对象，每个对象字段如展开所示
      "productSpecKey": [
        {
          //商品规格名称ID
          "id": "513",
          //	规格名称
          "title": "重量",
          //	商品规格种类列表，包含多个对象，每个对象字段如展开所示
          "productSpecValue": [
            {
              //商品规格种类ID
              "id": "935",
              //规格种类名称
              "value": "1KG"
            }
          ]
        }
      ]
    }
  }
}
 */
export async function productSkuList(id) {
  return await post({
    url: '/api/product/product-sku-list',
    data: {
      "id":id,
    }
  });
}

/**
 * @desc  [商城模块]商品列表
 * @param is_recommend 是否过滤推荐商品(默认false:不过滤,返回所有上架商品 true:过滤,只返回推荐商品)
 * @param pageSize 分页显示数据条数 默认10
 * @param page 分页页码 默认1
 * @param product_category_id 分类ID
 * @param product_promotion_category_id 活动分类ID
 * @param keyword_search 关键字搜索
 * @returns {Promise<*>}
 * {
  "code": 1,
  "message": "success!",
  "data": {
    "totalPage": 5, // 总页数
    "currentPage": 1, // 当前页
    "__info": "k",
    "info": [
      {
        "id": "12049", // 商品ID
        "title": "20201204测试商品",
        "description": "xxxxxxxxxxxxxxxxxxxxxxxx",
        "unit": "个",
        "shipping_mode": "0", // 发货模式 1:现货发货模式 2:预售发货模式
        "shipping_days": "0", // 发货天数
        "is_7day_return_supported": "0", // 是否支持7天无理由退款 1:支持 2:不支持
        "productImages": {
          "type": "1", // 图片类型 1=商品入户图 2=商品bannner图 3=商品介绍图
          "url": "https://admin.bb.uninote.com.cn/oss?path=xxx"
        },
        "productSkuByMinPrice": {
          "id": "3758", // 商品SKUID
          "price": "1",
          "inventory_quantity": "0", // 库存
          "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg" // sku 图片
        },
        "__productPromotion":  "i",
        "productPromotion":  {
          "id": "5", // 促销活动ID
          "start_time": "1605715800",
          "end_time": "1605715861",
          "productPromotionSku": [{
          "product_sku_id": "3758",
          "promotion_price": "1",
          "promotion_number": "10", // 促销数量
          "used_number": "0" // 实际销售数量
          }]
          }
        }
      ]
  }
}
 *
 */
export async function productList(is_recommend,pageSize,page,product_category_id,product_promotion_category_id,keyword_search) {
  return await post({
    url: '/api/product/list',
    data: {
      "is_recommend":is_recommend,
      "pageSize":pageSize,
      "page":page,
      "product_category_id":product_category_id,
      "keyword_search":keyword_search,
      "product_promotion_category_id":product_promotion_category_id,
    }
  });
}

/**
 * @desc  [商城模块]热搜关键词列表
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": [
      "测试"
    ]
  }
}
 */
export async function hotKeywordSearchList() {
  return await post({
    url: '/api/product/hot-keyword-search-list',
    data: {}
  });
}

/**
 * @desc  [商城模块]商品活动分类列表
 * @param pageSize 分页显示数据条数 默认10
 * @param page 分页页码 默认1
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "totalPage": 13,
    "currentPage": 1,
    "info": [
      {
        // 分类ID
        "id": "155",
        // 分类名称
        "name": "测试2",
        // 副标题
        "subtitle": "xxx2",
        // 促销活动分类图片
        "product_promotion_category_image": "https://admin.bb.uninote.com.cn/oss?path=xxx"
      }
    ]
  }
}
 */
export async function productPromotionCategory(pageSize,page) {
  return await post({
    url: '/api/product/product-promotion-category',
    data: {pageSize,page}
  });
}

