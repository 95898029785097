import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/blindbox/order_box';
import * as apis from '../../../../api/client/blindbox/order_box';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "背包列表\n",
    'url': '/api/blindbox/order/order-box-list',
    'defaults': {
      page: '',
      pageSize: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'conch': {
    '__proto__': base.conch,
    'api': apis.conch,
    'desc': "兑换贝壳\n",
    'url': '/api/blindbox/order/conch',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'dispatch': {
    '__proto__': base.dispatch,
    'api': apis.dispatch,
    'desc': "发货\n",
    'url': '/api/blindbox/order/dispatch',
    'defaults': {
      id: '',
      address_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'confirmReceipt': {
    '__proto__': base.confirmReceipt,
    'api': apis.confirmReceipt,
    'desc': "确认收货\n",
    'url': '/api/blindbox/order/confirm-receipt',
    'defaults': {
      dispatchIDs: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'conchLog': {
    '__proto__': base.conchLog,
    'api': apis.conchLog,
    'desc': "贝壳明细\n",
    'url': '/api/blindbox/order/conch-log',
    'defaults': {
      page: '',
      pageSize: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
