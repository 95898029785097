/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../base';

export const infos = {
  'login': {
    '__proto__': base,
    'name': 'login',
    'desc': "登录",
    'url': '/cmsapi/admin/user/login.html',
    'args': {
      username: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      password: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"status\": true,\n \"data\": {\n   \"id\": 1,\n   \"username\": \"root\",\n   \"email\": \"dajxyl@126.com\",\n   \"tel\": \"17729830529\",\n   \"nick\": \"超级管理员\",\n   \"pic\": \"https://admin.bb.uninote.com.cn/oss?path=img/4f836876fd1ca9ae2d1b67b66efc2ce0.jpg\",\n   \"logintime\": \"2021-10-11 12:30:24\",\n   \"is_admin\": 1,\n   \"remarks\": null,\n   \"user_status\": \"正常\"\n },\n \"msg\": \"登录成功！页面即将跳转。\"\n}\n",
  },
  'serviceList': {
    '__proto__': base,
    'name': 'serviceList',
    'desc': "服务列表",
    'url': '/cmsapi/admin/service/index.html',
    'args': {
      page: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      limit: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n",
  },
  'dispatchOrder': {
    '__proto__': base,
    'name': 'dispatchOrder',
    'desc': "派单",
    'url': '/cmsapi/admin/service/edit_order.html',
    'args': {
      orderId: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      staffId: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n{\n \"status\": true,\n \"data\": [],\n \"msg\": \"保存成功\"\n}\n",
  },
  'createrCoupon': {
    '__proto__': base,
    'name': 'createrCoupon',
    'desc': "创建优惠券",
    'url': '/cmsapi/admin/coupon/add.html',
    'args': {
      type: {
        'pos': 0,
        'desc': "1无门槛，2满减，3折扣",
        'demo': '',
      },
      nature: {
        'pos': 1,
        'desc': "1: 任意数字（单位：元），2：10|2 满10减2（元），3：0.75 七五折",
        'demo': '',
      },
      servicesIDs: {
        'pos': 2,
        'desc': "所属商品ID，多个ID使用英文逗号分隔",
        'demo': '',
      },
      num: {
        'pos': 3,
        'desc': "总数量",
        'demo': '',
      },
      useTime: {
        'pos': 4,
        'desc': "使用时间 例如 2021-10-01 00:00:00 - 2021-10-30 00:00:00",
        'demo': '',
      },
      useTime_start: {
        'pos': 5,
        'desc': "开始使用时间（时间戳）",
        'demo': '',
      },
      useTime_end: {
        'pos': 6,
        'desc': "结束使用时间（时间戳）",
        'demo': '',
      },
      pickTime: {
        'pos': 7,
        'desc': "领取时间 例如 2021-10-01 00:00:00 - 2021-10-30 00:00:00",
        'demo': '',
      },
      pickTime_start: {
        'pos': 8,
        'desc': "开始领取时间（时间戳）",
        'demo': '',
      },
      pickTime_end: {
        'pos': 9,
        'desc': "结束领取时间（时间戳）",
        'demo': '',
      },
      is_use: {
        'pos': 10,
        'desc': "是否使用 是：on 否：不传本字段就是否",
        'demo': '',
      },
      is_pick: {
        'pos': 11,
        'desc': "是否领取 是：on 否：不传本字段就是否",
        'demo': '',
      },
      is_child: {
        'pos': 12,
        'desc': "是否产生子码 是：on 否：不传本字段就是否",
        'demo': '',
      },
      is_show: {
        'pos': 13,
        'desc': "是否显示 是：on 否：不传本字段就是否",
        'demo': '',
      },
      remarks: {
        'pos': 14,
        'desc': "备注",
        'demo': '',
      },
      layuiTreeCheck: {
        'pos': 15,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n{\n \"status\": true,\n \"data\": {\n   \"code\": \"64378913\"\n },\n \"msg\": \"保存成功\"\n}\n",
  },
  'editStaff': {
    '__proto__': base,
    'name': 'editStaff',
    'desc': "编辑员工",
    'url': '/cmsapi/admin/staff/edit.html',
    'args': {
      staffId: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      city_id: {
        'pos': 1,
        'desc': "入驻城市ID",
        'demo': '',
      },
      tag_ids: {
        'pos': 2,
        'desc': "标签ID，多个ID使用英文逗号分隔",
        'demo': '',
      },
      nick: {
        'pos': 3,
        'desc': "昵称",
        'demo': '',
      },
      tel: {
        'pos': 4,
        'desc': "电话",
        'demo': '',
      },
      sex: {
        'pos': 5,
        'desc': "性别:0=女,1=男",
        'demo': '',
      },
      hometown_name: {
        'pos': 6,
        'desc': "家乡名称  例：四川",
        'demo': '',
      },
      identity_card: {
        'pos': 7,
        'desc': "身份证号码",
        'demo': '',
      },
      identity_card_a: {
        'pos': 8,
        'desc': "身份证附件（正面）",
        'demo': '',
      },
      identity_card_b: {
        'pos': 9,
        'desc': "身份证附件（反面）",
        'demo': '',
      },
      health: {
        'pos': 10,
        'desc': "健康证(附件)",
        'demo': '',
      },
      commission_ratio: {
        'pos': 11,
        'desc': "佣金比率 0.5代表50%",
        'demo': '',
      },
      desc: {
        'pos': 12,
        'desc': "信息栏描述",
        'demo': '',
      },
      status: {
        'pos': 13,
        'desc': "状态 0=未激活,1=激活",
        'demo': '',
      },
      remarks: {
        'pos': 14,
        'desc': "备注",
        'demo': '',
      },
      type: {
        'pos': 15,
        'desc': "账号身份  默认1:个人   2:公司",
        'demo': '',
      },
      layuiTreeCheck: {
        'pos': 16,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n{\n \"status\": true,\n \"data\": [],\n \"msg\": \"保存成功\"\n}\n",
  },
  'editService': {
    '__proto__': base,
    'name': 'editService',
    'desc': "编辑服务",
    'url': '/cmsapi/admin/service/edit.html',
    'args': {
      serviceId: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      title: {
        'pos': 1,
        'desc': "服务名称",
        'demo': '',
      },
      adCode: {
        'pos': 2,
        'desc': "所属城市ID，多个ID使用英文逗号分隔",
        'demo': '',
      },
      hideAdCodeIds: {
        'pos': 3,
        'desc': "隐藏的城市ID，多个ID使用英文逗号分隔",
        'demo': '',
      },
      spec_unit: {
        'pos': 4,
        'desc': "规格单位",
        'demo': '',
      },
      spec_unit_time: {
        'pos': 5,
        'desc': "规格换算成时长(单位分钟)",
        'demo': '',
      },
      spec_low: {
        'pos': 6,
        'desc': "服务最低规格",
        'demo': '',
      },
      number1: {
        'pos': 7,
        'desc': "本字段在接口上，没有任何作用",
        'demo': '',
      },
      spec: {
        'pos': 8,
        'desc': "规格",
        'demo': '',
      },
      tag_ids: {
        'pos': 9,
        'desc': "标签ID，多个ID使用英文逗号分隔",
        'demo': '',
      },
      icon: {
        'pos': 10,
        'desc': "促销配图",
        'demo': '',
      },
      tel: {
        'pos': 11,
        'desc': "电话",
        'demo': '',
      },
      original_price: {
        'pos': 12,
        'desc': "原价（单位元）",
        'demo': '',
      },
      present_price: {
        'pos': 13,
        'desc': "现价（单位元）",
        'demo': '',
      },
      business_hours: {
        'pos': 14,
        'desc': "营业时间（任意字符串）",
        'demo': '',
      },
      place: {
        'pos': 15,
        'desc': "地点 例如：四川省成都市高新区环球中心,117.1412,36.234234,距离地铁一号线锦城广场站400m",
        'demo': '',
      },
      subtitle: {
        'pos': 16,
        'desc': "副标题",
        'demo': '',
      },
      homeTitle: {
        'pos': 17,
        'desc': "服务标签描述(多个使用|分隔符)",
        'demo': '',
      },
      is_hour: {
        'pos': 18,
        'desc': "是否显示小时收费 是：on 否：不传本字段就是否",
        'demo': '',
      },
      sort: {
        'pos': 19,
        'desc': "排序",
        'demo': '',
      },
      status: {
        'pos': 20,
        'desc': "状态 是：on 否：不传本字段就是否",
        'demo': '',
      },
      abstract: {
        'pos': 21,
        'desc': "描述2",
        'demo': '',
      },
      describe: {
        'pos': 22,
        'desc': "描述1",
        'demo': '',
      },
      layuiTreeCheck: {
        'pos': 23,
        'desc': "",
        'demo': '',
      },
      integral_type: {
        'pos': 24,
        'desc': "",
        'demo': '',
      },
      integral_num: {
        'pos': 25,
        'desc': "",
        'demo': '',
      },
      advantage: {
        'pos': 26,
        'desc': "平台优势",
        'demo': '',
      },
      service_spec: {
        'pos': 27,
        'desc': "项目规格",
        'demo': '',
      },
      service_reward_specs: {
        'pos': 28,
        'desc': "返佣规格",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n{\n \"status\": true,\n \"data\": [],\n \"msg\": \"保存成功\"\n}\n",
  },
  'rewardAscension': {
    '__proto__': base,
    'name': 'rewardAscension',
    'desc': "佣金提升",
    'url': '/cmsapi/admin/service/rewardAscension.html',
    'args': {
      orderid: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      reward_ascension: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n{\n \"status\": true,\n \"data\": [],\n \"msg\": \"操作成功！\"\n}\n",
  },
  'getServiceSubOrderSpecs': {
    '__proto__': base,
    'name': 'getServiceSubOrderSpecs',
    'desc': "获取补单规格信息",
    'url': '/cmsapi/admin/service/getServiceSubOrderSpecs.html',
    'args': {
      serviceId: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n{\n \"status\": 1,\n \"data\": [\n   {\n     \"id\": 114,\n     \"service_id\": 1,\n     // 规格\n     \"spec\": \"1\",\n     // 高峰价（单位分）\n     \"peak_price\": 100,\n     // 低峰价（单位分）\n     \"low_peak_price\": 100\n   }\n ],\n \"msg\": \"\"\n}\n",
  },
  'editServiceSubOrderSpecs': {
    '__proto__': base,
    'name': 'editServiceSubOrderSpecs',
    'desc': "添加/编辑服务补单规格",
    'url': '/cmsapi/admin/service/edit_service_sub_order_spec.html',
    'args': {
      serviceId: {
        'pos': 0,
        'desc': "服务id",
        'demo': '',
      },
      spec: {
        'pos': 1,
        'desc': "规格",
        'demo': '',
      },
      peak_price: {
        'pos': 2,
        'desc': "高峰价（单位分）",
        'demo': '',
      },
      low_peak_price: {
        'pos': 3,
        'desc': "低峰价（单位分）",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n{\n \"status\": 1,\n \"data\": \"\",\n \"msg\": \"修改成功！\"\n}\n",
  },
  'delServiceSubOrderSpecs': {
    '__proto__': base,
    'name': 'delServiceSubOrderSpecs',
    'desc': "删除补单规格信息",
    'url': '/cmsapi/admin/service/del_service_sub_order_spec.html',
    'args': {
      id: {
        'pos': 0,
        'desc': "补单规格ID",
        'demo': '',
      },

    },
    'returns': "{Promise<>}\n{\n \"status\": 1,\n \"data\": \"\",\n \"msg\": \"删除成功！\"\n}\n",
  }
};
