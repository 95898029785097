import {post} from '../utils/request.js';

/**
 * @desc 初始化数据
 */
export async function init() {
  return await post({
      url: '/api/not-defined-url',
      data: {
      },
  })
}

/**
 * @desc 并发测试
 */
export async function parallel() {
  return await post({
      url: '/api/not-defined-url',
      data: {
      },
  })
}

/**
 * @desc 随机买完或者取消
 */
export async function randomAbortOrByAll() {
  return await post({
      url: '/api/not-defined-url',
      data: {
      },
  })
}
