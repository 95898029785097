/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'create': {
    '__proto__': base,
    'name': 'create',
    'desc': "创建优惠券",
    'url': '/scmsapi/coupon/create',
    'args': {
      type: {
        'pos': 0,
        'desc': "类型（1无门槛，2满减，3折扣）",
        'demo': '',
      },
      nature: {
        'pos': 1,
        'desc': "属性(无门槛为0,满减100|50,折扣0.8)",
        'demo': '',
      },
      product_ids: {
        'pos': 2,
        'desc': "所属商品 必须为商品id数组 [1,2,3]",
        'demo': '',
      },
      num: {
        'pos': 3,
        'desc': "优惠券总量，会生成对应总量的子码",
        'demo': '',
      },
      pick_start_time: {
        'pos': 4,
        'desc': "领取开始 int秒时间戳",
        'demo': '',
      },
      pick_end_time: {
        'pos': 5,
        'desc': "领取结束 int秒时间戳",
        'demo': '',
      },
      use_start_time: {
        'pos': 6,
        'desc': "使用开始 int秒时间戳",
        'demo': '',
      },
      use_end_time: {
        'pos': 7,
        'desc': "使用结束 int秒时间戳",
        'demo': '',
      },
      is_use: {
        'pos': 8,
        'desc': "是否使用（1是，0否）",
        'demo': '',
      },
      is_pick: {
        'pos': 9,
        'desc': "是否领取（1是，0否",
        'demo': '',
      },
      remarks: {
        'pos': 10,
        'desc': "备注",
        'demo': '',
      },
      is_child: {
        'pos': 11,
        'desc': "是否产生子码（1是，0否",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"code\": \"aYrihaWw\",\n   \"id\": 214\n }\n}\n",
  },
  'getOne': {
    '__proto__': base,
    'name': 'getOne',
    'desc': "获取优惠券信息",
    'url': '/scmsapi/coupon/getOne',
    'args': {
      id: {
        'pos': 0,
        'desc': "优惠券id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"id\": 8,\n       \"num\": 1, // 总量（固定值）\n       \"code\": \"3bfHrNWD\", // 编码\n       \"remains\": 0, // 剩余数量\n       \"pick_start_time\": 1605715200, // 开始领取时间\n       \"pick_end_time\": 1606723614, // 结束领取时间\n       \"use_start_time\": 1605715200, // 开始使用时间\n       \"use_end_time\": 1606723614, // 结束使用时间\n       \"__nature\": \"i\", // 属性\n       \"nature\": \"1\", // 属性\n       \"type\": 1, // 类型（1无门槛，2满减，3折扣）\n       \"is_use\": 1, // 是否能使用（1是0否)\n       \"is_pick\": 1, // 是否能领取（1是0否)\n       \"remarks\": \"\",\n       \"create_time\": \"2020-11-17 10:02:26\",\n       \"product\": [{\n           \"id\": 120,\n           \"title\": \"测试商品\"\n       }]\n   }\n}\n",
  },
  'index': {
    '__proto__': base,
    'name': 'index',
    'desc': "优惠券列表",
    'url': '/scmsapi/coupon/index',
    'args': {
      id: {
        'pos': 0,
        'desc': "优惠券ID",
        'demo': '',
      },
      type: {
        'pos': 1,
        'desc': "类型（1无门槛，2满减，3折扣）",
        'demo': '',
      },
      code: {
        'pos': 2,
        'desc': "编码",
        'demo': '',
      },
      is_use: {
        'pos': 3,
        'desc': "能否使用（1是0否）",
        'demo': '',
      },
      is_pick: {
        'pos': 4,
        'desc': "能否领取（1是0否）",
        'demo': '',
      },
      page: {
        'pos': 5,
        'desc': "页码 默认1",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"total\": 3,\n       \"per_page\": 10,\n       \"current_page\": 1,\n       \"last_page\": 1,\n       \"data\": [{\n           \"id\": 8,\n           \"num\": 1, // 总量（固定值）\n           \"code\": \"3bfHrNWD\", // 编码\n           \"remains\": 0, // 剩余数量\n           \"pick_start_time\": 1605715200, // 开始领取时间\n           \"pick_end_time\": 1606723614, // 结束领取时间\n           \"use_start_time\": 1605715200, // 开始使用时间\n           \"use_end_time\": 1606723614, // 结束使用时间\n           \"__nature\": \"i\", // 属性 可能会存在字符串和数字类型\n           \"nature\": \"1\", // 属性\n           \"type\": 1, // 类型（1无门槛，2满减，3折扣）\n           \"is_use\": 1, // 是否能使用（1是0否)\n           \"is_pick\": 1, // 是否能领取（1是0否)\n           \"is_child\": 1, // 是否有子码（1是0否)\n           \"remarks\": \"\",\n           \"create_time\": \"2020-11-17 10:02:26\",\n           \"product\": [{\n               \"title\": \"测试商品\"\n           }],\n           \"children_amount\": 1 // 优惠券已使用的子码数量\n       }]\n   }\n}\n",
  },
  'couponExport': {
    '__proto__': base,
    'name': 'couponExport',
    'desc': "导出优惠券列表(本接口请采用get请求，请求参数与/scmsapi/coupon/index接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)",
    'url': '/scmsapi/coupon/export',
    'args': {
      type: {
        'pos': 0,
        'desc': "类型（1无门槛，2满减，3折扣）",
        'demo': '',
      },
      code: {
        'pos': 1,
        'desc': "编码",
        'demo': '',
      },
      is_use: {
        'pos': 2,
        'desc': "能否使用（1是0否）",
        'demo': '',
      },
      is_pick: {
        'pos': 3,
        'desc': "能否领取（1是0否）",
        'demo': '',
      },
      export_num: {
        'pos': 4,
        'desc': "导出数据条数",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n",
  },
  'children': {
    '__proto__': base,
    'name': 'children',
    'desc': "子码列表",
    'url': '/scmsapi/coupon/children',
    'args': {
      id: {
        'pos': 0,
        'desc': "主码id",
        'demo': '',
      },
      code: {
        'pos': 1,
        'desc': "子码编码",
        'demo': '',
      },
      username: {
        'pos': 2,
        'desc': "领取人手机",
        'demo': '',
      },
      status: {
        'pos': 3,
        'desc': "是否使用（1未使用，2已使用）",
        'demo': '',
      },
      is_pick: {
        'pos': 4,
        'desc': "是否领取（1未领取，2已领取）",
        'demo': '',
      },
      page: {
        'pos': 5,
        'desc': "页码 默认1",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"total\": 1,\n       \"per_page\": 10,\n       \"current_page\": 1,\n       \"last_page\": 1,\n       \"data\": [{\n           \"id\": 17,\n           \"pid\": 8, // 主码id\n           \"code\": \"GRn2uUw3\",\n           \"user_id\": 10022, // 用户id\n           \"status\": 1, // 是否使用（1未使用，2已使用）\n           \"pick_time\": 1606119137, // 领取时间\n           \"use_time\": 0, // 使用时间\n           \"user\": {\n               \"phone\": \"18111619306\", // 用户电话\n               \"nickname\": \"黄翠刚\" // 用户昵称\n           }\n       }]\n   }\n}\n",
  },
  'couponChildrenExport': {
    '__proto__': base,
    'name': 'couponChildrenExport',
    'desc': "导出优惠券子码列表(本接口请采用get请求，请求参数与/scmsapi/coupon/children接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)",
    'url': '/scmsapi/coupon/childrenExport',
    'args': {
      id: {
        'pos': 0,
        'desc': "主码id",
        'demo': '',
      },
      code: {
        'pos': 1,
        'desc': "子码编码",
        'demo': '',
      },
      username: {
        'pos': 2,
        'desc': "领取人手机",
        'demo': '',
      },
      status: {
        'pos': 3,
        'desc': "是否使用（1未使用，2已使用）",
        'demo': '',
      },
      is_pick: {
        'pos': 4,
        'desc': "是否领取（1未领取，2已领取）",
        'demo': '',
      },
      export_num: {
        'pos': 5,
        'desc': "导出数据条数",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n",
  },
  'update': {
    '__proto__': base,
    'name': 'update',
    'desc': "优惠券更新",
    'url': '/scmsapi/coupon/update',
    'args': {
      id: {
        'pos': 0,
        'desc': "优惠券id",
        'demo': '',
      },
      product_ids: {
        'pos': 1,
        'desc': "商品id的数组[1,2,3]",
        'demo': '',
      },
      num: {
        'pos': 2,
        'desc': "数量",
        'demo': '',
      },
      nature: {
        'pos': 3,
        'desc': "属性(无门槛为0,满减100|50,折扣0.8)",
        'demo': '',
      },
      type: {
        'pos': 4,
        'desc': "类型1=无门槛 2=满减 3=折扣",
        'demo': '',
      },
      pick_start_time: {
        'pos': 5,
        'desc': "开始领取时间",
        'demo': '',
      },
      pick_end_time: {
        'pos': 6,
        'desc': "结束领取时间",
        'demo': '',
      },
      use_start_time: {
        'pos': 7,
        'desc': "开始使用时间",
        'demo': '',
      },
      use_end_time: {
        'pos': 8,
        'desc': "结束使用时间",
        'demo': '',
      },
      is_use: {
        'pos': 9,
        'desc': "是否可用（1是，0否）",
        'demo': '',
      },
      is_pick: {
        'pos': 10,
        'desc': "是否可用（1是，0否）",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\"code\":200,\"msg\":\"success\",\"data\":[]}\n",
  },
  'del': {
    '__proto__': base,
    'name': 'del',
    'desc': "删除优惠券",
    'url': '/scmsapi/coupon/delete',
    'args': {
      id: {
        'pos': 0,
        'desc': "优惠券id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\"code\":200,\"msg\":\"success\",\"data\":[]}\n",
  }
};
