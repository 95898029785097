import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../consts';
import {infos as base} from '../generated/tools';
import * as apis from '../../api/tools';

export const infos = {

  'init': {
    '__proto__': base.init,
    'api': apis.init,
    'desc': "初始化数据\n",
    'url': '/api/not-defined-url',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'parallel': {
    '__proto__': base.parallel,
    'api': apis.parallel,
    'desc': "并发测试\n",
    'url': '/api/not-defined-url',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'randomAbortOrByAll': {
    '__proto__': base.randomAbortOrByAll,
    'api': apis.randomAbortOrByAll,
    'desc': "随机买完或者取消\n",
    'url': '/api/not-defined-url',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
