/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../../base';

export const infos = {
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': "福袋订单列表\n",
    'url': '/scmsapi/blindbox/order/list',
    'args': {
      page: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      id: {
        'pos': 2,
        'desc': "订单id",
        'demo': '',
      },
      order_no: {
        'pos': 3,
        'desc': "商户订单号（支持高级模糊查询）",
        'demo': '',
      },
      wx_no: {
        'pos': 4,
        'desc': "微信订单号（支持高级模糊查询）",
        'demo': '',
      },
      uid: {
        'pos': 5,
        'desc': "订单用户id",
        'demo': '',
      },
      bag_ids: {
        'pos': 6,
        'desc': "福袋 id 列表，以','分隔",
        'demo': '',
      },
      pay_status: {
        'pos': 7,
        'desc': "支付状态",
        'demo': '',
      },
      bag_name: {
        'pos': 8,
        'desc': "福袋名（支持高级模糊查询）",
        'demo': '',
      },
      bag_status: {
        'pos': 9,
        'desc': "暂不支持",
        'demo': '',
      },
      bag_type: {
        'pos': 10,
        'desc': "暂不支持",
        'demo': '',
      },
      start_time: {
        'pos': 11,
        'desc': "订单范围：开始时间",
        'demo': '',
      },
      end_time: {
        'pos': 12,
        'desc': "订单范围：截止时间",
        'demo': '',
      },
      advanced: {
        'pos': 13,
        'desc': "true: 模糊查询条件完全手写, false: 添加首尾%，/%_ 匹配字面意义",
        'demo': '',
      },
      is_export: {
        'pos': 14,
        'desc': "是否导出",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"total\": 1,\n   \"per_page\": 10,\n   \"current_page\": 1,\n   \"last_page\": 1,\n   \"data\": [\n     {\n       \"order_id\": 3,\n       \"order_no\": \"BL1099980589\",\n       \"wx_no\": \"700258440\",\n       \"pay_status\": 1,\n       \"bag_id\": 5,\n       \"bag_type\": 1,\n       \"sale_type\": 1,\n       \"bag_name\": \"福袋\",\n       \"uid\": 10180,\n       \"nickname\": \"一码\",\n       \"remark_name\": \"223\",\n       \"amount\": 2,\n       \"partial_refund_amount\": 0,\n       \"create_time\": 1699198758,\n       \"total_conch\": 90,\n       \"conch_normal\": 90,\n       \"name_normal\": \"血色玫瑰\",\n       \"__conch_rank\": \"n\",\n       \"conch_rank\": 90,\n       \"__name_rank\": \"n\",\n       \"name_rank\": \"name\",\n       \"__conch_plus_b\": \"n\",\n       \"conch_plus_b\": 90,\n       \"__name_plus_b\": \"n\",\n       \"name_plus_b\": \"name\",\n       \"__once_more_id\": \"n\",\n       \"once_more_id\": 1\n     }\n   ]\n }\n}\n",
  },
  'listGroupByUser': {
    '__proto__': base,
    'name': 'listGroupByUser',
    'desc': "福袋订单列表-按用户分组\n",
    'url': '/scmsapi/blindbox/order/listGroupByUser',
    'args': {
      page: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      wx_no: {
        'pos': 2,
        'desc': "微信订单号（支持高级模糊查询）",
        'demo': '',
      },
      uid: {
        'pos': 3,
        'desc': "订单用户id",
        'demo': '',
      },
      bag_ids: {
        'pos': 4,
        'desc': "福袋 id 列表，以','分隔",
        'demo': '',
      },
      pay_status: {
        'pos': 5,
        'desc': "支付状态",
        'demo': '',
      },
      start_time: {
        'pos': 6,
        'desc': "订单范围：开始时间",
        'demo': '',
      },
      end_time: {
        'pos': 7,
        'desc': "订单范围：截止时间",
        'demo': '',
      },
      advanced: {
        'pos': 8,
        'desc': "true: 模糊查询条件完全手写, false: 添加首尾%，/%_ 匹配字面意义",
        'demo': '',
      },
      is_export: {
        'pos': 9,
        'desc': "是否导出",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n}\n",
  },
  'listGroupByDate': {
    '__proto__': base,
    'name': 'listGroupByDate',
    'desc': "福袋订单列表-按日期分组\n",
    'url': '/scmsapi/blindbox/order/listGroupByDate',
    'args': {
      page: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      wx_no: {
        'pos': 2,
        'desc': "微信订单号（支持高级模糊查询）",
        'demo': '',
      },
      uid: {
        'pos': 3,
        'desc': "订单用户id",
        'demo': '',
      },
      bag_ids: {
        'pos': 4,
        'desc': "福袋 id 列表，以','分隔",
        'demo': '',
      },
      pay_status: {
        'pos': 5,
        'desc': "支付状态",
        'demo': '',
      },
      start_time: {
        'pos': 6,
        'desc': "订单范围：开始时间",
        'demo': '',
      },
      end_time: {
        'pos': 7,
        'desc': "订单范围：截止时间",
        'demo': '',
      },
      advanced: {
        'pos': 8,
        'desc': "true: 模糊查询条件完全手写, false: 添加首尾%，/%_ 匹配字面意义",
        'demo': '',
      },
      is_export: {
        'pos': 9,
        'desc': "是否导出",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n}\n",
  }
};
