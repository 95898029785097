import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc  创建活动分类
 * @param name 分类名称
 * @param subtitle 副标题
 * @param sort 排序序号
 * @param status 状态 状态 1=启用 2=禁用
 * @param promotion_category_image 分类图片
 promotion_category_image: {
       // 图片key[/upload/image接口上传图片后获得]
       "file_key": "xxx",
       // 图片可访问路径[/upload/image接口上传图片后获得]
       "url": "xxx"
   },
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "id": 13
  }
}
 */
export function create(name,subtitle,sort,status,promotion_category_image) {
  let params = {
    url: '/scmsapi/promotion_category/create',
    data: {name,subtitle,sort,status,promotion_category_image}
  };
  return post(params);
}

/**
 * @desc  编辑促销活动分类
 * @param id 分类ID
 * @param name 分类名称
 * @param subtitle 副标题
 * @param sort 排序序号
 * @param status 状态 状态 1=启用 2=禁用
 * @param promotion_category_image 分类图片
 promotion_category_image: {
       // 图片key[/upload/image接口上传图片后获得]
       "file_key": "xxx",
       // 图片可访问路径[/upload/image接口上传图片后获得]
       "url": "xxx"
   },
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function update(id,name,subtitle,sort,status,promotion_category_image) {
  let params = {
    url: '/scmsapi/promotion_category/update',
    data: {id,name,subtitle,sort,status,promotion_category_image}
  };
  return post(params);
}

/**
 * @desc  删除促销活动分类
 * @param id 分类ID
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function del(id) {
  let params = {
    url: '/scmsapi/promotion_category/del',
    data: {id}
  };
  return post(params);
}

/**
 * @desc  获取促销活动分类列表
 * @param id 活动分类id
 * @param name 分类名称(模糊查询)
 * @param page 分页页码 默认1
 * @param page_size 分页每页数据条数 默认10
 * @param status 状态 1=显示 2=隐藏 不传此参数则查询全部
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 5,
    "per_page": 15,
    "current_page": 1,
    "last_page": 1,
     "__data": "k",
    "data": [
      {
        // 分类ID
        "id": 1,
        //分类名称
        "name": "日用品",
        //排序序号
        "sort": 1,
        //状态 1=显示 2=隐藏
        "status": 2,
        //操作时间
        "update_time": "2021-03-08 12:26:04",
        // 分类图片
        "product_promotion_category_image": "https://admin.bb.uninote.com.cn/oss?path=xxx3"
      }
    ]
  }
}
 */
export function list(id,name,page,page_size,status) {
  let params = {
    url: '/scmsapi/promotion_category/list',
    data: {id, name, page, page_size, status}
  };
  return post(params);
}
