/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': "获取 config 列表",
    'url': '/scmsapi/config/list',
    'args': {

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": [\n   {\n     \"id\": 1,\n     \"key\": \"service_timeout\",\n     \"value\": \"1\",\n     \"comment\": \"comment content\"\n   }\n ]\n}\n",
  },
  'update': {
    '__proto__': base,
    'name': 'update',
    'desc': "更新 config 项",
    'url': '/scmsapi/config/update',
    'args': {
      id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      value: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      comment: {
        'pos': 2,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": 1 // db返回的更新的数据行数\n}\n",
  }
};
