import { Message } from'element-ui';

// batch params variables
let batchParams = {};
let batchParamsChangedCb = null;

/**
 * whether obj1's structure contains obj2' structure
 * note: { "a": {} } contains { "a": null }, but not vice-versa
 * @param rootObj1
 * @param rootObj2
 * @param options Object, 验证忽略路径配置对象。支持的数据 key: nullList, keyList, typeList
 * eg: {"nullList": ["data.data.0.user"]}
 */
export const structureContains = (rootObj1, rootObj2, options = {}) => {
  let nullList = options.nullList || [];
  let keyList = options.keyList || [];
  let typeList = options.typeList || [];
  let ignoreList = options.ignoreList || [];

  // 检查内联配置是否允许忽略
  const inlineOk = (objSample, key, expect) => {
    // key1.key2.key3... 处理
    let parts = key.split('.');
    for (let i = 0; i < parts.length - 1; i ++) {
      objSample = objSample[parts[i]];
    }
    key = parts[parts.length - 1];

    let inlineConfig = objSample["__" + key];
    if (inlineConfig) {
      return inlineConfig.indexOf(expect) !== -1;
    }
    return false;
  };

  const checkRecur = (obj1, obj2, curRoot) => {
    let k1 = Object.keys(obj1);
    let k2 = Object.keys(obj2);
    for (let k of k2) {
      if (k.startsWith("__")) {
        continue;
      }
      let curPath = curRoot + k;
      if (ignoreList.includes(curPath) || inlineOk(obj2, k, 'i')) {
        continue;
      }
      if (k1.indexOf(k) === -1) {
        if (keyList.includes(curPath)) {
          continue;
        }
        if (Array.isArray(obj2)) {
          if (inlineOk(rootObj2, curRoot.substring(0, curRoot.length - 1), 'k')) {
            continue;
          }
        } else if (inlineOk(obj2, k, 'k')) {
          continue;
        }
        throw new Error(`key '${curPath}' not in obj1`);
      }
      let v1 = obj1[k];
      let v2 = obj2[k];
      let t1 = typeof(v1), t2 = typeof(v2);
      if (t1 !== t2) {
        // if (typeList.includes(curPath) || inlineOk(obj2, k, 't')) {
          continue;
        // }
        // throw new Error (`type '${t1}' not equal to type '${t2}', key: '${curPath}'`);
      }
      if (t1 === "object") {
        // null is object
        if (v1 === null && v2 !== null) {
          if (nullList.includes(curPath) || inlineOk(obj2, k, 'n')) {
            continue;
          }
          throw new Error (`v1 is null while v2 is not null, key: '${curPath}'`)
        }
        if (v1 && v2) {
          checkRecur(obj1[k], obj2[k], curPath + ".");
        }
      }
    }
  };

  checkRecur(rootObj1, rootObj2, '')
};

export const assert = (condition, msg) => {
  if (!condition) {
    throw new Error(`assertion failed, message: ${msg}`);
  }
};

/**
 * assert wrapper
 * @param info string, request result
 * @param sample string, sample string
 * @param msg string
 * @param options Object, see structureContains
 */
export const assertSuc = (info, sample, msg = '', options = {}) => {
  if (typeof (sample) === 'string') {
    sample = looseJsonParse(sample);
  }
  if (!options.noCode) {
    assert(info.data.code === 1 || info.data.code === 200 || info.data.status === true || info.data.status === 1, "return code error, msg: " + msg);
  }
  structureContains(info.data, sample, options);
};

/**
 * 用于将 json 字符串返回值转为接口结果描述形式
 * @param json string Json result of api
 * @param includeValue boolean 是否包含结果作为示例
 * @param onlyFirstElementOfArray 数组元素是否只显示第一个
 * @return {string}
 */
const toDesc = (json, includeValue = true, onlyFirstElementOfArray = true) => {
  let obj;
  try {
    obj = typeof(json) === "object" ? json : JSON.parse(json);
  } catch (e) {
    return json;
  }
  let result = [];
  const descRecur = (cur, level) => {
    let curIsArray = Array.isArray(cur);
    for(let key of Object.keys(cur)) {
      let value = cur[key];
      let type = Array.isArray(value) ? "array" : typeof value;
      // 'y' 表示默认必含此字段
      let line = " * " + "  ".repeat(level) + key.padEnd(12, " ") + ": " + type.padEnd(8, " ") + "y ";
      if (value && (type === "object" || type === "array")) {
        result.push(line);
        descRecur(value, level + 1);
      } else {
        line += "eg: " + JSON.stringify(value);
        result.push(line);
      }
      if (curIsArray && onlyFirstElementOfArray) {
        break;
      }
    }
  };
  descRecur(obj, 0);

  return "\n" + result.join('\n');
};

/**
 * show toast of message
 * @param err
 * @param duration
 */
export const showError = (err, duration = 2000) => {
  console.error(err);
  return Message.error(err.toString());
};

/**
 * 获取类型，区分 object & array
 * @param value
 * @return {string}
 */
export const getType = (value) => {
  return Array.isArray(value) ? "array" : typeof(value);
};

/**
 * JSON.parse 代替函数，使用非严格语法解析 JSON
 * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#Never_use_eval!
 * @param obj
 * @return {*}
 */
export const looseJsonParse = (obj) => {
  try {
    return Function('"use strict";return (' + obj + ')')();
  } catch (e) {
    console.log("// tips: 将以下代码复制到控制台执行，然后点击报错信息，就能快速定位 JSON 格式错误:)\n__temp=" + obj);
    throw {
      "message": "扩展JSON格式解析错误，请往上查看，根据提示进行处理。",
      origin: e,
    };
  }
};

/**
 * 返回当前时间的时间戳，加上 delta 的值
 * @param delta
 * @return {*}
 */
export const timeNow = (delta) => {
  return parseInt(Date.now() / 1000) + delta;
};

/**
 * uni-app checkbox helper
 * @param obj
 * @param allKeys 当前 group 所有的 key
 * @param checkedKeys 当前选中的所有的 key
 */
export const updateChecks = (obj, allKeys, checkedKeys) => {
  for (let i = 0; i < allKeys.length; ++i) {
    const key = allKeys[i];
    obj[key] = checkedKeys.includes(key);
  }
};

/**
 * 在 arr 中查找 source 的最长字符串
 * @param arr
 * @param source
 * @return {string} 最长字符串，其他情况 ''
 */
export const longestMatch = (arr, source) => {
  if (!arr || typeof(source) !== 'string') {
    return '';
  }
  let max = '';
  arr.forEach((item) => {
    if (item  && item.length > max.length && source.indexOf(item) !== -1) {
      max = item;
    }
  });
  return max;
};

// unit test for longestMatch
export const ut_longestMatch = () => {
  assert(longestMatch(null, 'x') === '');
  assert(longestMatch(null, null) === '');
  assert(longestMatch([], null) === '');
  assert(longestMatch([], {}) === '');
  assert(longestMatch(['a', 'b'], 'a') === 'a');
  assert(longestMatch(['a', 'a/b', 'b/c'], 'a/b/c') === 'a/b');
  assert(longestMatch(['a', 'a/b', 'a/b/c'], 'a/b/c') === 'a/b/c');
  assert(longestMatch(['a', 'a/b/c', 'a/b'], 'a/b') === 'a/b');
  assert(longestMatch(['a', 'a/b/c', 'a/b'], {}) === '');
  console.log('ut_longestMatch all passed');
};

/**
 * 根据 includes/excludes 判断是否应该包含 source
 * 如果同时命中了，谁的最长匹配最长，谁获胜
 *
 * @param includes array include config
 * @param excludes array exclude config
 * @param source string 判定主体
 * @return {boolean} 是否包含
 */
export const shouldInclude = (includes, excludes, source) => {
  let longestInc = longestMatch(includes, source);
  let longestExc = longestMatch(excludes, source);
  if (includes) {
    return longestInc.length > 0 && longestInc.length >= longestExc.length;
  }
  return longestExc.length === 0;
};

/**
 * 异步休眠
 * @param time 休眠时间(ms)
 * @return {Promise<any>}
 */
export const sleep = (time) => {
  return new Promise(function (resolve) {
    setTimeout(()=>{
      resolve();
    }, time);
  });
};

/**
 * 随机手机号
 *
 * @returns {string} tel number
 */
export const randomTel = () => {
  return ("1" + parseInt(Math.random() * 10000000000)).padEnd(11, "0");
};

export const randomPath = () => {
  return parseInt(Math.random() * 1000000) + ".md";
};

export const randomContent = () => {
  return parseInt(Math.random() * 1000000);
};

/**
 * 设置 cookie
 */
export const setCookie = (cname, cvalue, exdays, path = '/') => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires + '; path=' + path;
};

/**
 * get localStorage item by key
 *
 * @param {string} key
 * @returns {object}
 */
export const getLSItem = function (key, def = "") {
  const result = window.localStorage.getItem(key);
  try {
    return JSON.parse(result) || def;
  } catch {
    return result;
  }
}

/**
* set localStorage item by key
*
* @param {string} key
* @param {any} value
*/
export const setLSItem = function (key, value) {
  return window.localStorage.setItem(key, JSON.stringify(value));
}

// 根据 key 从 url 中获取 value
export const getURLParam = (key, def = "") => {
  return new URL(location.href).searchParams.get(key) || def;
};
// 设置 key、value 到 url，并 pushState
export const setURLParam = (key, val) => {
  let url = new URL(location.href);
  url.searchParams.set(key, val);
  history.pushState({title: val}, val, url.toString());
};

// 根据 key 从 url/localStorage 中获取 value, url 优先
export const getParam = (key, def = "") => {
  return batchParams[key] || getURLParam(key) || getLSItem(key, def);
};

// set batch params
export const setBatchParams = (params) => {
  batchParams = params;
  if (batchParamsChangedCb) {
    batchParamsChangedCb();
  }
};

// set batch params change callback
export const setBatchParamsChangeCb = (callback) => {
  batchParamsChangedCb = callback;
};

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
export const dateFormat = function (date, fmt) { //author: meizz
  const o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
};

/**
 * 获取当前时间的字符串表示，不足两位以"0"占位，eg：2021-08-09 11:20:24
 */
export const dateNow = () => {
  return dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss');
};

/**
 * 获取随机中文汉字
 */
export const getRandomChineseWord = function () {
  let _rsl = "";
  let _randomUniCode = Math.floor(Math.random() * (40870 - 19968) + 19968).toString(16);
  eval("_rsl=" + '"\\u' + _randomUniCode + '"');
  return _rsl;
};
