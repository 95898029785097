/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'register': {
    '__proto__': base,
    'name': 'register',
    'desc': "注册用户",
    'url': '/scmsapi/admin/register',
    'args': {
      username: {
        'pos': 0,
        'desc': "用户名/手机",
        'demo': '',
      },
      password: {
        'pos': 1,
        'desc': "密码",
        'demo': '',
      },
      confirm_pwd: {
        'pos': 2,
        'desc': "重复密码",
        'demo': '',
      },
      nickname: {
        'pos': 3,
        'desc': "昵称",
        'demo': '',
      },
      role_id: {
        'pos': 4,
        'desc': "角色id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"username\": \"18100571481\",\n       \"id\": 29\n   }\n}\n",
  },
  'index': {
    '__proto__': base,
    'name': 'index',
    'desc': "用户列表",
    'url': '/scmsapi/admin/index',
    'args': {
      id: {
        'pos': 0,
        'desc': "账号id",
        'demo': '',
      },
      status: {
        'pos': 1,
        'desc': "状态（0禁用，1正常）",
        'demo': '',
      },
      username: {
        'pos': 2,
        'desc': "用户名/手机",
        'demo': '',
      },
      role_id: {
        'pos': 3,
        'desc': "角色id",
        'demo': '',
      },
      page: {
        'pos': 4,
        'desc': "页码默认1",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"total\": 11,\n       \"per_page\": 10,\n       \"current_page\": 1,\n       \"last_page\": 2,\n       \"data\": [\n           {\n               \"id\": 5,\n               \"username\": \"18111619306\",\n               \"nickname\": \"测试供应商\",\n               \"creator_id\": 3,\n               \"status\": 1, // 状态(0禁用1正常)\n               \"role_id\": 3,\n               \"create_time\": \"2020-11-06 16:26:22\",\n               \"role_name\": \"供货商\",\n               \"role_mark\": \"supplier_mark\",\n               \"creator\": {\n                   \"username\": \"admin\",\n                   \"role_name\": null\n               }\n           }\n       ]\n   }\n}\n",
  },
  'updateStatus': {
    '__proto__': base,
    'name': 'updateStatus',
    'desc': "用户启用禁用",
    'url': '/scmsapi/admin/updateStatus',
    'args': {
      id: {
        'pos': 0,
        'desc': "用户id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'resetPwd': {
    '__proto__': base,
    'name': 'resetPwd',
    'desc': "重置密码-密码变为123456",
    'url': '/scmsapi/admin/resetPwd',
    'args': {
      id: {
        'pos': 0,
        'desc': "用户id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'del': {
    '__proto__': base,
    'name': 'del',
    'desc': "用户删除",
    'url': '/scmsapi/admin/delete',
    'args': {
      id: {
        'pos': 0,
        'desc': "用户id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'adminGetSupplierList': {
    '__proto__': base,
    'name': 'adminGetSupplierList',
    'desc': " 获取供应商列表",
    'url': '/scmsapi/admin/getSupplierList',
    'args': {

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": [\n   {\n     \"id\": 101,\n     \"nickname\": \"测试用户\"\n   }\n ]\n}\n",
  },
  'uploadImage': {
    '__proto__': base,
    'name': 'uploadImage',
    'desc': " 图片上传接口\n注意:本接口实际上是请求的[客户端api项目]提供的/upload/image接口,\n所以接口的请求参数和返回参数，需要以[客户端api项目]的/upload/image接口文档为准",
    'url': '/scmsapi/upload/image',
    'args': {
      file_base64: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      scene: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": [\n   {\n     \"url\": \"https://admin.bb.uninote.com.cn/oss?path=uploads/images/2021/09/e38a32dd876746af92d53bcb7bbce9e4.png\",\n     \"file_key\": \"e38a32dd876746af92d53bcb7bbce9e4\"\n   }\n ]\n}\n",
  }
};
