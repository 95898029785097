import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/shop/product';
import * as apis from '../../../../api/client/shop/product';

export const infos = {

  'productInfo': {
    '__proto__': base.productInfo,
    'api': apis.productInfo,
    'desc': " [商城模块]商品详情",
    'url': '/api/product/info',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productSkuList': {
    '__proto__': base.productSkuList,
    'api': apis.productSkuList,
    'desc': " [商城模块]商品SKU列表",
    'url': '/api/product/product-sku-list',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productList': {
    '__proto__': base.productList,
    'api': apis.productList,
    'desc': " [商城模块]商品列表",
    'url': '/api/product/list',
    'defaults': {
      is_recommend: '',
      pageSize: '',
      page: '',
      product_category_id: '',
      product_promotion_category_id: '',
      keyword_search: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'hotKeywordSearchList': {
    '__proto__': base.hotKeywordSearchList,
    'api': apis.hotKeywordSearchList,
    'desc': " [商城模块]热搜关键词列表",
    'url': '/api/product/hot-keyword-search-list',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productPromotionCategory': {
    '__proto__': base.productPromotionCategory,
    'api': apis.productPromotionCategory,
    'desc': " [商城模块]商品活动分类列表",
    'url': '/api/product/product-promotion-category',
    'defaults': {
      pageSize: '',
      page: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
