import {dajxHttp} from "../../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc 福袋订单列表
 *
 * @param page
 * @param page_size
 * @param id 订单id
 * @param uid 订单用户id
 * @param order_no 商户订单号（支持高级模糊查询）
 * @param wx_no 微信订单号（支持高级模糊查询）
 * @param bag_ids 福袋 id 列表，以','分隔
 * @param pay_status 支付状态
 * @param bag_name 福袋名（支持高级模糊查询）
 * @param bag_status 暂不支持
 * @param bag_type 暂不支持
 * @param start_time 订单范围：开始时间
 * @param end_time 订单范围：截止时间
 * @param advanced true: 模糊查询条件完全手写, false: 添加首尾%，/%_ 匹配字面意义
 * @param is_export 是否导出
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 1,
    "per_page": 10,
    "current_page": 1,
    "last_page": 1,
    "data": [
      {
        "order_id": 3,
        "order_no": "BL1099980589",
        "wx_no": "700258440",
        "pay_status": 1,
        "bag_id": 5,
        "bag_type": 1,
        "sale_type": 1,
        "bag_name": "福袋",
        "uid": 10180,
        "nickname": "一码",
        "remark_name": "223",
        "amount": 2,
        "partial_refund_amount": 0,
        "create_time": 1699198758,
        "total_conch": 90,
        "conch_normal": 90,
        "name_normal": "血色玫瑰",
        "__conch_rank": "n",
        "conch_rank": 90,
        "__name_rank": "n",
        "name_rank": "name",
        "__conch_plus_b": "n",
        "conch_plus_b": 90,
        "__name_plus_b": "n",
        "name_plus_b": "name",
        "__once_more_id": "n",
        "once_more_id": 1
      }
    ]
  }
}
 */
export function list(page, page_size, id, order_no, wx_no, uid, bag_ids, pay_status, bag_name, bag_status, bag_type,
  start_time, end_time, advanced, is_export) {
  let params = {
    url: '/scmsapi/blindbox/order/list',
    data: {
      page, page_size, id, order_no, wx_no, uid, bag_ids, pay_status, bag_name, bag_status, bag_type,
      start_time, end_time, advanced, is_export
    }
  };
  return post(params);
}

/**
 * @desc 福袋订单列表-按用户分组
 *
 * @param page
 * @param page_size
 * @param uid 订单用户id
 * @param wx_no 微信订单号（支持高级模糊查询）
 * @param bag_ids 福袋 id 列表，以','分隔
 * @param pay_status 支付状态
 * @param advanced true: 模糊查询条件完全手写, false: 添加首尾%，/%_ 匹配字面意义
 * @param start_time 订单范围：开始时间
 * @param end_time 订单范围：截止时间
 * @param is_export 是否导出
 * @returns {Promise<*>} {
}
 */
export function listGroupByUser(page, page_size, wx_no, uid, bag_ids, pay_status, start_time, end_time, advanced, is_export) {
  let params = {
    url: '/scmsapi/blindbox/order/listGroupByUser',
    data: {
      page, page_size, wx_no, uid, bag_ids, pay_status, start_time, end_time, advanced, is_export
    }
  };
  return post(params);
}

/**
 * @desc 福袋订单列表-按日期分组
 *
 * @param page
 * @param page_size
 * @param uid 订单用户id
 * @param wx_no 微信订单号（支持高级模糊查询）
 * @param bag_ids 福袋 id 列表，以','分隔
 * @param pay_status 支付状态
 * @param advanced true: 模糊查询条件完全手写, false: 添加首尾%，/%_ 匹配字面意义
 * @param start_time 订单范围：开始时间
 * @param end_time 订单范围：截止时间
 * @param is_export 是否导出
 * @returns {Promise<*>} {
}
 */
export function listGroupByDate(page, page_size, wx_no, uid, bag_ids, pay_status, start_time, end_time, advanced, is_export) {
  let params = {
    url: '/scmsapi/blindbox/order/listGroupByDate',
    data: {
      page, page_size, wx_no, uid, bag_ids, pay_status, start_time, end_time, advanced, is_export
    }
  };
  return post(params);
}
