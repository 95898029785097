/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'get_balance': {
    '__proto__': base,
    'name': 'get_balance',
    'desc': "用户钱包余额",
    'url': '/api/wallet/get-balance',
    'args': {
      uid: {
        'pos': 0,
        'desc': "用户ID",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     // 余额\n     \"balance\": \"100\"\n   }\n }\n}\n",
  },
  'billing_details': {
    '__proto__': base,
    'name': 'billing_details',
    'desc': "账单明细",
    'url': '/api/wallet/billing-details',
    'args': {
      uid: {
        'pos': 0,
        'desc': "用户ID",
        'demo': '',
      },
      status: {
        'pos': 1,
        'desc': "状态 0审核中 1已完成 2已驳回 3失败",
        'demo': '',
      },
      start: {
        'pos': 2,
        'desc': "开始时间",
        'demo': '',
      },
      end: {
        'pos': 3,
        'desc': "结束时间",
        'demo': '',
      },
      page: {
        'pos': 4,
        'desc': "当前页",
        'demo': '',
      },
      offset: {
        'pos': 5,
        'desc': "每页显示条数",
        'demo': '',
      },
      type: {
        'pos': 6,
        'desc': "账单类型 提现 or 返佣 0 未知 1 奖励 2提现",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n\t\"code\": 1,\n\t\"message\": \"success!\",\n\t\"data\": {\n\t\t\"info\": {\n\t\t    \"__list\":\"k\",\n\t\t\t\"list\": [\n\t\t\t\t{\n\t\t\t\t    // 流水id\n\t\t\t\t\t\"id\": \"3\",\n\t\t\t\t\t// 钱包id\n\t\t\t\t\t\"wallet_id\": \"1\",\n\t\t\t\t\t// 金额\n\t\t\t\t\t\"amount\": \"99.00\",\n\t\t\t\t\t// 备注\n\t\t\t\t\t\"remark\": \"服务a返佣\",\n\t\t\t\t\t// 状态 状态 0审核中 1已完成 2已驳回 3失败\n\t\t\t\t\t\"status\": \"0\",\n\t\t\t\t\t// 类型 0 未知 1 奖励 2提现\n\t\t\t\t\t\"type\": \"1\",\n\t\t\t\t\t// 创建时间\n\t\t\t\t\t\"time\": \"1599422767\",\n\t\t\t\t\t// 修改时间\n\t\t\t\t\t\"update_time\": \"1599669767\"\n\t\t\t\t}\n\t\t\t],\n\t\t\t\"total_page\": 1\n\t\t}\n\t}\n}\n",
  },
  'is_wx_bind': {
    '__proto__': base,
    'name': 'is_wx_bind',
    'desc': "判断该用户是否授权绑定",
    'url': '/api/wallet/is-wx-bind',
    'args': {
      uid: {
        'pos': 0,
        'desc': "用户ID",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 1,\n   \"message\": \"success!\",\n   \"data\": {\n       \"info\": {\n           // 是否绑定\n           \"is_bind\": true,\n           \"__user_info\": \"i\",\n           \"user_info\": {\n               // 昵称\n               \"nick_name\": \"哄哄\",\n               // 头像地址\n               \"avatar_url\": \"https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM6ZibrWUEqcKP6MqFiajWKm0gibrvuoIqlICdN7ibaHtI7G2APPkZZvz0btsLkatakW9698Xmj2kSmKrg/132\",\n               // 微信unionid\n               \"unionid\": \"oTmHYvpnfTH0SX_ubIKfoFpzDdnE\"\n           }\n       }\n   }\n}\n",
  },
  'supply_withdraw': {
    '__proto__': base,
    'name': 'supply_withdraw',
    'desc': "发起提现",
    'url': '/api/wallet/supply-withdraw',
    'args': {
      uid: {
        'pos': 0,
        'desc': "用户ID",
        'demo': '',
      },
      amount: {
        'pos': 1,
        'desc': "提现金额(单位分)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n\t\"code\": 1,\n\t\"message\": \"success!\",\n\t\"data\": {\n\t    // true 提现成功 false提现失败\n\t\t\"info\": true\n\t}\n}\n",
  }
};
