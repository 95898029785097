/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../base';

export const infos = {
  'init': {
    '__proto__': base,
    'name': 'init',
    'desc': "初始化数据\n",
    'url': '/api/not-defined-url',
    'args': {

    },
    'returns': "",
  },
  'parallel': {
    '__proto__': base,
    'name': 'parallel',
    'desc': "并发测试\n",
    'url': '/api/not-defined-url',
    'args': {

    },
    'returns': "",
  },
  'randomAbortOrByAll': {
    '__proto__': base,
    'name': 'randomAbortOrByAll',
    'desc': "随机买完或者取消\n",
    'url': '/api/not-defined-url',
    'args': {

    },
    'returns': "",
  }
};
