import * as admin from "../powder/user/scms/admin";
import * as utils from "../utils/utils";
let supplier;

export const getSupplier = async () => {
  if (supplier || (supplier = utils.getLSItem('supplier'))) {
    return supplier
  }
  supplier = (await admin.infos.adminGetSupplierList.make()).data.data[0].id;
  utils.setLSItem('supplier', supplier);
  return supplier
};
