import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/shop/order';
import * as apis from '../../../../api/client/shop/order';
import {infos as product} from '../../../user/client/shop/product';
import {infos as scms_order} from '../../scms/order';
import * as utils from "../../../../utils/utils";
import * as api_client from '../index';

async function getOneSkuID() {
  let data = (await product.productList.make()).data.data;
  return parseInt(data.info[0].productSkuByMinPrice.id);
}

async function getConfirmData(confirmScene) {
  let data = (await infos.confirm.make(confirmScene)).data.data;
  let param = {};
  if(!data.info.address.id){
    // 创建默认收货地址
    param['address_id'] = (await api_client.infos.addAddress.make()).data.data.info;
  } else {
    param['address_id'] = data.info.address.id;
  }

  // 生成sku_list参数
  let sku_list = [];
  for (let cart of data.info.cart_list) {
    for (let sku of cart.product_sku_list) {
      sku_list.push({'sku_id': parseInt(sku.id), 'quantity': parseInt(sku.cart_sku_quantity)});
    }
  }
  param['sku_list'] = sku_list;
  return param;
}

// 创建一个待支付订单
export async function create() {
  return (await infos.create.make()).data.data.info;
}

// 创建一个已支付订单
export async function createPay(createScene) {
  await api_client.infos.testNotifyWX.make('shop', createScene);
  return createInfo();
}

// 返回创建订单的返回信息
export function createInfo() {
  return infos.create.lastResult.data.data.info;
}

// 返回获取到的列表中的最后一个订单号
export async function getOrderNoByList(action) {
  let data = (await infos.list.make(action)).data.data;
  return data.info[0].order_no;
}

async function getOrderNoByConfirmReceipt() {
  await scms_order.dispatch.make('create');
  return createInfo().children_order_no[0];
}

async function getOrderNoByRefundList() {
  let data = await infos.refund.make();
  return createInfo().children_order_no[0];
}

async function getOrderNoByCancelProgressInfo() {
  let data = (await infos.refundList.make()).data.data;
  let param = {};
  param['order_no'] = data.info.order_no;
  param['refund_no'] = data.info.list[0].refund_no;
  return param;
}

async function getRefundData(scene, createScene) {
  let data = await infos.info.make(scene, createScene);
  return {
    order_no: data.data.data.info.order_no,
    product_sku_ids: [data.data.data.info.orderProduct[0].product_sku_id],
  }
}

export const infos = {

  'confirm': {
    '__proto__': base.confirm,
    'api': apis.confirm,
    'desc': " 确认订单",
    'url': '/api/shop-order/confirm',
    'defaults': {
      type: '2',
      sku_list: [],
      address_id: 0,
      source_id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            sku_list: [{"sku_id":await getOneSkuID(),"quantity":1}],
          };
        }
      },
      {
        'scene': 'sku-all',
        'desc': '两个商品的全部 sku',
        'func': async (ctx) => {
          let res = await product.productList.make('有库存', 2);
          let id1 = res.data.data.info[0].id;
          let id2 = res.data.data.info[1].id;
          // console.log('----ids:', id1, id2);

          async function getSku(id) {
            let skus = (await product.productSkuList.make('args', id)).data.data.info.productSku;
            return skus.map((item) => {
              return {'sku_id': parseInt(item.id), "quantity": 1};
            });
          }
          let sku_list = await getSku(id1);
          sku_list.push(...(await getSku(id2)));
          ctx.generated = {
            sku_list,
          };
        }
      },
    ],
  },

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': " 提交订单(创建订单)",
    'url': '/api/shop-order/create',
    'defaults': {
      type: 2,
      sku_list: [],
      address_id: '',
      coupon: [],
      source_id: '0',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = await getConfirmData();
        }
      },
      {
        'scene': 'multi-sku',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = await getConfirmData('sku-all');
        }
      },
    ],
  },

  'pay': {
    '__proto__': base.pay,
    'api': apis.pay,
    'desc': " 支付订单",
    'url': '/api/shop-order/pay',
    'defaults': {
      order_no: '',
      pay_type: 1,
      wx_code: '',
      wx_return_url: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            order_no: (await create()).order_no,
          }
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': " 订单列表",
    'url': '/api/shop-order/list',
    'defaults': {
      type: 0,
      page: 1,
      pageSize: 10,
    },
    'nullList': ['data.info.0.orderProduct.0.refund_order_product_info'],
    'keyList': ['data.info.0'],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': '获取待付款的订单列表',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = {
            type: 1
          };
        }
      },
      {
        'scene': '获取待收货的订单列表',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = {
            page: 1,
            pageSize: 10000,
            type: 2
          };
        }
      },
      {
        'scene': '获取全部订单',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = {
            page: 1,
            pageSize: 100000,
            type: 0
          };
        }
      },
    ],
  },

  'cancel': {
    '__proto__': base.cancel,
    'api': apis.cancel,
    'desc': " 取消订单",
    'url': '/api/shop-order/cancel',
    'defaults': {
      order_no: '',
      cancel_reason: '其他',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            order_no: (await create()).children_order_no[0],
          };
        }
      },
    ],
  },

  'confirmReceipt': {
    '__proto__': base.confirmReceipt,
    'api': apis.confirmReceipt,
    'desc': " 确认收货",
    'url': '/api/shop-order/confirm-receipt',
    'defaults': {
      order_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            order_no: await getOrderNoByConfirmReceipt(),
          };
        }
      },
      {
        'scene': 'with-no',
        'desc': '',
        'type': INNER,
        'func': async (ctx, no) => {
          ctx.generated = {
            order_no: no,
          };
        }
      },
      {
        'scene': 'create-multi-sku-comfirm-all',
        'desc': '生成2个商品的多个sku，批量 发货-确认发货',
        'type': GENE_ONLY,
        'func': async (ctx) => {
          await scms_order.dispatch.make('create-multi-sku-dispatch-all');
          let data = infos.create.lastResult.data;
          for (let no of data.data.info.children_order_no) {
            await ctx.make('with-no', no);
          }
        }
      },
    ],
  },

  'getRefund': {
    '__proto__': base.getRefund,
    'api': apis.getRefund,
    'desc': " 获取订单退款页面聚合数据",
    'url': '/api/shop-order/get-refund',
    'defaults': {
      order_no: '',
      product_sku_ids: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = await getRefundData('create-pay');
        }
      },
    ],
  },

  'refund': {
    '__proto__': base.refund,
    'api': apis.refund,
    'desc': " 提交订单退款",
    'url': '/api/shop-order/refund',
    'defaults': {
      order_no: '',
      product_sku_ids: [],
      refund_reason: '其他原因',
      refund_explain: '我不想要了,这个退款说明屌不屌',
      refund_images: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = await getRefundData('create-pay');
        }
      },
      {
        'scene': 'refund_part',
        'desc': '退款一部分(订单中多个商品SKU,只退款1个)',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = await getRefundData('create-pay', 'multi-sku');
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': " 订单详情",
    'url': '/api/shop-order/info',
    'defaults': {
      order_no: '',
    },
    'nullList':['data.info.orderProduct.0.refund_order_product_info'],
    'keyList': ['data.info.cancel_progress.progress', 'data.info.cancel_progress.description',
      'data.info.cancel_progress.create_time'],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            order_no: await getOrderNoByList(),
          };
        }
      },
      {
        'scene': 'create',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = {
            order_no: (await create()).children_order_no[0],
          };
        }
      },
      {
        'scene': 'create-pay',
        'desc': '',
        'func': async (ctx, createScene) => {
          ctx.generated = {
            order_no: (await createPay(createScene)).children_order_no[0],
          };
        }
      },
    ],
  },

  'refundList': {
    '__proto__': base.refundList,
    'api': apis.refundList,
    'desc': " 子订单的退款订单列表",
    'url': '/api/shop-order/refund-list',
    'defaults': {
      order_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            order_no: await getOrderNoByRefundList()
          }
        }
      },
    ],
  },

  'cancelProgressInfo': {
    '__proto__': base.cancelProgressInfo,
    'api': apis.cancelProgressInfo,
    'desc': " 取消/退款进度详情",
    'url': '/api/shop-order/cancel-progress-info',
    'defaults': {
      order_no: '',
      refund_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = await getOrderNoByCancelProgressInfo();
        }
      },
    ],
  },

  'expressInfo': {
    '__proto__': base.expressInfo,
    'api': apis.expressInfo,
    'desc': " 查看物流",
    'url': '/api/shop-order/express-info',
    'defaults': {
      order_no: '',
    },
    'nullList':['data.info.express'],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await scms_order.dispatch.make('express-notify');
          ctx.generated = {
            order_no: scms_order.dispatch.lastResult.data.data.order_no,
          };
        }
      },
    ],
  }
};
