import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc 获取用户贝壳明细列表
 * @param page 分页页码(默认1)
 * @param page_size 分页每页数据条数(默认10)
 * @param uid 用户ID
 * @param type 类型(枚举值 1:兑换 2:消费 3:管理员增加 4:管理员减少 可不传,为空时表示全部)
 * @param create_time_start 创建时间-开始时间(时间戳)
 * @param create_time_end 创建时间-结束时间(时间戳)
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 1,
    "per_page": 10,
    "current_page": 1,
    "last_page": 1,
    "__data": "k",
    "data": [
      {
        "id": 1,
        "uid": 10000,
        "type": 1, // 类型(枚举值 1:兑换(增加) 2:消费(减少) 3:管理员(增加) 4:管理员(减少) 可不传,为空时表示全部)
        "transaction_amount": 90, // 交易额(贝壳)
        "conch": 3690, // 用户可用贝壳(user表conch字段计算了本次交易额后的记录值)
        "create_time": "2023-04-19 16:22:34" // 创建时间
      }
    ]
  }
}
 */
export function getUserConchLog(page,page_size,uid,type,create_time_start,create_time_end) {
    let params = {
        url: '/scmsapi/user/getUserConchLog',
        data: {
            page,page_size,uid,type,create_time_start,create_time_end
        }
    };
    return post(params)
}

/**
 * @desc 导出用户贝壳明细列表(本接口请采用get请求，请求参数与/scmsapi/user/getUserConchLog接口的请求参数一致)
 * @param page 分页页码(默认1)
 * @param page_size 分页每页数据条数(默认10)
 * @param uid 用户ID
 * @param type 类型(枚举值 1:兑换 2:消费 3:管理员增加 4:管理员减少 可不传,为空时表示全部)
 * @param create_time_start 创建时间-开始时间(时间戳)
 * @param create_time_end 创建时间-结束时间(时间戳)
 * @returns {Promise<*>}
 */
export function exportUserConchLogList(page,page_size,uid,type,create_time_start,create_time_end) {
    let params = {
        url: '/scmsapi/user/exportUserConchLogList',
        data: {
            page,page_size,uid,type,create_time_start,create_time_end
        }
    };
    return post(params)
}

/**
 * @desc 获取用户列表
 * @param page 分页页码(默认1)
 * @param page_size 分页每页数据条数(默认10)
 * @param keyword 信息搜索
 * @param uid 用户ID
 * @param name 用户昵称
 * @param phone 电话号码
 * @param startTime 创建时间-开始时间(时间戳)
 * @param endTime 创建时间-结束时间(时间戳)
 * @param sex 性别(0女，1男)
 * @param status 状态 0正常 1禁用
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 18,
    "per_page": 15,
    "current_page": 1,
    "last_page": 2,
    "data": [
      {
        "uid": 10019, // 用户ID
        "phone": "17345155551", // 电话
        "nickname": "", // 昵称
        "remark_name": "", // 备注名
        "pic": "https://admin.bb.uninote.com.cn/oss?path=img/default_pic.png", // 头像
        "__birthday": "t", // type null key ignore
        "birthday": null, // 生日(年月日)
        "__sex": "t", // type null key ignore
        "sex": null, // 性别(0女，1男)
        "time": 1681276626, // 创建时间(时间戳)
        "last_login_time": 1681276626, // 最近登录时间(时间戳)
        "status": 0, // 状态(0正常 1禁用)
        "ip": "172.17.0.1", // 最近登录IP
        "integral": 0, // 积分余額
        "integral_accumulated": 0, // 积分总额
        "conch": 0, // 贝壳数
        "city": "局域网 ", // 最近登录地区
        "order_count": 0 // 下单总订单次数
      }
    ]
  }
}
 */
export function list(page,page_size,keyword,uid,name,phone,startTime,endTime,sex,status) {
    let params = {
        url: '/scmsapi/user/list',
        data: {
            page,page_size,keyword,uid,name,phone,startTime,endTime,sex,status
        }
    };
    return post(params)
}

/**
 * @desc 编辑用户信息
 * @param uid 用户ID
 * @param nickname 用户昵称
 * @param remark_name 备注名
 * @param sex 用户性别 0女，1男
 * @param birthday 用户出生年月日(时间戳)
 * @param phone 电话号码
 * @param status 状态 0正常 1禁用
 * @param time 创建时间(时间戳)
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function edit(uid,nickname,remark_name,sex,birthday,phone,status,time) {
    let params = {
        url: '/scmsapi/user/edit',
        data: {
            uid,nickname,remark_name,sex,birthday,phone,status,time
        }
    };
    return post(params)
}

/**
 * @desc 获取用户信息
 * @param uid 用户ID
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "uid": 10019, // 用户ID
    "phone": "17345155551", // 电话
    "nickname": "", // 昵称
    "remark_name": "", // 备注名
    "__birthday": "t", // type null key ignore
    "birthday": null, // 生日(年月日)
    "__sex": "t", // type null key ignore
    "sex": null, // 性别(0女，1男)
    "time": 1681276626, // 创建时间(时间戳)
    "status": 0, // 状态(0正常 1禁用)
    "pic": "https://admin.bb.uninote.com.cn/oss?path=img/default_pic.png",
    "last_login_time": 1691161479,
    "ip": "127.0.0.1",
    "integral": 0,
    "integral_accumulated": 0,
    "conch": 163
  }
}
 */
export function info(uid) {
    let params = {
        url: '/scmsapi/user/info',
        data: {
            uid
        }
    };
    return post(params)
}

/**
 * @desc 获取用户积分明细列表
 * @param page 分页页码(默认1)
 * @param page_size 分页每页数据条数(默认10)
 * @param uid 用户ID
 * @param type 类型(枚举值 1:购买-常规福袋 2:购买-超级福袋 3:购买-小卖部 4:消费)
 * @param order_no 订单编号(模糊查询)
 * @param create_time_start 创建时间-开始时间(时间戳)
 * @param create_time_end 创建时间-结束时间(时间戳)
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 2,
    "per_page": 15,
    "current_page": 1,
    "last_page": 1,
    "__data": "k",
    "data": [
      {
        "id": 2,
        "uid": 10015, // 用户ID
        "type": 2, // 类型(枚举值 1:购买-福袋 3:购买-小卖部 4:消费 5:小卖部退款)
        "type_text": "购买-福袋",
        "order_no": "xx002", // 订单编号
        "transaction_amount": 111, // 交易额(积分)
        "integral": 11, // 用户可用积分
        "integral_accumulated": 111, // 用户累计积分
        "create_time": "2023-03-28 12:37:06" // 创建时间
      }
    ]
  }
}
 */
export function getUserIntegralLog(page,page_size,uid,type,order_no,create_time_start,create_time_end) {
    let params = {
        url: '/scmsapi/user/getUserIntegralLog',
        data: {
            page,page_size,uid,type,order_no,create_time_start,create_time_end
        }
    };
    return post(params)
}

/**
 * @desc 删除用户
 * @param uid 用户ID
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function del(uid) {
    let params = {
        url: '/scmsapi/user/del',
        data: {
            uid
        }
    };
    return post(params)
}

/**
 * @desc 导出用户列表(本接口请采用get请求，请求参数与/scmsapi/user/list接口的请求参数一致)
 * @param page 分页页码(默认1)
 * @param page_size 分页每页数据条数(默认10)
 * @param keyword 信息搜索
 * @param uid 用户ID
 * @param name 用户昵称
 * @param phone 电话号码
 * @param startTime 创建时间-开始时间(时间戳)
 * @param endTime 创建时间-结束时间(时间戳)
 * @param sex 性别(0女，1男)
 * @param status 状态 0正常 1禁用
 * @returns {Promise<*>}
 */
export function exportUserList(page,page_size,keyword,uid,name,phone,startTime,endTime,sex,status) {
    let params = {
        url: '/scmsapi/user/exportUserList',
        data: {
            page,page_size,keyword,uid,name,phone,startTime,endTime,sex,status
        }
    };
    return post(params)
}

/**
 * @desc 导出用户积分明细列表(本接口请采用get请求，请求参数与/scmsapi//user/getUserIntegralLog接口的请求参数一致)
 * @param page 分页页码(默认1)
 * @param page_size 分页每页数据条数(默认10)
 * @param uid 用户ID
 * @param type 类型(枚举值 1:购买-常规福袋 2:购买-超级福袋 3:购买-小卖部 4:消费)
 * @param order_no 订单编号(模糊查询)
 * @param create_time_start 创建时间-开始时间(时间戳)
 * @param create_time_end 创建时间-结束时间(时间戳)
 * @returns {Promise<*>}
 */
export function exportUserIntegralLogList(page,page_size,uid,type,order_no,create_time_start,create_time_end) {
    let params = {
        url: '/scmsapi/user/exportUserIntegralLogList',
        data: {
            page,page_size,uid,type,order_no,create_time_start,create_time_end
        }
    };
    return post(params)
}

/**
 * @desc 管理员修改用户贝壳值
 *
 * @param uid
 * @param delta
 * @param comment
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": 139
}
 */
export function changeConch(uid, delta, comment) {
  let params = {
    url: '/scmsapi/user/changeConch',
    data: {
      uid, delta, comment,
    }
  };
  return post(params);
}
