import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/product_category';
import * as apis from '../../../api/scms/product_category';
import * as utils from "../../../utils/utils";
import * as utils_product_category  from '@/utils/product_category';
import {infos as admin} from '../../user/scms/admin';

// 这里用随机数更简单
async function getCreateDataBySort() {
  let data = (await infos.list.make('all')).data.data;
  if(data.total == 0){
    return 1;
  }

  let sorts = [];
  data.data.forEach((item)=>{
    sorts.push(item.sort);
  });
  return Math.max.apply(null,sorts) + 1;
}

export const infos = {
  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': " 创建商品分类",
    'url': '/scmsapi/product_category/create',
    'defaults': {
      name: '',
      sort: 1,
      product_category_image: {},
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            name: "自动生成的分类名称" + await utils.getRandomChineseWord(),
            sort: await getCreateDataBySort(),
            product_category_image: (await admin.uploadImage.make()).data.data[0],
          };
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': " 获取商品分类列表",
    'url': '/scmsapi/product_category/list',
    'defaults': {
      id: '',
      name: '',
      page: '',
      page_size: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': 'all',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            page: '1',
            page_size: '99999999',
          };
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': " 编辑商品分类",
    'url': '/scmsapi/product_category/update',
    'defaults': {
      name: '',
      sort: '',
      id: '',
      product_category_image: {},
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            name: "自动生成的分类名称" + await utils.getRandomChineseWord(),
            sort: await getCreateDataBySort(),
            id: await utils_product_category.getCategoryOneID(),
            product_category_image: (await admin.uploadImage.make()).data.data[0]
          };
        }
      },
    ],
  },

  'updateStatus': {
    '__proto__': base.updateStatus,
    'api': apis.updateStatus,
    'desc': " 更新商品分类状态(启用|禁用|删除)",
    'url': '/scmsapi/product_category/updateStatus',
    'defaults': {
      status: '',
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            status: '3',
            id: await utils_product_category.getCategoryOneID(),
          };
        }
      },
    ],
  }
};
