import {dajxHttp} from "../../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc 创建常规福袋
 *
 * @param type 福袋类型：1 常规福袋，2 超级福袋的一级福袋，3 超级福袋的二级福袋，4 加加乐，5 刮刮乐
 * @param name 福袋名
 * @param price 参考价格
 * @param start_time 开始售卖时间
 * @param end_time 结束售卖时间
 * @param sale_type 类型：1 满开, 2 即买即开
 * @param for_sale 0 下架，1 上架
 * @param desc 福袋描述
 * @param img 福袋配图
 * @param boxes 福袋关联盲盒数组，每一个元素结构：
 *   {
    "name": "血色玫瑰", // 盲盒名
    "price": 120, // 参考价格
    "total": 2, // 总数量，默认为1
    "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg", // 盲盒配图
    "conch_value": 90, // 可兑换的贝壳值，0表示不可兑换
    "condition": 1 // 品相 1 全新未拆盒，2 拆盒未拆袋，3 已拆袋
  },
 * @param rank_boxes 福袋关联的排名盲盒数组，每一个元素结构：
  {
    "name": "血色茉莉", // 盲盒名
    "price": 100, // 参考价格
    "rank": 2, // 排名
    "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg", // 盲盒配图
    "conch_value": 0, // 可兑换的贝壳值，0表示不可兑换
    "condition": 1 // 品相 1 全新未拆盒，2 拆盒未拆袋，3 已拆袋
  }
  @param sum_boxes 福袋关联的和值盲盒数组，每一个元素结构：
  {
    "name": "血色茉莉", // 盲盒名
    "price": 100, // 参考价格
    "sum": 2, // 和值
    "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg", // 盲盒配图
    "conch_value": 0, // 可兑换的贝壳值，0表示不可兑换
    "condition":, 1 // 品相 1 全新未拆盒，2 拆盒未拆袋，3 已拆袋
    "once_more": 0 // 再来一次 0 否 1 是
  }
 * @param sub_bags 二级福袋，每个二级福袋只接受三个参数：name，img，boxes；其中 boxes 的字段同顶级 boxes
 * @param prob 刮刮乐再来一次概率
 * @param hidden 福袋是否对普通用户隐藏
 * @param allow_conch 是否支持贝壳支付
 * @param last_count 最后单数
 * @param limit_count 最低购买单数
 * @param profit 成本/收益数据
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": {
    "id": 30, // 福袋id
    "box_ids": [ // 关联的盲盒id
      34
    ],
    "__rank_box_ids": "k",
    "rank_box_ids": [ // 关联的排名盲盒id
      66
    ],
    "__sum_box_ids": "k",
    "sum_box_ids": [ // 关联的和值盲盒id
      66
    ],
    "__sub_bags": "k",
    "sub_bags": [
      {
        "id": 4,
        "box_ids": [
          7,
        ]
      },
    ]
  }
}
 */
export function create(type, name, price, start_time, end_time,
  sale_type, for_sale, desc, img, boxes, rank_boxes, sum_boxes, sub_bags, prob,
  hidden, allow_conch, last_count, limit_count, profit) {
  let params = {
    url: '/scmsapi/blindbox/bag/create',
    data: {
      type, name, price, start_time, end_time,
      sale_type, for_sale, desc, img, boxes, rank_boxes, sum_boxes, sub_bags, prob,
      hidden, allow_conch, last_count, limit_count, profit
    }
  };
  return post(params);
}

/**
 * @desc 更新常规福袋
 *
 * @param name 福袋名
 * @param price 参考价格
 * @param start_time 开始售卖时间
 * @param end_time 结束售卖时间
 * @param sale_type 类型：1 满开, 2 即买即开
 * @param for_sale 0 下架，1 上架
 * @param desc 福袋描述
 * @param img 福袋配图
 * @param boxes 福袋关联盲盒数组，每一个元素结构：
 *   {
    "id": 1,
    "name": "血色玫瑰", // 盲盒名
    "price": 120, // 参考价格
    "total": 2, // 总数量，默认为1
    "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg", // 盲盒配图
    "conch_value": 90, // 可兑换的贝壳值，0表示不可兑换
    "condition": 1 // 品相 1 全新未拆盒，2 拆盒未拆袋，3 已拆袋
  },
 * @param rank_boxes 福袋关联的排名盲盒数组，每一个元素结构：
  {
    "id": 2,
    "name": "血色茉莉", // 盲盒名
    "price": 100, // 参考价格
    "rank": 2, // 排名
    "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg", // 盲盒配图
    "conch_value": 0, // 可兑换的贝壳值，0表示不可兑换
    "condition": 1 // 品相 1 全新未拆盒，2 拆盒未拆袋，3 已拆袋
  }
 * @param sum_boxes 福袋关联的和值盲盒数组，每一个元素结构：
  {
    "id": 2,
    "name": "血色茉莉", // 盲盒名
    "price": 100, // 参考价格
    "sum": 2, // 和值
    "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg", // 盲盒配图
    "conch_value": 0, // 可兑换的贝壳值，0表示不可兑换
    "condition": 1, // 品相 1 全新未拆盒，2 拆盒未拆袋，3 已拆袋
    "once_more": 0 // 再来一次 0 否 1 是
  }
 * @param sub_bags 二级福袋，每个二级福袋只接受三个参数：name，img，boxes；其中 boxes 的字段同顶级 boxes
 * @param prob 刮刮乐再来一次概率
 * @param hidden 福袋是否对普通用户隐藏
 * @param allow_conch 是否支持贝壳支付
 * @param last_count 最后单数
 * @param limit_count 最低购买单数
 * @param profit 成本/收益数据
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": {
    "box_ids": [
      127
    ],
    "__rank_box_ids": "k",
    "rank_box_ids": [ // 关联的排名盲盒id
      65
    ],
    "__sum_box_ids": "k",
    "sum_box_ids": [ // 关联的和值盲盒id
      65
    ],
    "__sub_bags": "k",
    "sub_bags": [
      {
        "id": 4,
        "box_ids": [
          7,
        ]
      },
    ]
  }
}
 */
export function update(id, name, price, start_time, end_time,
  sale_type, for_sale, desc, img, boxes, rank_boxes, sum_boxes, sub_bags, prob,
  hidden, allow_conch, last_count, limit_count, profit) {
  let params = {
    url: '/scmsapi/blindbox/bag/update',
    data: {
      id, name, price, start_time, end_time,
      sale_type, for_sale, desc, img, boxes, rank_boxes, sum_boxes, sub_bags, prob,
      hidden, allow_conch, last_count, limit_count, profit
    }
  };
  return post(params);
}

/**
 * @desc 福袋上下架
 *
 * @param id 福袋ID
 * @param for_sale 0 下架，1 上架
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function updateForSale(id, for_sale) {
  let params = {
    url: '/scmsapi/blindbox/bag/updateForSale',
    data: {
      id, for_sale
    }
  };
  return post(params);
}

/**
 * @desc 常规福袋列表
 *
 * @returns {Promise<*>} {
  // 没有注释的字段意义参见 create 接口
  "code": 200,
  "msg": "success",
  "data": {
    "total": 21,
    "per_page": 10,
    "current_page": 1,
    "last_page": 3,
    "data": [
      {
        "id": 25,
        "type": 1,
        "name": "福袋",
        "price": 100,
        "total": 3, // 盲盒+二级福袋资格总个数（位置总数）
        "leftover": 3, // 剩余位置个数（未支付的不扣减这里）
        "start_time": 1679897782,
        "end_time": 1679900382,
        "create_time": "2023-03-27 14:00:12",
        "sale_type": 1,
        "status": 1,
        "for_sale": 1,
        "desc": "福袋描述",
        "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
        "prob": 10, // 刮刮乐再来一次概率
        "hidden": 0, // 福袋是否对普通用户隐藏 0 否, 1 是
        "allow_conch": 1,
        "max_sale": 28.34,
        "exp_sale": 27.69,
        "all_conch": 684.3,
        "sale_conch": 0,
        "sale_amount": 0,
        "sale_refund": 0,
        "once_more_count": 0,
        "gross_income": 0,
        "gross_profit": null
      }
    ]
  }
}
 */
export function list(id, name, status, type, sale_type, for_sale) {
  let params = {
    url: '/scmsapi/blindbox/bag/list',
    data: {
      id, name, status, type, sale_type, for_sale
    }
  };
  return post(params);
}

/**
 * @desc 获取福袋信息
 *
 * @param int id
 * @returns {Promise<*>} {
  // 没有注释的字段意义参见 create 接口
  "code": 200,
  "msg": "success",
  "data": {
    "id": 20,
    "type": 1,
    "name": "福袋",
    "price": 100,
    "total": 3, // 盲盒+二级福袋资格总个数（位置总数）
    "leftover": 3, // 剩余位置个数（未支付的不扣减这里）
    "start_time": 1679890237,
    "end_time": 1679893837,
    "create_time": "2023-03-27 13:53:41",
    "sale_type": 1,
    "status": 1,
    "for_sale": 1,
    "desc": "福袋描述",
    "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
    "prob": 10, // 刮刮乐再来一次概率
    "hidden": 0, // 福袋是否对普通用户隐藏 0 否, 1 是
    "allow_conch": 1,
    "boxes": [
      {
        "id": 14,
        "name": "血色玫瑰",
        "price": 120,
        "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
        "total": 2,
        "leftover": 2,
        "conch_value": 90,
        "condition": 1,
        "create_time": "2023-03-27 13:53:42",
        "status": 1
      }
    ],
    "__rank_boxes": "k",
    "rank_boxes": [
      {
        "id": 2,
        "bag_id": 20,
        "rank": 2,
        "name": "血色茉莉",
        "price": 100,
        "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
        "conch_value": 0,
        "condition": 1,
        "create_time": "2023-03-27 13:53:44",
        "update_time": "2023-03-27 13:53:44",
        "status": 1
      }
    ],
    "__sum_boxes": "k",
    "sum_boxes": [
      {
        "id": 2,
        "bag_id": 20,
        "sum": 2,
        "name": "血色茉莉",
        "price": 100,
        "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
        "conch_value": 0,
        "condition": 1,
        "once_more": 0,
        "create_time": "2023-03-27 13:53:44",
        "update_time": "2023-03-27 13:53:44",
        "status": 1
      }
    ],
    "__sub_bags": "k",
    "sub_bags": [
      {
        "id": 2,
        "name": "二级福袋A",
        "total": 10,
        "leftover": 10,
        "img": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/5de7a8902f451e1c61984a1d5c6546fa.jpeg",
        "boxes": [
          {
            "id": 1,
            "name": "21",
            "price": 120,
            "img": "https://admin.bb.uninote.com.cn/oss?path=bb_ty/2023-12/dc9be7cc79136f9a886f29639c616785.jpeg",
            "total": 2,
            "leftover": 2,
            "conch_value": 90,
            "condition": 1,
            "create_time": "2023-12-26 15:34:31",
            "status": 1
          }
        ]
      }
    ],
  }
}
 */
export function info(id) {
  let params = {
    url: '/scmsapi/blindbox/bag/info',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc 终止福袋
 *
 * @param id 要终止的福袋id
 * @returns {Promise<*>} {}
 */
export function abort(id) {
  let params = {
    url: '/scmsapi/blindbox/bag/abort',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc 终止所有过期的福袋
 *
 * @param all 如果是不为空的任意字符串，则包含状态为退款中的福袋（相当于重新退款）
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "__data": "k",
  "data": [
    29
  ]
}
 */
export function abortAllExpired(all) {
  let params = {
    url: '/scmsapi/blindbox/bag/abortAllExpired',
    data: {
      all,
    }
  };
  return post(params);
}

/**
 * @desc 获取福袋按用户分组统计信息
 *
 * @param ids 福袋 id 列表，以','分隔
 * @param combined 是否合并福袋
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": [
    {
      "id": 335,
      "type": 4,
      "name": "加加乐1",
      "uid": 10000,
      "nickname": "555",
      "remark_name": "",
      "sale_amount": "50008",
      "sale_refund": "0",
      "total_conch": "230",
      "count": 10,
      "once_more_count": 9
    }
  ]
}
 */
export function getStatDataUser(ids, combined) {
  let params = {
    url: '/scmsapi/blindbox/bag/getStatDataUser',
    data: {
      ids,
      combined
    }
  };
  return post(params);
}

/**
 * @desc 更新福袋统计数据。不传参数则是全量更新
 *
 * @param ids 福袋 id 数组
 * @param back_days 更新创建时间为 back_days(天) 以内的福袋
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": 6 // 更新的记录数
}
 */
export function updateStat(ids, back_days) {
  let params = {
    url: '/scmsapi/blindbox/bag/updateStat',
    data: {
      ids,
      back_days,
    }
  };
  return post(params);
}
