//全局配置

//开发模式
let redirectToLogin = false;

//线上环境

//微信小程序版本号
let WxMiniVersion = '1.0.4';

export {
    WxMiniVersion,
    redirectToLogin,
}
