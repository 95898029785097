import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;


/**
 * @desc 获取已上架商品select
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": [
    {
      "id": 12051,
      "title": "混元死茶",
      "limit_number": 100,
    }
  ]
}
 */
export function getProductSelect() {
  let params = {
    url: '/scmsapi/promotion/getProductSelect',
    data: {}
  };
  return post(params);
}



/**
 * @desc 创建促销活动
 * @param product_id 商品id
 * @param product_promotion_category_id 商品促销活动分类id
 * @param start_time 促销开始时间
 * @param end_time 促销结束时间
 * @param limit_number 限购数量
 * @param product_sku 活动sku信息 数组
 * [{
        "product_sku_id": sku的id,
        "promotion_price": 促销价,
        "promotion_number": 促销数量,
        "status": 1正常 2禁用
    }]
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": {
    "id": 36
  }
}
 */
export function create(product_id,product_promotion_category_id,start_time,end_time,limit_number,product_sku) {
  let params = {
    url: '/scmsapi/promotion/create',
    data: {
      product_id,start_time,end_time,limit_number,product_sku,product_promotion_category_id
    }
  };
  return post(params);
}


/**
 * @desc 更新促销活动
 * @param id 活动id
 * @param product_id 商品id
 * @param product_promotion_category_id 商品促销活动分类id
 * @param start_time 促销开始时间
 * @param end_time 促销结束时间
 * @param limit_number 限购数量
 * @param product_sku 活动sku信息 数组
 * [{
        "product_sku_id": sku的id,
        "promotion_price": 促销价,
        "promotion_number": 促销数量,
        "status": 1正常 2禁用
    }]
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function update(id,product_id,start_time,end_time,limit_number,product_sku,product_promotion_category_id) {
  let params = {
    url: '/scmsapi/promotion/update',
    data: {
      id,product_id,start_time,end_time,limit_number,product_sku,product_promotion_category_id
    }
  };
  return post(params);
}

/**
 * @desc 获取活动详情（用于查看或编辑）
 * @param id 活动id
 * @param action 动作（默认info=查看详情，edit=编辑数据）
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "id": 6,
        "product_id": 120,
        "start_time": 1605715202, // 活动开始
        "end_time": 1665715203, // 活动结束
        "actual_end_time": 0, // 实际结束时间
        "limit_number": 1, // 限购数量
        // 商品促销活动分类id
        product_promotion_category_id: 0,
        "status": 1, // 状态 1上架 2下架 3已完成 （1里面分为未开始，进行中）
        "product": {
            "title": "测试商品",
            "unit": "个",
            // 商品的限购数量
            "limit_number": 100
        },
        "promotion_sku": [{
            "id": 18,
            "product_sku_id": 364,
            "promotion_price": 10000, // 促销价
            "promotion_number": 10, // 促销数量
            "used_number": 0,
            "status": 1, // 1启用 0禁用
            "product_spec": [{
                "product_spec_key": "重量",
                "product_spec_value": "2KG"
            }],
            "product_price": 1 //原价
        }]
    }
}
 */
export function info(id,action) {
  let params = {
    url: '/scmsapi/promotion/info',
    data: {
      id,action
    }
  };
  return post(params);
}


/**
 * @desc 获取对应商品sku
 * @param id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": {
    "id": 12051,
    "title": "混元死茶",
    "unit": "梯", //单位
    "product_sku": [
      {
        "id": 3763, // sku_id
        "product_spec": [
          {
            "product_spec_key": "五行",
            "product_spec_value": "风"
          }
        ],
        "price": 34876 // 价格
      }
    ]
  }
}
 */
export function getProductSku(id) {
  let params = {
    url: '/scmsapi/promotion/getProductSku',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc 上下架
 * @param id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function updateStatus(id) {
  let params = {
    url: '/scmsapi/promotion/updateStatus',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc 活动删除
 * @param id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function del(id) {
  let params = {
    url: '/scmsapi/promotion/delete',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc 活动列表
 * @param id 活动id
 * @param start_time 活动开始时间
 * @param end_time 活动结束时间
 * @param product_title 商品名
 * @param status 状态（1已上架，2已下架，3已结束，4未开始，5进行中）
 * @param page 页码
 * @param product_promotion_category_id 商品促销活动分类id
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "total": 1,
        "per_page": 10,
        "current_page": 1,
        "last_page": 1,
        "data": [{
            "id": 6,
            "start_time": 1605715202, // 开始时间
            "end_time": 1665715203, // 结束时间
            "limit_number": 1, // 限购数量
            "status": 1, // 状态 1上架 2下架 3已完成 （1里面分为未开始，进行中）
            "create_time": "2020-11-17 11:35:53",
            "product_id": 120,
            "product": {
                "title": "测试商品",
                "status": 1  // 状态 1=上架 2=下架
            },
            // 商品促销活动分类
            "promotionCategory": {
                // 商品促销活动分类ID
                "id": 40,
                // 商品促销活动分类名称
                "name": "测试"
            }
        }]
    }
}
 */
export function index(id, start_time,end_time,product_title,status,page,product_promotion_category_id) {
  let params = {
    url: '/scmsapi/promotion/index',
    data: {
      id, start_time,end_time,product_title,status,page,product_promotion_category_id
    }
  };
  return post(params);
}

/**
 * @desc 关闭活动
 * @param id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function close(id) {
  let params = {
    url: '/scmsapi/promotion/close',
    data: {
      id
    }
  };
  return post(params);
}
