/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'getProductSelect': {
    '__proto__': base,
    'name': 'getProductSelect',
    'desc': "获取已上架商品select",
    'url': '/scmsapi/promotion/getProductSelect',
    'args': {

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": [\n   {\n     \"id\": 12051,\n     \"title\": \"混元死茶\",\n     \"limit_number\": 100,\n   }\n ]\n}\n",
  },
  'create': {
    '__proto__': base,
    'name': 'create',
    'desc': "创建促销活动",
    'url': '/scmsapi/promotion/create',
    'args': {
      product_id: {
        'pos': 0,
        'desc': "商品id",
        'demo': '',
      },
      product_promotion_category_id: {
        'pos': 1,
        'desc': "商品促销活动分类id",
        'demo': '',
      },
      start_time: {
        'pos': 2,
        'desc': "促销开始时间",
        'demo': '',
      },
      end_time: {
        'pos': 3,
        'desc': "促销结束时间",
        'demo': '',
      },
      limit_number: {
        'pos': 4,
        'desc': "限购数量",
        'demo': '',
      },
      product_sku: {
        'pos': 5,
        'desc': "活动sku信息 数组\n[{\n      \"product_sku_id\": sku的id,\n      \"promotion_price\": 促销价,\n      \"promotion_number\": 促销数量,\n      \"status\": 1正常 2禁用\n  }]",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"id\": 36\n }\n}\n",
  },
  'update': {
    '__proto__': base,
    'name': 'update',
    'desc': "更新促销活动",
    'url': '/scmsapi/promotion/update',
    'args': {
      id: {
        'pos': 0,
        'desc': "活动id",
        'demo': '',
      },
      product_id: {
        'pos': 1,
        'desc': "商品id",
        'demo': '',
      },
      start_time: {
        'pos': 2,
        'desc': "促销开始时间",
        'demo': '',
      },
      end_time: {
        'pos': 3,
        'desc': "促销结束时间",
        'demo': '',
      },
      limit_number: {
        'pos': 4,
        'desc': "限购数量",
        'demo': '',
      },
      product_sku: {
        'pos': 5,
        'desc': "活动sku信息 数组\n[{\n      \"product_sku_id\": sku的id,\n      \"promotion_price\": 促销价,\n      \"promotion_number\": 促销数量,\n      \"status\": 1正常 2禁用\n  }]",
        'demo': '',
      },
      product_promotion_category_id: {
        'pos': 6,
        'desc': "商品促销活动分类id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'info': {
    '__proto__': base,
    'name': 'info',
    'desc': "获取活动详情（用于查看或编辑）",
    'url': '/scmsapi/promotion/info',
    'args': {
      id: {
        'pos': 0,
        'desc': "活动id",
        'demo': '',
      },
      action: {
        'pos': 1,
        'desc': "动作（默认info=查看详情，edit=编辑数据）",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"id\": 6,\n       \"product_id\": 120,\n       \"start_time\": 1605715202, // 活动开始\n       \"end_time\": 1665715203, // 活动结束\n       \"actual_end_time\": 0, // 实际结束时间\n       \"limit_number\": 1, // 限购数量\n       // 商品促销活动分类id\n       product_promotion_category_id: 0,\n       \"status\": 1, // 状态 1上架 2下架 3已完成 （1里面分为未开始，进行中）\n       \"product\": {\n           \"title\": \"测试商品\",\n           \"unit\": \"个\",\n           // 商品的限购数量\n           \"limit_number\": 100\n       },\n       \"promotion_sku\": [{\n           \"id\": 18,\n           \"product_sku_id\": 364,\n           \"promotion_price\": 10000, // 促销价\n           \"promotion_number\": 10, // 促销数量\n           \"used_number\": 0,\n           \"status\": 1, // 1启用 0禁用\n           \"product_spec\": [{\n               \"product_spec_key\": \"重量\",\n               \"product_spec_value\": \"2KG\"\n           }],\n           \"product_price\": 1 //原价\n       }]\n   }\n}\n",
  },
  'getProductSku': {
    '__proto__': base,
    'name': 'getProductSku',
    'desc': "获取对应商品sku",
    'url': '/scmsapi/promotion/getProductSku',
    'args': {
      id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"id\": 12051,\n   \"title\": \"混元死茶\",\n   \"unit\": \"梯\", //单位\n   \"product_sku\": [\n     {\n       \"id\": 3763, // sku_id\n       \"product_spec\": [\n         {\n           \"product_spec_key\": \"五行\",\n           \"product_spec_value\": \"风\"\n         }\n       ],\n       \"price\": 34876 // 价格\n     }\n   ]\n }\n}\n",
  },
  'updateStatus': {
    '__proto__': base,
    'name': 'updateStatus',
    'desc': "上下架",
    'url': '/scmsapi/promotion/updateStatus',
    'args': {
      id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'del': {
    '__proto__': base,
    'name': 'del',
    'desc': "活动删除",
    'url': '/scmsapi/promotion/delete',
    'args': {
      id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'index': {
    '__proto__': base,
    'name': 'index',
    'desc': "活动列表",
    'url': '/scmsapi/promotion/index',
    'args': {
      id: {
        'pos': 0,
        'desc': "活动id",
        'demo': '',
      },
      start_time: {
        'pos': 1,
        'desc': "活动开始时间",
        'demo': '',
      },
      end_time: {
        'pos': 2,
        'desc': "活动结束时间",
        'demo': '',
      },
      product_title: {
        'pos': 3,
        'desc': "商品名",
        'demo': '',
      },
      status: {
        'pos': 4,
        'desc': "状态（1已上架，2已下架，3已结束，4未开始，5进行中）",
        'demo': '',
      },
      page: {
        'pos': 5,
        'desc': "页码",
        'demo': '',
      },
      product_promotion_category_id: {
        'pos': 6,
        'desc': "商品促销活动分类id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"total\": 1,\n       \"per_page\": 10,\n       \"current_page\": 1,\n       \"last_page\": 1,\n       \"data\": [{\n           \"id\": 6,\n           \"start_time\": 1605715202, // 开始时间\n           \"end_time\": 1665715203, // 结束时间\n           \"limit_number\": 1, // 限购数量\n           \"status\": 1, // 状态 1上架 2下架 3已完成 （1里面分为未开始，进行中）\n           \"create_time\": \"2020-11-17 11:35:53\",\n           \"product_id\": 120,\n           \"product\": {\n               \"title\": \"测试商品\",\n               \"status\": 1  // 状态 1=上架 2=下架\n           },\n           // 商品促销活动分类\n           \"promotionCategory\": {\n               // 商品促销活动分类ID\n               \"id\": 40,\n               // 商品促销活动分类名称\n               \"name\": \"测试\"\n           }\n       }]\n   }\n}\n",
  },
  'close': {
    '__proto__': base,
    'name': 'close',
    'desc': "关闭活动",
    'url': '/scmsapi/promotion/close',
    'args': {
      id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  }
};
