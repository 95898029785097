import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc 登录
 * @param username
 * @param password
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "id": 3,
        "username": "admin",
        "nickname": "超级管理员",
        "role_id": 0,
        "creator_id": 0,
        "token": "09c877a6cdafec13433a490bf517dadd",
        "mark": ""
    }
}
 */
export function login(username, password) {
  let params = {
    url: '/scmsapi/auth/login',
    data: {
      "username": username,
      "password": password
    }
  };
  return post(params);
}

/**
 * @desc 退出登录
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function logout() {
  let params = {
    url: '/scmsapi/auth/logout',
    data: {}
  };
  return post(params);
}

/**
 * @desc 获取登录菜单
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": [
    {
      "id": 1,
      "title": "角色管理",
      "mark": "admin_role"
    }
  ]
}
 */
export function menu() {
  let params = {
    url: '/scmsapi/auth/menu',
    data: {}
  };
  return post(params);
}

/**
 * @desc 修改密码
 * @param old_password 原密码
 * @param password 新密码
 * @param confirm_pwd 重复新密码
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function updatePwd(old_password,password,confirm_pwd) {
  let params = {
    url: '/scmsapi/auth/updatePwd',
    data: {
      old_password,
      password,
      confirm_pwd
    }
  };
  return post(params);
}
