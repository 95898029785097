import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/shop/coupon';
import * as apis from '../../../../api/client/shop/coupon';
import * as scms_coupon from '../../../user/scms/coupon';

// 异步初始化接口
export const __init = async(force = false) => {
  return scms_coupon.__init(force);
};

export const infos = {

  'shopCouponList': {
    '__proto__': base.shopCouponList,
    'api': apis.shopCouponList,
    'desc': " [商城模块]我的优惠券",
    'url': '/api/shop-coupon/list',
    'defaults': {
      type: '1',
      pageSize: '10',
      page: '1',
    },
    'keyList':['data.info.0','data.info.0.coupon_product_ids'],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'shopCouponPick': {
    '__proto__': base.shopCouponPick,
    'api': apis.shopCouponPick,
    'desc': " [商城模块]领取优惠券",
    'url': '/api/shop-coupon/pick',
    'defaults': {
      id: '9',
      code: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: (await scms_coupon.infos.create.make()).data.data.id,
          };
        }
      },
    ],
  },
};
