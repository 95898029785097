import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/scms/blindbox/bag';
import * as apis from '../../../../api/scms/blindbox/bag';

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': "创建常规福袋\n",
    'url': '/scmsapi/blindbox/bag/create',
    'defaults': {
      type: '',
      name: '',
      price: '',
      start_time: '',
      end_time: '',
      sale_type: '',
      for_sale: '',
      desc: '',
      img: '',
      boxes: '',
      rank_boxes: '',
      sum_boxes: '',
      sub_bags: '',
      prob: '',
      hidden: '',
      allow_conch: '',
      last_count: '',
      limit_count: '',
      profit: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': "更新常规福袋\n",
    'url': '/scmsapi/blindbox/bag/update',
    'defaults': {
      id: '',
      name: '',
      price: '',
      start_time: '',
      end_time: '',
      sale_type: '',
      for_sale: '',
      desc: '',
      img: '',
      boxes: '',
      rank_boxes: '',
      sum_boxes: '',
      sub_bags: '',
      prob: '',
      hidden: '',
      allow_conch: '',
      last_count: '',
      limit_count: '',
      profit: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updateForSale': {
    '__proto__': base.updateForSale,
    'api': apis.updateForSale,
    'desc': "福袋上下架\n",
    'url': '/scmsapi/blindbox/bag/updateForSale',
    'defaults': {
      id: '',
      for_sale: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "常规福袋列表\n",
    'url': '/scmsapi/blindbox/bag/list',
    'defaults': {
      id: '',
      name: '',
      status: '',
      type: '',
      sale_type: '',
      for_sale: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "获取福袋信息\n",
    'url': '/scmsapi/blindbox/bag/info',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'abort': {
    '__proto__': base.abort,
    'api': apis.abort,
    'desc': "终止福袋\n",
    'url': '/scmsapi/blindbox/bag/abort',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'abortAllExpired': {
    '__proto__': base.abortAllExpired,
    'api': apis.abortAllExpired,
    'desc': "终止所有过期的福袋\n",
    'url': '/scmsapi/blindbox/bag/abortAllExpired',
    'defaults': {
      all: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getStatDataUser': {
    '__proto__': base.getStatDataUser,
    'api': apis.getStatDataUser,
    'desc': "获取福袋按用户分组统计信息\n",
    'url': '/scmsapi/blindbox/bag/getStatDataUser',
    'defaults': {
      ids: '',
      combined: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updateStat': {
    '__proto__': base.updateStat,
    'api': apis.updateStat,
    'desc': "更新福袋统计数据。不传参数则是全量更新\n",
    'url': '/scmsapi/blindbox/bag/updateStat',
    'defaults': {
      ids: '',
      back_days: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
