import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;


/**
 * @desc 创建优惠券
 * @param type 类型（1无门槛，2满减，3折扣）
 * @param nature 属性(无门槛为0,满减100|50,折扣0.8)
 * @param product_ids 所属商品 必须为商品id数组 [1,2,3]
 * @param num 优惠券总量，会生成对应总量的子码
 * @param pick_start_time 领取开始 int秒时间戳
 * @param pick_end_time 领取结束 int秒时间戳
 * @param use_start_time 使用开始 int秒时间戳
 * @param use_end_time 使用结束 int秒时间戳
 * @param is_use 是否使用（1是，0否）
 * @param is_pick 是否领取（1是，0否
 * @param is_child 是否产生子码（1是，0否
 * @param remarks 备注
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": {
    "code": "aYrihaWw",
    "id": 214
  }
}
 */
export function create(type,nature,product_ids,num,pick_start_time,pick_end_time,use_start_time,use_end_time,is_use,is_pick,remarks,is_child) {
  let params = {
    url: '/scmsapi/coupon/create',
    data: {
      type,
      nature,
      product_ids,
      num,
      pick_start_time,
      pick_end_time,
      use_start_time,
      use_end_time,
      is_use,
      is_pick,
      remarks,
      is_child
    }
  };
  return post(params);
}

/**
 * @desc 获取优惠券信息
 * @param id 优惠券id
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "id": 8,
        "num": 1, // 总量（固定值）
        "code": "3bfHrNWD", // 编码
        "remains": 0, // 剩余数量
        "pick_start_time": 1605715200, // 开始领取时间
        "pick_end_time": 1606723614, // 结束领取时间
        "use_start_time": 1605715200, // 开始使用时间
        "use_end_time": 1606723614, // 结束使用时间
        "__nature": "i", // 属性
        "nature": "1", // 属性
        "type": 1, // 类型（1无门槛，2满减，3折扣）
        "is_use": 1, // 是否能使用（1是0否)
        "is_pick": 1, // 是否能领取（1是0否)
        "remarks": "",
        "create_time": "2020-11-17 10:02:26",
        "product": [{
            "id": 120,
            "title": "测试商品"
        }]
    }
}
 */
export function getOne(id) {
  let params = {
    url: '/scmsapi/coupon/getOne',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc 优惠券列表
 * @param id 优惠券ID
 * @param type 类型（1无门槛，2满减，3折扣）
 * @param code 编码
 * @param is_use 能否使用（1是0否）
 * @param is_pick 能否领取（1是0否）
 * @param page 页码 默认1
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "total": 3,
        "per_page": 10,
        "current_page": 1,
        "last_page": 1,
        "data": [{
            "id": 8,
            "num": 1, // 总量（固定值）
            "code": "3bfHrNWD", // 编码
            "remains": 0, // 剩余数量
            "pick_start_time": 1605715200, // 开始领取时间
            "pick_end_time": 1606723614, // 结束领取时间
            "use_start_time": 1605715200, // 开始使用时间
            "use_end_time": 1606723614, // 结束使用时间
            "__nature": "i", // 属性 可能会存在字符串和数字类型
            "nature": "1", // 属性
            "type": 1, // 类型（1无门槛，2满减，3折扣）
            "is_use": 1, // 是否能使用（1是0否)
            "is_pick": 1, // 是否能领取（1是0否)
            "is_child": 1, // 是否有子码（1是0否)
            "remarks": "",
            "create_time": "2020-11-17 10:02:26",
            "product": [{
                "title": "测试商品"
            }],
            "children_amount": 1 // 优惠券已使用的子码数量
        }]
    }
}
 */
export function index(id, type,code,is_use,is_pick,page) {
  let params = {
    url: '/scmsapi/coupon/index',
    data: {
      id, type,code,is_use,is_pick,page
    }
  };
  return post(params);
}

/**
 * @desc 导出优惠券列表(本接口请采用get请求，请求参数与/scmsapi/coupon/index接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)
 * @param type 类型（1无门槛，2满减，3折扣）
 * @param code 编码
 * @param is_use 能否使用（1是0否）
 * @param is_pick 能否领取（1是0否）
 * @param export_num 导出数据条数
 * @returns {Promise<*>}
 */
export function couponExport(type,code,is_use,is_pick, export_num) {
  let params = {
    url: '/scmsapi/coupon/export',
    data: {type,code,is_use,is_pick, export_num}
  };
  return post(params);
}

/**
 * @desc 子码列表
 * @param id 主码id
 * @param code 子码编码
 * @param username 领取人手机
 * @param status 是否使用（1未使用，2已使用）
 * @param is_pick 是否领取（1未领取，2已领取）
 * @param page 页码 默认1
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "total": 1,
        "per_page": 10,
        "current_page": 1,
        "last_page": 1,
        "data": [{
            "id": 17,
            "pid": 8, // 主码id
            "code": "GRn2uUw3",
            "user_id": 10022, // 用户id
            "status": 1, // 是否使用（1未使用，2已使用）
            "pick_time": 1606119137, // 领取时间
            "use_time": 0, // 使用时间
            "user": {
                "phone": "18111619306", // 用户电话
                "nickname": "黄翠刚" // 用户昵称
            }
        }]
    }
}
 */
export function children(id,code,username,status,is_pick,page) {
  let params = {
    url: '/scmsapi/coupon/children',
    data: {
      id,code,username,status,is_pick,page
    }
  };
  return post(params);
}

/**
 * @desc 导出优惠券子码列表(本接口请采用get请求，请求参数与/scmsapi/coupon/children接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)
 * @param id 主码id
 * @param code 子码编码
 * @param username 领取人手机
 * @param status 是否使用（1未使用，2已使用）
 * @param is_pick 是否领取（1未领取，2已领取）
 * @param export_num 导出数据条数
 * @returns {Promise<*>}
 */
export function couponChildrenExport(id,code,username,status,is_pick,export_num) {
  let params = {
    url: '/scmsapi/coupon/childrenExport',
    data: {id,code,username,status,is_pick,export_num}
  };
  return post(params);
}


/**
 * @desc 优惠券更新
 * @param id 优惠券id
 * @param product_ids 商品id的数组[1,2,3]
 * @param num 数量
 * @param nature 属性(无门槛为0,满减100|50,折扣0.8)
 * @param type 类型1=无门槛 2=满减 3=折扣
 * @param pick_start_time 开始领取时间
 * @param pick_end_time 结束领取时间
 * @param use_start_time 开始使用时间
 * @param use_end_time 结束使用时间
 * @param is_use 是否可用（1是，0否）
 * @param is_pick 是否可用（1是，0否）
 * @returns {Promise<*>} {"code":200,"msg":"success","data":[]}
 */
export function update(id,product_ids,num,nature,type,pick_start_time,pick_end_time,use_start_time,use_end_time,is_use,is_pick) {
  let params = {
    url: '/scmsapi/coupon/update',
    data: {
      id,product_ids,num,nature,type,pick_start_time,pick_end_time,use_start_time,use_end_time,is_use,is_pick
    }
  };
  return post(params);
}

/**
 * @desc 删除优惠券
 * @param id 优惠券id
 * @returns {Promise<*>} {"code":200,"msg":"success","data":[]}
 */
export function del(id) {
  let params = {
    url: '/scmsapi/coupon/delete',
    data: {
      id
    }
  };
  return post(params);
}
