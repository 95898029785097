import {dajxHttp} from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc  查看商品(获取商品详情)
 * @param id 商品ID
 * @param model 内容模块
 * 值可能性：
 * edit:编辑商品,查看商品的编辑信息,默认值
 * product_images_1:查看查看商品的入户图
 * product_images_2:查看商品banner图片
 * product_images_3:查看商品介绍图片
 * product_description:查看商品的商品描述
 * product_sku_and_spec:查看商品规格及商品SKU
 * product_supplier_info:查看供应商信息
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    // 商品ID
    "id": 12053,
    // 商品序号
    "sort": 1,
    // 商品名称
    "title": "测试商品",
    // 计量单位
    "unit": "个",
    // 供货商ID
    "supplier_id": 5,
    // 商品描述
    "description": "xxxxxxxxxxxxxxxxxxxxxxxx",
    // 分类ID
    "product_category_id": 1,
    // 限购数量 前端可把0展示未空
    "limit_number": 100,
    // 发货模式 1:现货发货模式 2:预售发货模式
    "shipping_mode": 1,
    // 发货天数
    "shipping_days": 3,
    // 是否支持7天无理由退款 1:支持 2:不支持
    "is_7day_return_supported": 1,
    // 是否置顶 0不置顶 1置顶
    "is_top": 1,
    // 商品图片数组，包含多个对象，每个对象字段如展开所示
    "product_images": [
      {
        // 图片类型 1=商品入户图 2=商品bannner图 3=商品介绍图
        "type": 1,
        // 图片可访问路径
        "url": "https://admin.bb.uninote.com.cn/oss?path=img/58ec5568199749c991fc848e85bd7516.jpg"
      }
    ],
    // 商品规格数组，包含多个对象，每个对象字段如展开所示
    "product_spec": [
      {
        // 商品规格名称
        "product_spec_key": "重量",
        // 商品规格种类数组
        "product_spec_value": [
          "1KG"
        ]
      }
    ],
    // 商品运费数组，包含多个对象，每个对象字段如展开所示
    "__product_freight": "i",
    "product_freight": [
      {
        // 运费金额(单位分)
        "amount": 1,
        // 地区id数组
        "area_id": [
          360
        ],
        // 地区名称(与area_id参数的数组键是一一对应的,比如area_id参数为[1,2],area_city参数为[四川,北京],此时area_id参数值1是对应的area_city参数值四川,area_id参数值2是对应的area_city参数值北京)
        "area_city": [
          "四川"
        ]
      }
    ],
    // 商品SKU数组，包含多个对象，每个对象字段如展开所示
    "product_sku": [
      {
        // 规格组合值数组，包含多个对象，每个对象字段如展开所示
        "product_spec": [
          {
            // 商品规格名称
            "product_spec_key": "重量",
            // 商品规格种类
            "product_spec_value": "1KG"
          }
        ],
        // shu价格(单位分)
        "price": 1,
        // 状态 1=正常 2=禁用
        "status": 1,
        // 库存
        "inventory_quantity": 10,
        // sku 图片
        "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
      }
    ],
    // 供应商信息
    "supplier_info": {
      // 供应商手机
      "mobile": "18111619306",
      // 供应商名称
      "nickname": "测试供应商"
     }
  }
}
 */
export function productInfo(id, model) {
  let params = {
    url: '/scmsapi/product/info',
    data: {
      "id": id,
      "model": model
    }
  };
  return post(params);
}

/**
 * @desc  创建商品
 * @param sort 商品序号
 * @param title 商品名称
 * @param unit 计量单位
 * @param supplier_id 供货商id
 * @param description 商品描述
 * @param product_images 商品图片数组
 * @param product_spec 商品规格数组
 * @param product_freight 商品运费数组
 * @param product_sku 商品SKU数组
 * @param product_category_id 商品分类ID
 * @param limit_number 限购数量
 * @param is_top 是否置顶 0不置顶 1置顶
 * @param shipping_mode 发货模式 1:现货发货模式 2:预售发货模式 可不传,默认值1
 * @param shipping_days 发货天数 可不传,默认值3
 * @param is_7day_return_supported 是否支持7天无理由退款 1:支持 2:不支持 可不传,默认值1
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "id": 12053
  }
}
 */
export function productCreate(is_top, sort, title, unit, supplier_id, description, product_images, product_spec, product_freight, product_sku, product_category_id,limit_number,
  shipping_mode, shipping_days, is_7day_return_supported) {
  let params = {
    url: '/scmsapi/product/create',
    data: {
      "sort": sort,
      "title": title,
      "unit": unit,
      "supplier_id": supplier_id,
      "description": description,
      "product_images": product_images,
      "product_spec": product_spec,
      "product_freight": product_freight,
      "product_sku": product_sku,
      "product_category_id": product_category_id,
      "limit_number": limit_number,
      "is_top": is_top,
      shipping_mode,
      shipping_days,
      is_7day_return_supported,
    }
  };
  return post(params);
}

/**
 * @desc  编辑商品
 * @param id 商品序号
 * @param sort 商品序号
 * @param title 商品名称
 * @param unit 计量单位
 * @param supplier_id 供货商id
 * @param description 商品描述
 * @param product_images 商品图片数组
 * @param product_spec 商品规格数组
 * @param product_freight 商品运费数组
 * @param product_sku 商品SKU数组
 * @param product_category_id 商品分类ID
 * @param limit_number 限购数量
 * @param shipping_mode 发货模式 1:现货发货模式 2:预售发货模式 可不传,默认值1
 * @param shipping_days 发货天数 可不传,默认值3
 * @param is_7day_return_supported 是否支持7天无理由退款 1:支持 2:不支持 可不传,默认值1
 * @param is_top 是否置顶 0不置顶 1置顶
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": null
}
 */
export function productUpdate(is_top, id, sort, title, unit, supplier_id, description, product_images, product_spec, product_freight, product_sku, product_category_id,limit_number,
  shipping_mode, shipping_days, is_7day_return_supported) {
  let params = {
    url: '/scmsapi/product/update',
    data:  {
      "id": id,
      "sort": sort,
      "title": title,
      "unit": unit,
      "supplier_id": supplier_id,
      "description": description,
      "product_images": product_images,
      "product_spec": product_spec,
      "product_freight": product_freight,
      "product_sku": product_sku,
      "product_category_id": product_category_id,
      "limit_number": limit_number,
      "is_top": is_top,
      shipping_mode,
      shipping_days,
      is_7day_return_supported,
    }
  };
  return post(params);
}

/**
 * @desc  获取商品列表
 * @param id 商品id
 * @param start_time 开始时间
 * @param end_time 结束时间
 * @param supplier_name 供应商姓名
 * @param title 商品名称
 * @param status 商品状态 默认0=全部 1=上架 2=下架
 * @param page 分页页码 默认1
 * @param page_size 分页每页数据条数 默认10
 * @param product_category_id 分类ID
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 48,
    "per_page": 15,
    "current_page": 1,
    "last_page": 4,
    "__data": "i",
    "data": [
      {
        // 商品ID
        "id": 12046,
        // 商品序号
        "sort": 1,
        // 商品名称
        "title": "猪头肉",
        // 计量单位
        "unit": "个",
        // 商品描述
        "description": "xxxxxxxxxxxxxxxxxxxxxxxx",
        // 状态 1=上架 2=下架
        "status": 1,
        "create_time": "2020-12-02 23:04:01",
        // 商品SKU最低价格
        "min_price": 1,
        // 商品SKU最高价格
        "max_price": 1,
        // 发货模式 1:现货发货模式 2:预售发货模式
        "shipping_mode": 1,
        // 发货天数
        "shipping_days": 3,
        // 商品类型信息
        "productCategory": {
          // 商品类型名称
          "name": "测试类型",
          // 商品类型图片
          "product_category_image": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2021/10/e1e5b3f2d5f008e0902b5fcd85a1888a.png"
        },
      }
    ]
  }
}
 */
export function productList(id, start_time, end_time, supplier_name, title, status, page, page_size, product_category_id) {
  let params = {
    url: '/scmsapi/product/list',
    data: {
      "id": id,
      "start_time": start_time,
      "end_time": end_time,
      "supplier_name": supplier_name,
      "title": title,
      "status": status,
      "page": page,
      "page_size": page_size,
      "product_category_id": product_category_id,
    }
  };
  return post(params);
}

/**
 * @desc 导出商品列表(本接口请采用get请求，请求参数与/scmsapi/product/list接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)
 * @param start_time 开始时间
 * @param end_time 结束时间
 * @param supplier_name 供应商姓名
 * @param title 商品名称
 * @param status 商品状态 默认0=全部 1=上架 2=下架
 * @param product_category_id 分类ID
 * @param export_num 导出数据条数
 * @returns {Promise<*>}
 */
export function productExport(start_time, end_time, supplier_name, title, status, product_category_id,export_num) {
  let params = {
    url: '/scmsapi/product/export',
    data: {start_time, end_time, supplier_name, title, status, product_category_id,export_num}
  };
  return post(params);
}

/**
 * @desc  更新商品状态(上架|下架|删除)
 * @param id 商品ID
 * @param status 状态 状态 0=删除 1=上架 2=下架
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": null
}
 */
export function productUpdateStatus(id, status) {
  let params = {
    url: '/scmsapi/product/updateStatus',
    data: {
      id,
      status,
    }
  };
  return post(params);
}

/**
 * @desc  设置商品排序
 * @param id 商品ID
 * @param pre_product_id 前面的商品ID(例如设置当前商品ID:1在商品ID:2的后面,本子段传2,如果设置当前商品为最前面的第一个,本子段传0,因为前面没有商品了)
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": null
}
 */
export function productSetProductSort(id, pre_product_id) {
  let params = {
    url: '/scmsapi/product/setProductSort',
    data: {
      "id": id,
      "pre_product_id": pre_product_id
    }
  };
  return post(params);
}

/**
 * @desc  获取商品推荐列表
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "__data": "k",
  "data": [
    {
      // 商品ID
      "id": 115,
      // 商品名称
      "title": "测试商品",
      "productRecommend": {
        // 商品推荐排序(升序)
        "sort": 1
      }
    }
  ]
}
 */
export function productProductRecommendList() {
  let params = {
    url: '/scmsapi/product/productRecommendList',
    data: {}
  };
  return post(params);
}

/**
 * @desc  设置商品推荐
 * @param product_ids 商品ID数组(传空数组,表示删除所有推荐设置)
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": null
}
 */
export function productProductRecommend(product_ids) {
  let params = {
    url: '/scmsapi/product/productRecommend',
    data: {
      "product_ids":product_ids
    }
  };
  return post(params);
}
