/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../../base';

export const infos = {
  'check': {
    '__proto__': base,
    'name': 'check',
    'desc': "数据 checks\n",
    'url': '/api/blindbox/test/check',
    'args': {
      filters: {
        'pos': 0,
        'desc': "支持的过滤项：'bag', 'user', 'sql'; 如果配置了，则只检查指定的项",
        'demo': '',
      },

    },
    'returns': "\n",
  },
  'fix': {
    '__proto__': base,
    'name': 'fix',
    'desc': "系统崩溃后的数据修复\n",
    'url': '/api/blindbox/test/fix',
    'args': {

    },
    'returns': "\n",
  }
};
