import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/blindbox/order_box';
import {infos as index} from '../../../generated/client/index';
import * as apis from '../../../../api/client/blindbox/order_box';
import * as api_client from "@/powder/user/client/index";
import * as order from './order';
import {infos as cmsOrderBox} from '@/powder/user/scms/blindbox/order_box';

// 获取一个可兑换的背包数据ID
async function getOneIDByConch() {
  let data = (await infos.list.make('待处理状态')).data.data;
  let id = "";
  if (data.info.length > 0) {
    let key;
    for(key in data.info) {
      if (data.info[key].conch_value > 0) {
        id = data.info[key].id
        break;
      }
    }
  }
  return id;
}

// 获取一个可发货的背包数据ID
async function getOneIDByDispatch() {
  let data = (await infos.list.make('待处理状态')).data.data;
  let id = "";
  if (data.info.length > 0) {
    id = data.info[0].id
  }
  return id;
}

// 获取用户默认收货地址
async function getAddressId() {
  let data = (await api_client.infos.getDefaultAddress.make()).data.data;
  let address_id
  if(!data.info.id){
    // 创建默认收货地址
    address_id = (await api_client.infos.addAddress.make()).data.data.info;
  } else {
    address_id = data.info.id;
  }
  return address_id
}

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "背包列表\n",
    'url': '/api/blindbox/order/order-box-list',
    'defaults': {
      page: 1,
      pageSize: 10,
      status: 1,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': '待处理状态',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            status: 1
          };
        }
      },
      {
        'scene': '已发货状态',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            status: 3
          };
        }
      },
    ],
  },

  'conch': {
    '__proto__': base.conch,
    'api': apis.conch,
    'desc': "兑换贝壳\n",
    'url': '/api/blindbox/order/conch',
    'defaults': {
      id: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await order.infos.pay.make('pay-all');
          ctx.generated = {
            id: [await getOneIDByConch()],
          };
        }
      },
    ],
  },

  'dispatch': {
    '__proto__': base.dispatch,
    'api': apis.dispatch,
    'desc': "发货\n",
    'url': '/api/blindbox/order/dispatch',
    'defaults': {
      id: [],
      address_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await order.infos.pay.make('pay-all');
          ctx.generated = {
            id: [await getOneIDByDispatch()],
            address_id: await getAddressId()
          };
        }
      },
    ],
  },

  'confirmReceipt': {
    '__proto__': base.confirmReceipt,
    'api': apis.confirmReceipt,
    'desc': "确认收货\n",
    'url': '/api/blindbox/order/confirm-receipt',
    'defaults': {
      dispatchIDs: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await cmsOrderBox.doDispatch.make('geneFirst');
          const ids = infos.dispatch.lastResult.data.data.info;
          ctx.generated = {
            dispatchIDs : ids
          };
        }
      },
    ],
  },

  'conchLog': {
    '__proto__': base.conchLog,
    'api': apis.conchLog,
    'desc': "贝壳明细\n",
    'url': '/api/blindbox/order/conch-log',
    'defaults': {
      page: 1,
      pageSize: 10,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': 'create',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await infos.conch.make();
          ctx.generated = {};
        }
      },
    ],
  }
};
