import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/promotion_category';
import * as apis from '../../../api/scms/promotion_category';

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': " 创建活动分类",
    'url': '/scmsapi/promotion_category/create',
    'defaults': {
      name: '',
      subtitle: '',
      sort: '',
      status: '',
      promotion_category_image: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': " 编辑促销活动分类",
    'url': '/scmsapi/promotion_category/update',
    'defaults': {
      id: '',
      name: '',
      subtitle: '',
      sort: '',
      status: '',
      promotion_category_image: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': " 删除促销活动分类",
    'url': '/scmsapi/promotion_category/del',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': " 获取促销活动分类列表",
    'url': '/scmsapi/promotion_category/list',
    'defaults': {
      id: '',
      name: '',
      page: '',
      page_size: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
