import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../consts';
import {infos as base} from '../generated/cms';
import * as apis from '../../api/cms';
import * as order from "../../tests/order";
import * as cms from "../../tests/cms";
import {getParam} from "../../utils/utils";

// 异步初始化接口
let inited = false;
export const __init = async(force = false) => {
  if (!inited) {
    inited = true;
  }
};

export const infos = {

  'login': {
    '__proto__': base.login,
    'api': apis.login,
    'desc': "登录",
    'url': '/cmsapi/admin/user/login.html',
    'defaults': {
      username: '',
      password: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'serviceList': {
    '__proto__': base.serviceList,
    'api': apis.serviceList,
    'desc': "服务列表",
    'url': '/cmsapi/admin/service/index.html',
    'defaults': {
      page: 1,
      limit: 10,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'dispatchOrder': {
    '__proto__': base.dispatchOrder,
    'api': apis.dispatchOrder,
    'desc': "派单",
    'url': '/cmsapi/admin/service/edit_order.html',
    'defaults': {
      orderId: '',
      staffId: '',
      city_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let info = await order.makeOrder(true);
          ctx.generated = {
            orderId: info.data.id,
            staffId: getParam('staff_id'),
          };
        }
      },
    ],
  },

  'createrCoupon': {
    '__proto__': base.createrCoupon,
    'api': apis.createrCoupon,
    'desc': "创建优惠券",
    'url': '/cmsapi/admin/coupon/add.html',
    'defaults': {
      type: '1',
      nature: '1',
      servicesIDs: '',
      num: 1,
      useTime: '2020-10-01 00:00:00 - 2030-01-01 00:00:00',
      useTime_start: '1601481600',
      useTime_end: '1893427200',
      pickTime: '2020-10-01 00:00:00 - 2030-01-01 00:00:00',
      pickTime_start: '1601481600',
      pickTime_end: '1893427200',
      is_use: 'on',
      is_pick: 'on',
      is_child: '',
      is_show: 'on',
      remarks: '',
      layuiTreeCheck: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let data = await cms.getServiceInfo();
          ctx.generated = {
            servicesIDs: data.id
          };
        }
      },
    ],
  },

  'editStaff': {
    '__proto__': base.editStaff,
    'api': apis.editStaff,
    'desc': "编辑员工",
    'url': '/cmsapi/admin/staff/edit.html',
    'defaults': {
      staffId: '',
      city_id: '42',
      tag_ids: '1',
      nick: 'powder申屠子夜',
      tel: '13778707887',
      sex: '1',
      hometown_name: '1',
      identity_card: '511321199701101614',
      identity_card_a: '',
      identity_card_b: '',
      health: '',
      commission_ratio: '399',
      desc: '123',
      status: 'on',
      remarks: '123',
      type: '2',
      layuiTreeCheck: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            staffId: getParam("staff_id"),
          };
        }
      },
    ],
  },

  'editService': {
    '__proto__': base.editService,
    'api': apis.editService,
    'desc': "编辑服务",
    'url': '/cmsapi/admin/service/edit.html',
    'defaults': {
      serviceId: '',
      title: '居家保洁',
      adCode: '0',
      hideAdCodeIds: '',
      spec_unit: '小时',
      spec_unit_time: '1',
      spec_low: '2.00',
      number1: '1',
      spec: '其他',
      tag_ids: '1',
      icon: '',
      tel: '4000360036',
      original_price: '160',
      present_price: '89',
      business_hours: '每天 早上8：00 -- 晚上12：00 点为您服务',
      place: '新世纪环球购物中心,104.063402,30.568744,天府大道北段1700号',
      subtitle: '专业保洁服务',
      homeTitle: '居家保洁|专业高效|不含擦窗',
      is_hour: 'on',
      sort: '102',
      status: 'on',
      abstract: '<p>家政服务</p>',
      describe: '<p style="text-align: center;"><img src="https://admin.bb.uninote.com.cn/oss?path=img/1596420068445472.png" title="1596420068445472.png" alt="清洁.png"/></p>',
      layuiTreeCheck: '',
      integral_type:'0',
      integral_num:'100',
      advantage: '<p><img src="https://admin.bb.uninote.com.cn/oss?path=img/1607927915264543.jpg" title="1607927915264543.jpg" alt="特点保洁.jpg" _src="https://admin.bb.uninote.com.cn/oss?path=img/1607927915264543.jpg"></p>',
      service_spec: '[{"spec":"1","peak_price":60,"low_peak_price":50}]',
      service_reward_specs: '[{"spec":"0","staff_high_reward":45,"staff_low_reward":37,"company_high_reward":50,"company_low_reward":45}]',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let service = await cms.getLastService();
          let serviceId = service.id;
          ctx.generated = {
            serviceId: serviceId
          };
        }
      },
    ],
  },

  'rewardAscension': {
    '__proto__': base.rewardAscension,
    'api': apis.rewardAscension,
    'desc': "佣金提升",
    'url': '/cmsapi/admin/service/rewardAscension.html',
    'defaults': {
      orderid: '',
      reward_ascension: 99900
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getServiceSubOrderSpecs': {
    '__proto__': base.getServiceSubOrderSpecs,
    'api': apis.getServiceSubOrderSpecs,
    'desc': "获取补单规格信息",
    'url': '/cmsapi/admin/service/getServiceSubOrderSpecs.html',
    'defaults': {
      serviceId: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let service = await cms.getLastService();
          let serviceId = service.id;
          await cms.addServiceSubOrderSpecs(serviceId);
          ctx.generated = {
            serviceId: serviceId
          };
        }
      },
    ],
  },

  'editServiceSubOrderSpecs': {
    '__proto__': base.editServiceSubOrderSpecs,
    'api': apis.editServiceSubOrderSpecs,
    'desc': "添加/编辑服务补单规格",
    'url': '/cmsapi/admin/service/edit_service_sub_order_spec.html',
    'defaults': {
      serviceId: '',
      spec: 1,
      peak_price: 100,
      low_peak_price: 100,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let service = await cms.getLastService();
          let serviceId = service.id;
          await cms.delAllServiceSubOrderSpecs(serviceId);
          ctx.generated = {
            serviceId: serviceId
          };
        }
      },
    ],
  },

  'delServiceSubOrderSpecs': {
    '__proto__': base.delServiceSubOrderSpecs,
    'api': apis.delServiceSubOrderSpecs,
    'desc': "删除补单规格信息",
    'url': '/cmsapi/admin/service/del_service_sub_order_spec.html',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let service = await cms.getLastService();
          let serviceId = service.id;
          let data = await cms.addServiceSubOrderSpecs(serviceId);
          ctx.generated = {
            id: data[0].id
          };
        }
      },
    ],
  }
};
