import {dajxHttp} from '../utils/request.js';

/**
 * @desc 登录
 * @param username
 * @param password
 * @returns {Promise<*>}
 {
  "status": true,
  "data": {
    "id": 1,
    "username": "root",
    "email": "dajxyl@126.com",
    "tel": "17729830529",
    "nick": "超级管理员",
    "pic": "https://admin.bb.uninote.com.cn/oss?path=img/4f836876fd1ca9ae2d1b67b66efc2ce0.jpg",
    "logintime": "2021-10-11 12:30:24",
    "is_admin": 1,
    "remarks": null,
    "user_status": "正常"
  },
  "msg": "登录成功！页面即将跳转。"
}
 */
export async function login(username, password) {
  let params = {
    url: '/cmsapi/admin/user/login.html',
    data: {
      "username": username,
      "password": password,
      "captcha": "1234"
    }
  };
  return dajxHttp.cms(params);
}

/**
 * @desc 服务列表
 * @param page
 * @param limit
 * @returns {Promise<>}
 */
export async function serviceList(page, limit) {
  let params = {
    url: '/cmsapi/admin/service/index.html',
    data: {
      'page': page,
      'limit': limit,
    }
  };
  return dajxHttp.cms(params);
}

/**
 * @desc 派单
 * @param orderId
 * @param staffId
 * @returns {Promise<>}
 {
  "status": true,
  "data": [],
  "msg": "保存成功"
}
 */
export async function dispatchOrder(orderId, staffId) {
  let params = {
    url: '/cmsapi/admin/service/edit_order.html',
    data: {
      'staff_id': staffId,
    }
  };
  params.url += '?id=' + orderId;
  return dajxHttp.cms(params);
}

/**
 * @desc 创建优惠券
 * @param type 1无门槛，2满减，3折扣
 * @param nature 1: 任意数字（单位：元），2：10|2 满10减2（元），3：0.75 七五折
 * @param servicesIDs 所属商品ID，多个ID使用英文逗号分隔
 * @param num 总数量
 * @param useTime 使用时间 例如 2021-10-01 00:00:00 - 2021-10-30 00:00:00
 * @param useTime_start 开始使用时间（时间戳）
 * @param useTime_end 结束使用时间（时间戳）
 * @param pickTime 领取时间 例如 2021-10-01 00:00:00 - 2021-10-30 00:00:00
 * @param pickTime_start 开始领取时间（时间戳）
 * @param pickTime_end 结束领取时间（时间戳）
 * @param is_use 是否使用 是：on 否：不传本字段就是否
 * @param is_pick 是否领取 是：on 否：不传本字段就是否
 * @param is_child 是否产生子码 是：on 否：不传本字段就是否
 * @param is_show 是否显示 是：on 否：不传本字段就是否
 * @param remarks 备注
 * @param layuiTreeCheck
 * @returns {Promise<>}
 {
  "status": true,
  "data": {
    "code": "64378913"
  },
  "msg": "保存成功"
}
 */
export async function createrCoupon(type, nature, servicesIDs, num, useTime, useTime_start, useTime_end,
  pickTime, pickTime_start, pickTime_end, is_use, is_pick, is_child, is_show, remarks, layuiTreeCheck) {
  let params = {
    url: '/cmsapi/admin/coupon/add.html',
    data: {
      'type': type,
      'nature': nature,
      'serviceIDs': servicesIDs,
      'num': num,
      'useTime': useTime,
      'useTime_start': useTime_start,
      'useTime_end': useTime_end,
      'pickTime': pickTime,
      'pickTime_start': pickTime_start,
      'pickTime_end': pickTime_end,
      'is_use': is_use,
      'is_pick': is_pick,
      'is_child': is_child,
      'is_show': is_show,
      'remarks': remarks,
      'layuiTreeCheck': layuiTreeCheck,
    }
  };
  return dajxHttp.cms(params);
}


/**
 * @desc 编辑员工
 * @param staffId
 * @param city_id 入驻城市ID
 * @param tag_ids 标签ID，多个ID使用英文逗号分隔
 * @param nick 昵称
 * @param tel 电话
 * @param sex 性别:0=女,1=男
 * @param hometown_name 家乡名称  例：四川
 * @param identity_card 身份证号码
 * @param identity_card_a 身份证附件（正面）
 * @param identity_card_b 身份证附件（反面）
 * @param health 健康证(附件)
 * @param commission_ratio 佣金比率 0.5代表50%
 * @param desc 信息栏描述
 * @param status 状态 0=未激活,1=激活
 * @param remarks 备注
 * @param type 账号身份  默认1:个人   2:公司
 * @param layuiTreeCheck
 * @returns {Promise<>}
 {
  "status": true,
  "data": [],
  "msg": "保存成功"
}
 */
export async function editStaff(staffId, city_id, tag_ids, nick, tel, sex,
  hometown_name, identity_card, identity_card_a, identity_card_b, health,
  commission_ratio, desc, status, remarks, type, layuiTreeCheck) {
  let params = {
    url: '/cmsapi/admin/staff/edit.html',
    data: {
      'city_id': city_id,
      'tag_ids': tag_ids,
      'nick': nick,
      'tel': tel,
      'sex': sex,
      'hometown_name': hometown_name,
      'identity_card': identity_card,
      'identity_card_a': identity_card_a,
      'identity_card_b': identity_card_b,
      'health': health,
      'commission_ratio': commission_ratio,
      'desc': desc,
      'status': status,
      'remarks': remarks,
      'type': type,
      'layuiTreeCheck': layuiTreeCheck,
    }
  };
  params.url += '?id=' + staffId;
  return dajxHttp.cms(params);
}

/**
 * @desc 编辑服务
 * @param serviceId
 * @param title 服务名称
 * @param adCode 所属城市ID，多个ID使用英文逗号分隔
 * @param hideAdCodeIds 隐藏的城市ID，多个ID使用英文逗号分隔
 * @param spec_unit 规格单位
 * @param spec_unit_time 规格换算成时长(单位分钟)
 * @param spec_low 服务最低规格
 * @param number1 本字段在接口上，没有任何作用
 * @param spec 规格
 * @param tag_ids 标签ID，多个ID使用英文逗号分隔
 * @param icon 促销配图
 * @param tel 电话
 * @param original_price 原价（单位元）
 * @param present_price 现价（单位元）
 * @param business_hours 营业时间（任意字符串）
 * @param place 地点 例如：四川省成都市高新区环球中心,117.1412,36.234234,距离地铁一号线锦城广场站400m
 * @param subtitle 副标题
 * @param homeTitle 服务标签描述(多个使用|分隔符)
 * @param is_hour 是否显示小时收费 是：on 否：不传本字段就是否
 * @param sort 排序
 * @param status 状态 是：on 否：不传本字段就是否
 * @param abstract 描述2
 * @param describe 描述1
 * @param layuiTreeCheck
 * @param integral_type
 * @param integral_num
 * @param advantage 平台优势
 * @param service_spec 项目规格
 * @param service_reward_specs 返佣规格
 * @returns {Promise<>}
 {
  "status": true,
  "data": [],
  "msg": "保存成功"
}
 */
export async function editService(serviceId, title, adCode, hideAdCodeIds, spec_unit, spec_unit_time,
  spec_low, number1, spec, tag_ids, icon, tel, original_price, present_price, business_hours, place,
  subtitle, homeTitle, is_hour, sort, status, abstract, describe, layuiTreeCheck, integral_type,
  integral_num, advantage, service_spec, service_reward_specs) {
  let params = {
    url: '/cmsapi/admin/service/edit.html',
    data: {
      title, adCode, hideAdCodeIds, spec_unit, spec_unit_time, spec_low, number1, spec,
      tag_ids, icon, tel, original_price, present_price, business_hours, place, subtitle,
      homeTitle, is_hour, sort, status, abstract, describe, layuiTreeCheck, integral_type,
      integral_num, advantage, service_spec, service_reward_specs
    }
  };
  params.url += '?id=' + serviceId;
  return dajxHttp.cms(params);
}

/**
 * @desc 佣金提升
 * @param orderid
 * @param reward_ascension
 * @returns {Promise<>}
 {
  "status": true,
  "data": [],
  "msg": "操作成功！"
}
 */
export async function rewardAscension(orderid, reward_ascension) {
  let params = {
    url: '/cmsapi/admin/service/rewardAscension.html',
    data: {
      'id' : orderid,
      'reward_ascension' : reward_ascension,
    }
  };
  return dajxHttp.cms(params);
}

/**
 * @desc 获取补单规格信息
 * @param serviceId
 * @returns {Promise<>}
{
  "status": 1,
  "data": [
    {
      "id": 114,
      "service_id": 1,
      // 规格
      "spec": "1",
      // 高峰价（单位分）
      "peak_price": 100,
      // 低峰价（单位分）
      "low_peak_price": 100
    }
  ],
  "msg": ""
}
 */
export async function getServiceSubOrderSpecs(serviceId) {
  let params = {
    url: '/cmsapi/admin/service/getServiceSubOrderSpecs.html',
    data: {
      'id' : serviceId
    }
  };
  return dajxHttp.cms(params);
}

/**
 * @desc 添加/编辑服务补单规格
 * @param serviceId 服务id
 * @param spec 规格
 * @param low_peak_price 低峰价（单位分）
 * @param peak_price 高峰价（单位分）
 * @returns {Promise<>}
 {
  "status": 1,
  "data": "",
  "msg": "修改成功！"
}
 */
export async function editServiceSubOrderSpecs(serviceId, spec, peak_price, low_peak_price) {
let params = {
  url: '/cmsapi/admin/service/edit_service_sub_order_spec.html',
  data: {
    'spec': spec,
    'peak_price': peak_price,
    'low_peak_price': low_peak_price,
    'service_id': serviceId,
  }
};
  return dajxHttp.cms(params);
}

/**
 * @desc 删除补单规格信息
 * @param id 补单规格ID
 * @returns {Promise<>}
 {
  "status": 1,
  "data": "",
  "msg": "删除成功！"
}
 */
export async function delServiceSubOrderSpecs(id) {
  let params = {
    url: '/cmsapi/admin/service/del_service_sub_order_spec.html',
    data: {
      'id' : id
    }
  };
  return dajxHttp.cms(params);
}

//优惠券类型：1-无门槛；2-满减；3-折扣
const coupon_type_noThreshold = '1';
const coupon_type_fullReduction = '2';
const coupon_type_discount = '3';

export {
  coupon_type_noThreshold,coupon_type_fullReduction,coupon_type_discount
}
