import {infos as product_category_scms} from '../powder/user/scms/product_category';
/**
 * 获取商品分类ID，没有则创建一个
 */
export const getCategoryOneID = async function () {
    let data = (await product_category_scms.list.make()).data.data;
    if (!data.data.length) {
        return (await product_category_scms.create.make()).data.data.id;
    }
    return data.data[0].id;
};