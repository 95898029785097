import { dajxHttp } from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc 获取 config 列表
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": [
    {
      "id": 1,
      "key": "service_timeout",
      "value": "1",
      "comment": "comment content"
    }
  ]
}
 */
export function list() {
  let params = {
    url: "/scmsapi/config/list",
    data: {}
  };
  return post(params);
}

/**
 * @desc 更新 config 项
 * @param id
 * @param value
 * @param comment
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": 1 // db返回的更新的数据行数
}
 */
export function update(id, value, comment) {
  let params = {
    url: "/scmsapi/config/update",
    data: { id, value, comment }
  };
  return post(params);
}
