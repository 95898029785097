/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'getUserConchLog': {
    '__proto__': base,
    'name': 'getUserConchLog',
    'desc': "获取用户贝壳明细列表",
    'url': '/scmsapi/user/getUserConchLog',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码(默认1)",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "分页每页数据条数(默认10)",
        'demo': '',
      },
      uid: {
        'pos': 2,
        'desc': "用户ID",
        'demo': '',
      },
      type: {
        'pos': 3,
        'desc': "类型(枚举值 1:兑换 2:消费 3:管理员增加 4:管理员减少 可不传,为空时表示全部)",
        'demo': '',
      },
      create_time_start: {
        'pos': 4,
        'desc': "创建时间-开始时间(时间戳)",
        'demo': '',
      },
      create_time_end: {
        'pos': 5,
        'desc': "创建时间-结束时间(时间戳)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"total\": 1,\n   \"per_page\": 10,\n   \"current_page\": 1,\n   \"last_page\": 1,\n   \"__data\": \"k\",\n   \"data\": [\n     {\n       \"id\": 1,\n       \"uid\": 10000,\n       \"type\": 1, // 类型(枚举值 1:兑换(增加) 2:消费(减少) 3:管理员(增加) 4:管理员(减少) 可不传,为空时表示全部)\n       \"transaction_amount\": 90, // 交易额(贝壳)\n       \"conch\": 3690, // 用户可用贝壳(user表conch字段计算了本次交易额后的记录值)\n       \"create_time\": \"2023-04-19 16:22:34\" // 创建时间\n     }\n   ]\n }\n}\n",
  },
  'exportUserConchLogList': {
    '__proto__': base,
    'name': 'exportUserConchLogList',
    'desc': "导出用户贝壳明细列表(本接口请采用get请求，请求参数与/scmsapi/user/getUserConchLog接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserConchLogList',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码(默认1)",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "分页每页数据条数(默认10)",
        'demo': '',
      },
      uid: {
        'pos': 2,
        'desc': "用户ID",
        'demo': '',
      },
      type: {
        'pos': 3,
        'desc': "类型(枚举值 1:兑换 2:消费 3:管理员增加 4:管理员减少 可不传,为空时表示全部)",
        'demo': '',
      },
      create_time_start: {
        'pos': 4,
        'desc': "创建时间-开始时间(时间戳)",
        'demo': '',
      },
      create_time_end: {
        'pos': 5,
        'desc': "创建时间-结束时间(时间戳)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n",
  },
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': "获取用户列表",
    'url': '/scmsapi/user/list',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码(默认1)",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "分页每页数据条数(默认10)",
        'demo': '',
      },
      keyword: {
        'pos': 2,
        'desc': "信息搜索",
        'demo': '',
      },
      uid: {
        'pos': 3,
        'desc': "用户ID",
        'demo': '',
      },
      name: {
        'pos': 4,
        'desc': "用户昵称",
        'demo': '',
      },
      phone: {
        'pos': 5,
        'desc': "电话号码",
        'demo': '',
      },
      startTime: {
        'pos': 6,
        'desc': "创建时间-开始时间(时间戳)",
        'demo': '',
      },
      endTime: {
        'pos': 7,
        'desc': "创建时间-结束时间(时间戳)",
        'demo': '',
      },
      sex: {
        'pos': 8,
        'desc': "性别(0女，1男)",
        'demo': '',
      },
      status: {
        'pos': 9,
        'desc': "状态 0正常 1禁用",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"total\": 18,\n   \"per_page\": 15,\n   \"current_page\": 1,\n   \"last_page\": 2,\n   \"data\": [\n     {\n       \"uid\": 10019, // 用户ID\n       \"phone\": \"17345155551\", // 电话\n       \"nickname\": \"\", // 昵称\n       \"remark_name\": \"\", // 备注名\n       \"pic\": \"https://admin.bb.uninote.com.cn/oss?path=img/default_pic.png\", // 头像\n       \"__birthday\": \"t\", // type null key ignore\n       \"birthday\": null, // 生日(年月日)\n       \"__sex\": \"t\", // type null key ignore\n       \"sex\": null, // 性别(0女，1男)\n       \"time\": 1681276626, // 创建时间(时间戳)\n       \"last_login_time\": 1681276626, // 最近登录时间(时间戳)\n       \"status\": 0, // 状态(0正常 1禁用)\n       \"ip\": \"172.17.0.1\", // 最近登录IP\n       \"integral\": 0, // 积分余額\n       \"integral_accumulated\": 0, // 积分总额\n       \"conch\": 0, // 贝壳数\n       \"city\": \"局域网 \", // 最近登录地区\n       \"order_count\": 0 // 下单总订单次数\n     }\n   ]\n }\n}\n",
  },
  'edit': {
    '__proto__': base,
    'name': 'edit',
    'desc': "编辑用户信息",
    'url': '/scmsapi/user/edit',
    'args': {
      uid: {
        'pos': 0,
        'desc': "用户ID",
        'demo': '',
      },
      nickname: {
        'pos': 1,
        'desc': "用户昵称",
        'demo': '',
      },
      remark_name: {
        'pos': 2,
        'desc': "备注名",
        'demo': '',
      },
      sex: {
        'pos': 3,
        'desc': "用户性别 0女，1男",
        'demo': '',
      },
      birthday: {
        'pos': 4,
        'desc': "用户出生年月日(时间戳)",
        'demo': '',
      },
      phone: {
        'pos': 5,
        'desc': "电话号码",
        'demo': '',
      },
      status: {
        'pos': 6,
        'desc': "状态 0正常 1禁用",
        'demo': '',
      },
      time: {
        'pos': 7,
        'desc': "创建时间(时间戳)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'info': {
    '__proto__': base,
    'name': 'info',
    'desc': "获取用户信息",
    'url': '/scmsapi/user/info',
    'args': {
      uid: {
        'pos': 0,
        'desc': "用户ID",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"uid\": 10019, // 用户ID\n   \"phone\": \"17345155551\", // 电话\n   \"nickname\": \"\", // 昵称\n   \"remark_name\": \"\", // 备注名\n   \"__birthday\": \"t\", // type null key ignore\n   \"birthday\": null, // 生日(年月日)\n   \"__sex\": \"t\", // type null key ignore\n   \"sex\": null, // 性别(0女，1男)\n   \"time\": 1681276626, // 创建时间(时间戳)\n   \"status\": 0, // 状态(0正常 1禁用)\n   \"pic\": \"https://admin.bb.uninote.com.cn/oss?path=img/default_pic.png\",\n   \"last_login_time\": 1691161479,\n   \"ip\": \"127.0.0.1\",\n   \"integral\": 0,\n   \"integral_accumulated\": 0,\n   \"conch\": 163\n }\n}\n",
  },
  'getUserIntegralLog': {
    '__proto__': base,
    'name': 'getUserIntegralLog',
    'desc': "获取用户积分明细列表",
    'url': '/scmsapi/user/getUserIntegralLog',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码(默认1)",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "分页每页数据条数(默认10)",
        'demo': '',
      },
      uid: {
        'pos': 2,
        'desc': "用户ID",
        'demo': '',
      },
      type: {
        'pos': 3,
        'desc': "类型(枚举值 1:购买-常规福袋 2:购买-超级福袋 3:购买-小卖部 4:消费)",
        'demo': '',
      },
      order_no: {
        'pos': 4,
        'desc': "订单编号(模糊查询)",
        'demo': '',
      },
      create_time_start: {
        'pos': 5,
        'desc': "创建时间-开始时间(时间戳)",
        'demo': '',
      },
      create_time_end: {
        'pos': 6,
        'desc': "创建时间-结束时间(时间戳)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"total\": 2,\n   \"per_page\": 15,\n   \"current_page\": 1,\n   \"last_page\": 1,\n   \"__data\": \"k\",\n   \"data\": [\n     {\n       \"id\": 2,\n       \"uid\": 10015, // 用户ID\n       \"type\": 2, // 类型(枚举值 1:购买-福袋 3:购买-小卖部 4:消费 5:小卖部退款)\n       \"type_text\": \"购买-福袋\",\n       \"order_no\": \"xx002\", // 订单编号\n       \"transaction_amount\": 111, // 交易额(积分)\n       \"integral\": 11, // 用户可用积分\n       \"integral_accumulated\": 111, // 用户累计积分\n       \"create_time\": \"2023-03-28 12:37:06\" // 创建时间\n     }\n   ]\n }\n}\n",
  },
  'del': {
    '__proto__': base,
    'name': 'del',
    'desc': "删除用户",
    'url': '/scmsapi/user/del',
    'args': {
      uid: {
        'pos': 0,
        'desc': "用户ID",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'exportUserList': {
    '__proto__': base,
    'name': 'exportUserList',
    'desc': "导出用户列表(本接口请采用get请求，请求参数与/scmsapi/user/list接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserList',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码(默认1)",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "分页每页数据条数(默认10)",
        'demo': '',
      },
      keyword: {
        'pos': 2,
        'desc': "信息搜索",
        'demo': '',
      },
      uid: {
        'pos': 3,
        'desc': "用户ID",
        'demo': '',
      },
      name: {
        'pos': 4,
        'desc': "用户昵称",
        'demo': '',
      },
      phone: {
        'pos': 5,
        'desc': "电话号码",
        'demo': '',
      },
      startTime: {
        'pos': 6,
        'desc': "创建时间-开始时间(时间戳)",
        'demo': '',
      },
      endTime: {
        'pos': 7,
        'desc': "创建时间-结束时间(时间戳)",
        'demo': '',
      },
      sex: {
        'pos': 8,
        'desc': "性别(0女，1男)",
        'demo': '',
      },
      status: {
        'pos': 9,
        'desc': "状态 0正常 1禁用",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n",
  },
  'exportUserIntegralLogList': {
    '__proto__': base,
    'name': 'exportUserIntegralLogList',
    'desc': "导出用户积分明细列表(本接口请采用get请求，请求参数与/scmsapi//user/getUserIntegralLog接口的请求参数一致)",
    'url': '/scmsapi/user/exportUserIntegralLogList',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码(默认1)",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "分页每页数据条数(默认10)",
        'demo': '',
      },
      uid: {
        'pos': 2,
        'desc': "用户ID",
        'demo': '',
      },
      type: {
        'pos': 3,
        'desc': "类型(枚举值 1:购买-常规福袋 2:购买-超级福袋 3:购买-小卖部 4:消费)",
        'demo': '',
      },
      order_no: {
        'pos': 4,
        'desc': "订单编号(模糊查询)",
        'demo': '',
      },
      create_time_start: {
        'pos': 5,
        'desc': "创建时间-开始时间(时间戳)",
        'demo': '',
      },
      create_time_end: {
        'pos': 6,
        'desc': "创建时间-结束时间(时间戳)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n",
  },
  'changeConch': {
    '__proto__': base,
    'name': 'changeConch',
    'desc': "管理员修改用户贝壳值\n",
    'url': '/scmsapi/user/changeConch',
    'args': {
      uid: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      delta: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      comment: {
        'pos': 2,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": 139\n}\n",
  }
};
