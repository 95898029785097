/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../../base';

export const infos = {
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': " [商城模块]商品分类列表",
    'url': '/api/product-category/list',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码 默认1",
        'demo': '',
      },
      pageSize: {
        'pos': 1,
        'desc': "分页显示数据条数 默认10",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"totalPage\": 1,\n   \"currentPage\": 1,\n   \"info\": [\n     {\n       \"name\": \"自动生成的分类名称餂\",\n       \"id\": \"11\",\n       // 商品分类图\n       \"product_category_image\": \"\"\n     }\n   ]\n }\n}\n",
  }
};
