/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../../base';

export const infos = {
  'shopCouponList': {
    '__proto__': base,
    'name': 'shopCouponList',
    'desc': " [商城模块]我的优惠券",
    'url': '/api/shop-coupon/list',
    'args': {
      type: {
        'pos': 0,
        'desc': "类型 默认1:未使用 2:已使用 3:已过期",
        'demo': '',
      },
      pageSize: {
        'pos': 1,
        'desc': "分页显示数据条数 默认10",
        'demo': '',
      },
      page: {
        'pos': 2,
        'desc': "分页页码 默认1",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 1,\n   \"message\": \"success!\",\n   \"data\": {\n       \"totalPage\": 1,\n       \"currentPage\": 1,\n       \"info\": [{\n           // 优惠券ID\n           \"id\": \"8\",\n           //开始使用时间\n           \"use_start_time\": \"1605715200\",\n           //结束使用时间\n           \"use_end_time\": \"1606723614\",\n           //类型 1:无门槛 2:满减 3:折扣\n           \"type\": \"1\",\n           //备注\n           \"remarks\": \"\",\n           //优惠券金额(折扣券为折扣,例0.85表示8.5折)\n           \"coupon_amount\": \"1\",\n           //优惠券使用限制金额(无门槛或折扣券始终为0,满减券为满减条件)\n           \"coupon_limit_amount\": 0,\n           //优惠券子码ID数组(多个)\n           \"coupon_children_ids\": [\"17\"],\n           //优惠券关联的商品ID数组(多个)\n           \"__coupon_product_ids\": \"i\",\n           \"coupon_product_ids\": [\"120\"]\n       }]\n   }\n}\n",
  },
  'shopCouponPick': {
    '__proto__': base,
    'name': 'shopCouponPick',
    'desc': " [商城模块]领取优惠券",
    'url': '/api/shop-coupon/pick',
    'args': {
      id: {
        'pos': 0,
        'desc': "优惠券ID(商品列表领取优惠券可传本参数,通过优惠券ID领取优惠券)(id和code参数请根据业务场景使用其中任意一个参数,如果2个参数同时存在,id参数的优先级高于code,code参数无效,会被接口忽略)",
        'demo': '',
      },
      code: {
        'pos': 1,
        'desc': "优惠券子码(优惠券子码兑换优惠券,可传本参数,通过优惠券子码领取优惠券)",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": {\n     \"coupon_children_id\": 33631\n   }\n }\n}\n",
  }
};
