/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../../base';

export const infos = {
  'updateBagStatus': {
    '__proto__': base,
    'name': 'updateBagStatus',
    'desc': "更新福袋状态\n",
    'url': '/api/blindbox/job/update-bag-status',
    'args': {

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"__info\": \"k\",\n   \"info\": [\n     111\n   ]\n }\n}\n",
  }
};
