import { dajxHttp } from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc 福袋列表
 *
 * @param type 福袋类型：1 常规福袋，2 超级福袋的一级福袋，3 超级福袋的二级福袋
 * @param status 状态：1 新建，2 售卖中，3 完成，5 终止
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "__info": "k",
    "info": [
      {
        "id": "186",
        "type": "1",
        "name": "福袋",
        "price": "100",
        "total": "3", // 盲盒+二级福袋资格总个数（位置总数）
        "leftover": "3", // 剩余位置个数（未支付的不扣减这里）
        "start_time": "1680191681",
        "end_time": "1680195281",
        "sale_type": "1",
        "desc": "福袋描述",
        "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
        "status": "1",
        "hidden": "0",
        "allow_conch": "1",
      }
    ]
  }
}
 */
export function list(page, page_size, type, status) {
  let params = {
    url: "/api/blindbox/bag/list",
    data: {
      page,
      page_size,
      type,
      status
    }
  };
  return post(params);
}

/**
 * @desc 常规福袋详情
 * @param id 福袋id
 *
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "id": "66",
      "type": "1",
      "name": "福袋",
      "price": "100",
      "total": "3", // 盲盒+二级福袋资格总个数（位置总数）
      "leftover": "3", // 剩余位置个数（未支付的不扣减这里）
      "start_time": "1680148389",
      "end_time": "1680151989",
      "sale_type": "1",
      "desc": "福袋描述",
      "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
      "status": "1",
      "hidden": "0",
      "allow_conch": "1",
      "last_count": "1",
      "limit_count": "1",
      "__self_count": "k",
      "self_count": "1", // 自己在当前福袋已经下单（购买）的数量
      "__chooseEndTime": "k",
      "chooseEndTime": 1705015141, // 超级福袋售卖完成后，二级福袋的选择倒计时
      "boxs": [
        {
          "id": "102",
          "bag_id": "66",
          "name": "血色玫瑰",
          "price": "120",
          "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
          "total": "2",
          "leftover": "2",
          "conch_value": "90",
          "condition": "1"
        }
      ],
      "__orders": "k",
      "orders": [
        {
          "id": "32",
          "user_id": "11689",
          "bag_id": "66",
          "__sub_bag_id": "n",
          "sub_bag_id": "1", // 此订单命中的二级福袋 id
          "pay_status": "0",
          "position": "0",
          "round": "0",
          "wx_no": "",
          "__once_more_id": "n",
          "once_more_id": "1", // 此订单使用的 once more id
          "create_time": "1692009406",
          "update_time": "1692009406",
          "amount": "2",
          "actual_amount": "2",
          "user": {
            "uid": "11689",
            "name": "cyb",
            "pic": "https://admin.bb.uninote.com.cn/oss?path=img/default_pic.png"
          },
          "__box": "nk",
          "box": {
            "id": "51",
            "name": "血色玫瑰",
            "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
            "price": "120"
          },
          "__sumBox": "nk",
          "sumBox": {
            "id": "67",
            "name": "彩色糕点10",
            "img": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/a7e8dda79adf00b71b1e74dfd5a8b0e2.jpeg",
            "price": "100",
            "once_more": "1",
            "condition": "1",
            "conditionText": "全新"
          },
          "__onceMore": "n",
          "onceMore": { // 此订单生成的 once more 数据（如果命中了）
            "id": "77",
            "status": "1",
            "order_id": "452"
          }
        }
      ],
      "__ranks": "k",
      "ranks": [
        {
          "id": "48",
          "bag_id": "66",
          "rank": "2",
          "name": "血色茉莉",
          "price": "100",
          "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
          "conch_value": "0",
          "condition": "1"
        }
      ],
      "__sums": "k",
      "sums": [
        {
          "id": "392",
          "bag_id": "11",
          "sum": "0",
          "name": "彩色糕点0",
          "price": "100",
          "img": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/a7e8dda79adf00b71b1e74dfd5a8b0e2.jpeg",
          "once_more": "0",
          "conch_value": "0",
          "condition": "1",
          "conditionText": "全新"
        }
      ],
      "__totalRankList": "k",
      "totalRankList": [
        {
          "id": "28",
          "user_id": "11689",
          "bag_id": "26",
          "pay_status": "1",
          "position": "2",
          "wx_no": "27263048",
          "update_time": "1681606838",
          "user": {
            "uid": "11689",
            "name": "cyb",
            "pic": "https://admin.bb.uninote.com.cn/oss?path=img/default_pic.png"
          },
          "__box": "n",
          "box": {
            "id": "51",
            "name": "血色玫瑰",
            "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
            "price": "120"
          }
        }
      ],
      "total_round": "10", // 总共的轮次
      "cur_round": 0, // 当前的轮次
      "count_per_round": 2, // 每轮多少个位置
      "__sub_bags": "k",
      "sub_bags": [ // 二级福袋数组
        {
          "id": "32",
          "pid": "1",
          "name": "二级福袋A",
          "total": "10",
          "leftover": "10",
          "img": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/07/5de7a8902f451e1c61984a1d5c6546fa.jpeg",
          "__my_available_count": "k",
          "my_available_count": 1, // 当前用户此二级福袋可选数量
          "boxs": [
            {
              "id": "61",
              "bag_id": "32",
              "name": "21",
              "price": "120",
              "img": "https://admin.bb.uninote.com.cn/oss?path=bb_ty/2023-12/dc9be7cc79136f9a886f29639c616785.jpeg",
              "total": "2",
              "leftover": "2",
              "conch_value": "90",
              "condition": "1",
              "conditionText": "全新"
            }
          ],
          "__chooses": "k",
          "chooses": [
            {
              "id": "5",
              "order_id": "18",
              "user_id": "10180",
              "box_id": "0",
              "order_box_id": "0",
              "sub_bag_id": "16",
              "position": "-1",
              "user": {
                "uid": "10180",
                "name": "一码",
                "pic": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2023/08/da274d26b7e546ac9464ff236f824f4e.jpeg"
              },
              "__box": "n",
              "box": {
                "id": "51",
                "name": "血色玫瑰",
                "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
                "price": "120"
              },
            }
          ],
        }
      ],
    }
  }
}
 */
export function luckyBagInfo(id) {
  let params = {
    url: "/api/blindbox/bag/lucky-bag-info",
    data: { id }
  };
  return post(params);
}
