import * as scms from "./scms/auth";
import * as utils from '@/utils/utils'
import {setToken} from "../../../service";
import * as client from "./client/index";
import * as apis from "../../api/cms";

let inited = false;

export default async () => {
  if (!inited) {
    inited = true;

    let info;
    try {
      // client
      info = await client.infos.telByCodeGetToken.make();
      setToken(info.data.data.token);
    } catch (e) {
      console.error(e);
    }
    try {
      // cms登录（已经登录 + 未登录 两种情况）
      info = await apis.login('root', 'root123.');
      // 登录失败、登录成功、已经登录，各种情况比较复杂，这样处理简单些：
      let content = (info.data.msg + "") + (info.data + "");
      utils.assert((content.indexOf('登录成功') !== -1) || content.indexOf('success') !== -1, 'cms 登录失败');
    } catch (e) {
      console.error(e);
    }
    try {
      // scms
      info = await scms.infos.login.make();
      utils.assert(info.data.code === 200, 'scms 登录失败');
      setToken(info.data.data.token, 'stoken');
    } catch (e) {
      console.error(e);
    }
  }
};
