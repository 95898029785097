import {dajxHttp} from "../../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc 获取盲盒发货列表
 *
 * @param status 发货状态 0:全部订单 1:待发货 2:待收货 3:已完成
 * @param name 用户昵称
 * @param phone 手机号
 * @param wx_no 微信订单号
 * @param bag_name 福袋名称
 * @param has_print 是否已经打印 0：未打印 1：已打印
 * @param start_time 开始时间(Unix 时间戳)
 * @param end_time 结束时间(Unix 时间戳)
 * @param page 页码 默认1
 * @param page_size 每页数据量 默认10
 * @returns {Promise<*>}
 {
    "code": 200,
    "msg": "success",
    "data": {
         // 数据量
        "total": 6,
        // 每页数据量
        "per_page": 10,
        // 当前分页页码
        "current_page": 1,
        // 总页数
        "last_page": 1,
        // 分页数据
        "__data": "k",
        "data": [
            {
                // 订单ID
                "id": 39,
                // 订单编号
                "order_no": "B1057226283",
                // 订单创建时间
                "create_time": "2020-11-26 10:46:58",
                // 收件人信息
                "address": {
                  "id": 1963,
                  "uid": 11757,
                  "name": "cyb",
                  "adCode": "510107",
                  "province": "四川省",
                  "city": "成都市",
                  "area": "武侯区",
                  "desc": "aaaa",
                  "tel": "13344445555",
                  "time": "2023-05-17 12:26:23",
                  "update_time": "2023-06-05 17:02:15",
                  "is_default": 1,
                  "status": 1,
                  "lng": "0",
                  "lat": "0",
                  "doorplate": "永丰路22号晓初公寓3栋3单元7楼14号"
                },
                "orderBox": [
                  {
                    "id": 9,
                    "order_id": 6,
                    "user_id": 11757,
                    "box_id": 3,
                    "bag_id": 3,
                    "type": 30,
                    "dispatch_id": 3,
                    "status": 2,
                    "create_time": "2023-06-07 22:24:46",
                    "box": {
                      "id": 3,
                      "bag_id": 2,
                      "name": "血色玫瑰",
                      "price": 120,
                      "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg",
                      "__total": "k",
                      "total": 1,
                      "__leftover": "k",
                      "leftover": 1,
                      "conch_value": 90,
                      "condition": 1,
                      "create_time": "2023-06-07 22:15:58",
                      "update_time": "2023-06-07 22:15:58",
                      "status": 1
                    },
                    "bag": {
                      "id": 123,
                      "type": 1,
                      "name": "福袋",
                      "status": 3
                    },
                    "wx_no": "999077570"
                  }
                ],
                "express_order_id": 0,
                "expressOrder": null,
                "print_count": 0,
                "user": {
                  "uid": 10000,
                  "phone": "18982283449",
                  "nickname": "cyb"
                }
            }
        ]
    }
}
 */
export function list(status,name,phone,wx_no,bag_name,has_print,start_time,end_time,page,page_size) {
    let params = {
        url: '/scmsapi/order_box/list',
        data: {
            status,name,phone,wx_no,bag_name,has_print,start_time,end_time,page,page_size
        }
    };
    return post(params)
}

/**
 * @desc 获取盲盒发货列表(合并)
 *
 * @param status 发货状态 1:待发货 2:待收货 3:已完成
 * @param name 用户昵称
 * @param phone 手机号
 * @param wx_no 微信订单号
 * @param bag_name 福袋名称
 * @param has_print 是否已经打印，无意义，仅为了保持和 list 接口相同
 * @param start_time 开始时间(Unix 时间戳)
 * @param end_time 结束时间(Unix 时间戳)
 * @param page 页码 默认1
 * @param page_size 每页数据量 默认10
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 2,
    "per_page": 10,
    "current_page": 1,
    "last_page": 1,
    "data": [
      {
        "dispatches": [
          {
            "id": 56,
            "user_id": 10000,
            "address_id": 108,
            "order_no": "B1077509893",
            "status": 2,
            "create_time": "2023-06-30 16:18:48",
            "orderBox": [
              {
                "id": 1,
                "order_id": 3,
                "user_id": 10000,
                "box_id": 10,
                "bag_id": 5,
                "type": 1,
                "dispatch_id": 56,
                "status": 3,
                "create_time": "2023-06-29 17:44:58",
                "wx_no": "992297168",
                "box": {
                  "id": 10,
                  "bag_id": 5,
                  "name": "血色茉莉",
                  "price": 100,
                  "img": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg",
                  "__total": "k",
                  "total": 1,
                  "__leftover": "k",
                  "leftover": 1,
                  "conch_value": 0,
                  "condition": 1,
                  "create_time": "2023-06-29 17:44:55",
                  "update_time": "2023-06-29 17:44:55",
                  "status": 1
                },
                "bag": {
                  "id": 5,
                  "type": 1,
                  "name": "福袋",
                  "status": 5
                }
              }
            ]
          }
        ],
        "__order_status": "t",
        "order_status": "2",
        "address": {
          "id": 108,
          "uid": 10000,
          "name": "cyb",
          "adCode": "510104",
          "province": "四川省",
          "city": "成都市",
          "area": "锦江区",
          "desc": "环球中心W6",
          "tel": "13489518366",
          "time": "2023-06-29 22:12:27",
          "update_time": "2023-06-29 22:12:27",
          "is_default": 1,
          "status": 1,
          "lng": "104.060824",
          "lat": "30.567234",
          "doorplate": "永丰路22"
        },
        "express_order_id": 64,
        "__expressOrder": "n",
        "expressOrder": {
          "id": 64,
          "order_no": "DL649e9a03efa13",
          "express_number": "DL649e9a03efa13_waybill_id",
          "delivery_id": "TEST",
          "status": 4,
          "context": "",
          "create_time": "2023-06-30 17:01:57",
          "update_time": "2023-06-30 17:01:57"
        },
        "user": {
          "uid": 10000,
          "phone": "18982283449",
          "nickname": "cyb"
        }
      }
    ]
  }
}
 */
export function combineList(status,name,phone,wx_no,bag_name,has_print,start_time,end_time,page,page_size) {
  let params = {
      url: '/scmsapi/order_box/combineList',
      data: {
          status,name,phone,wx_no,bag_name,has_print,start_time,end_time,page,page_size
      }
  };
  return post(params)
}

/**
 * @desc 增加发货单打印次数
 *
 * @param dispatchIDs 发货请求 id 数组
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": 1
}
 */
export function increasePrintCount(dispatchIDs) {
  let params = {
      url: '/scmsapi/order_box/increasePrintCount',
      data: {
          dispatchIDs,
      }
  };
  return post(params)
}

/**
 * @desc 物流生成运单
 *
 * @param dispatchIDs 发货 ID 数组
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": {
    "errIDs": [],
    "wxRes": {
      "errcode": 0,
      "errmsg": "ok",
      "order_id": "DL647ee4c36f394",
      "waybill_id": "YT2500733476437",
      "delivery_resultcode": 0,
      "delivery_resultmsg": "success",
      "waybill_data": [
        {
          "key": "bigPen",
          "value": ""
        }
      ],
      "is_correct_sender": 0,
      "is_correct_receiver": 0
    }
  }
}
 */
export function expressAddOrder(dispatchIDs, delivery_id) {
  let params = {
      url: '/scmsapi/order_box/expressAddOrder',
      data: {
          dispatchIDs,
          delivery_id,
      }
  };
  return post(params)
}

/**
 * @desc 物流手动绑定运单
 *
 * @param dispatchIDs 发货 ID 数组
 * @param express_number 手动绑定的运单号
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": {
    "errIDs": [],
    "orderID": 43,
    "wxRes": null
  }
}
 */
export function expressBindOrder(dispatchIDs, express_number) {
  let params = {
      url: '/scmsapi/order_box/expressBindOrder',
      data: {
          dispatchIDs,
          express_number,
      }
  };
  return post(params)
}

/**
 * @desc 物流取消运单
 *
 * @param orderIDs 运单 ID 数组
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": {
    "errIDs": []
  }
}
 */
export function expressCancelOrder(orderIDs) {
  let params = {
      url: '/scmsapi/order_box/expressCancelOrder',
      data: {
        orderIDs,
      }
  };
  return post(params)
}

/**
 *
 * @desc 卖家发货
 *
 * @param orderIDs 运单表 id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": {
    "success": true,
    "errOrderIDs": [],
    "errDispatchIDs": [],
    "errBoxIDs": []
  }
}
 */
export function doDispatch(orderIDs) {
  let params = {
    url: '/scmsapi/order_box/doDispatch',
    data: {
      orderIDs,
    }
  };
  return post(params)
}


/**
 *
 * @desc 查询运单轨迹
 *
 * @param orderID 运单表 id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": {
    "openid": "oNeLR5CHZNS5b4f1x2mpEbjRmr6M",
    "delivery_id": "TEST",
    "waybill_id": "DL64905a15aa312_waybill_id",
    "path_item_num": 3,
    "path_item_list": [
      {
        "action_time": 1687181847,
        "action_type": 300003,
        "action_msg": "已签收，蜂巢"
      }
    ]
  }
}
 */
export function expressOrderPath(orderID) {
  let params = {
    url: '/scmsapi/order_box/expressOrderPath',
    data: {
      orderID,
    }
  };
  return post(params)
}

/**
 * @desc 模拟更新订单状态
 *
 * @param order_id
 * @param waybill_id
 * @param action_time
 * @param action_type
 * @param action_msg
 * @returns {Promise<*>} {
    "code": 200,
    "msg": "success",
    "data": {
        "errcode": 0,
        "errmsg": "ok"
    }
}
 */
export function expressTestUpdateOrder(order_id, waybill_id, action_time, action_type, action_msg) {
  let params = {
    url: '/scmsapi/order_box/expressTestUpdateOrder',
    data: {
      order_id, waybill_id, action_time, action_type, action_msg,
    }
  };
  return post(params)
}

/**
 * @desc 绑定、解绑 物流公司
 *
 * @param is_bind bool 是否绑定
 * @param biz_id 快递公司客户编码
 * @param delivery_id 快递公司ID
 * @param password 快递公司客户密码
 * @param remark_content 备注内容（提交EMS审核需要
 * @returns
 */
export function expressAccountBind(is_bind, biz_id, delivery_id, password, remark_content) {
  let params = {
    url: '/scmsapi/order_box/expressAccountBind',
    data: {
      is_bind, biz_id, delivery_id, password, remark_content,
    }
  };
  return post(params)
}

/**
 * @desc 获取所有绑定的物流账号
 *
 * @returns
 */
export function expressAccountAll() {
  let params = {
    url: '/scmsapi/order_box/expressAccountAll',
    data: {
    }
  };
  return post(params)
}

/**
 * @desc 配置面单打印员
 *
 * @param is_bind
 * @param openid
 * @returns
 */
export function expressPrinterBind(is_bind, openid) {
  let params = {
    url: '/scmsapi/order_box/expressPrinterBind',
    data: {
      is_bind, openid,
    }
  };
  return post(params)
}

/**
 * @desc 获取所有打印员
 *
 * @returns
 */
export function expressPrinterAll() {
  let params = {
    url: '/scmsapi/order_box/expressPrinterAll',
    data: {
    }
  };
  return post(params)
}

/**
 * @desc 查询rid信息
 *
 * @param rid
 * @returns
 */
export function expressRid(rid) {
  let params = {
    url: '/scmsapi/order_box/expressRid',
    data: {
      rid,
    }
  };
  return post(params)
}

/**
 * @desc 背包盲盒列表
 *
 * @param page
 * @param page_size
 * @param id 背包盲盒id
 * @param uid 订单用户id
 * @param order_no 商户订单号（支持高级模糊查询）
 * @param wx_no 微信订单号（支持高级模糊查询）
 * @param bag_ids 福袋 id 列表，以','分隔
 * @param bag_name 福袋名（支持高级模糊查询）
 * @param bag_status 暂不支持
 * @param bag_type 暂不支持
 * @param box_name 盲盒名（支持高级模糊查询）
 * @param advanced true: 模糊查询条件完全手写, false: 添加首尾%，/%_ 匹配字面意义
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 1,
    "per_page": 10,
    "current_page": 1,
    "last_page": 1,
    "data": [
      {
        "id": 261,
        "box_id": 1274,
        "status": 1,
        "dispatch_id": 0,
        "type": 11,
        "bag_id": 113,
        "bag_name": "福袋",
        "bag_type": 4,
        "uid": 10000,
        "nickname": "555",
        "remark_name": "cyb",
        "phone": "18982283449",
        "order_id": 213,
        "order_no": "BL1042917000",
        "wx_no": "694488454",
        "create_time": 1699149795,
      }
    ]
  }
}
 */
export function orderBoxList(page, page_size, id, order_no, wx_no, uid, bag_ids, bag_name, bag_status, bag_type,
  box_name, advanced) {
  let params = {
    url: '/scmsapi/order_box/orderBoxList',
    data: {
      page, page_size, id, order_no, wx_no, uid, bag_ids, bag_name, bag_status, bag_type, box_name, advanced
    }
  };
  return post(params);
}
