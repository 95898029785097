/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'login': {
    '__proto__': base,
    'name': 'login',
    'desc': "登录",
    'url': '/scmsapi/auth/login',
    'args': {
      username: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      password: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"id\": 3,\n       \"username\": \"admin\",\n       \"nickname\": \"超级管理员\",\n       \"role_id\": 0,\n       \"creator_id\": 0,\n       \"token\": \"09c877a6cdafec13433a490bf517dadd\",\n       \"mark\": \"\"\n   }\n}\n",
  },
  'logout': {
    '__proto__': base,
    'name': 'logout',
    'desc': "退出登录",
    'url': '/scmsapi/auth/logout',
    'args': {

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'menu': {
    '__proto__': base,
    'name': 'menu',
    'desc': "获取登录菜单",
    'url': '/scmsapi/auth/menu',
    'args': {

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": [\n   {\n     \"id\": 1,\n     \"title\": \"角色管理\",\n     \"mark\": \"admin_role\"\n   }\n ]\n}\n",
  },
  'updatePwd': {
    '__proto__': base,
    'name': 'updatePwd',
    'desc': "修改密码",
    'url': '/scmsapi/auth/updatePwd',
    'args': {
      old_password: {
        'pos': 0,
        'desc': "原密码",
        'demo': '',
      },
      password: {
        'pos': 1,
        'desc': "新密码",
        'demo': '',
      },
      confirm_pwd: {
        'pos': 2,
        'desc': "重复新密码",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  }
};
