import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/coupon';
import * as apis from '../../../api/scms/coupon';

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': "创建优惠券",
    'url': '/scmsapi/coupon/create',
    'defaults': {
      type: '',
      nature: '',
      product_ids: '',
      num: '',
      pick_start_time: '',
      pick_end_time: '',
      use_start_time: '',
      use_end_time: '',
      is_use: '',
      is_pick: '',
      remarks: '',
      is_child: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getOne': {
    '__proto__': base.getOne,
    'api': apis.getOne,
    'desc': "获取优惠券信息",
    'url': '/scmsapi/coupon/getOne',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'index': {
    '__proto__': base.index,
    'api': apis.index,
    'desc': "优惠券列表",
    'url': '/scmsapi/coupon/index',
    'defaults': {
      id: '',
      type: '',
      code: '',
      is_use: '',
      is_pick: '',
      page: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'couponExport': {
    '__proto__': base.couponExport,
    'api': apis.couponExport,
    'desc': "导出优惠券列表(本接口请采用get请求，请求参数与/scmsapi/coupon/index接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)",
    'url': '/scmsapi/coupon/export',
    'defaults': {
      type: '',
      code: '',
      is_use: '',
      is_pick: '',
      export_num: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'children': {
    '__proto__': base.children,
    'api': apis.children,
    'desc': "子码列表",
    'url': '/scmsapi/coupon/children',
    'defaults': {
      id: '',
      code: '',
      username: '',
      status: '',
      is_pick: '',
      page: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'couponChildrenExport': {
    '__proto__': base.couponChildrenExport,
    'api': apis.couponChildrenExport,
    'desc': "导出优惠券子码列表(本接口请采用get请求，请求参数与/scmsapi/coupon/children接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)",
    'url': '/scmsapi/coupon/childrenExport',
    'defaults': {
      id: '',
      code: '',
      username: '',
      status: '',
      is_pick: '',
      export_num: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': "优惠券更新",
    'url': '/scmsapi/coupon/update',
    'defaults': {
      id: '',
      product_ids: '',
      num: '',
      nature: '',
      type: '',
      pick_start_time: '',
      pick_end_time: '',
      use_start_time: '',
      use_end_time: '',
      is_use: '',
      is_pick: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': "删除优惠券",
    'url': '/scmsapi/coupon/delete',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
