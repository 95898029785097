import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/shop/product_category';
import * as apis from '../../../../api/client/shop/product_category';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': " [商城模块]商品分类列表",
    'url': '/api/product-category/list',
    'defaults': {
      page: '',
      pageSize: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
