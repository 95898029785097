import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/shop/cart';
import * as apis from '../../../../api/client/shop/cart';
import {infos as product} from '../../../user/client/shop/product';

async function getOneSkuID() {
  let data = (await product.productList.make()).data.data;
  return data.info[0].productSkuByMinPrice.id;
}

async function getOneID() {
  await infos.cartAdd.make();
  let data = (await infos.cartList.make()).data.data;
  return data.info[0].product_sku_list[0].id;
}

export const infos = {

  'cartAdd': {
    '__proto__': base.cartAdd,
    'api': apis.cartAdd,
    'desc': " [商城模块]添加购物车",
    'url': '/api/cart/add',
    'defaults': {
      product_sku_id: '365',
      quantity: '1',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            product_sku_id : await getOneSkuID()
          };
        }
      },
    ],
  },

  'cartCount': {
    '__proto__': base.cartCount,
    'api': apis.cartCount,
    'desc': " [商城模块]统计购物车数量",
    'url': '/api/cart/count',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'cartList': {
    '__proto__': base.cartList,
    'api': apis.cartList,
    'desc': " [商城模块]购物车列表",
    'url': '/api/cart/list',
    'defaults': {
      pageSize: '10',
      page: '1',
    },
    'nullList':['data.info.0.productPromotion'],
    'keyList':['data.info.0'],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

 'cartDel': {
    '__proto__': base.cartDel,
    'api': apis.cartDel,
    'desc': " [商城模块]删除购物车",
    'url': '/api/cart/del',
    'defaults': {
      product_sku_id: '365',
      quantity: '1',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            product_sku_id: await getOneID(),
            quantity: '0'
          };
        }
      },
    ],
  }
};
