import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/role';
import * as apis from '../../../api/scms/role';

export const infos = {

  'getRoles': {
    '__proto__': base.getRoles,
    'api': apis.getRoles,
    'desc': "所有角色",
    'url': '/scmsapi/role/getRoles',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'menu': {
    '__proto__': base.menu,
    'api': apis.menu,
    'desc': "角色对应菜单",
    'url': '/scmsapi/role/menu',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'setMenu': {
    '__proto__': base.setMenu,
    'api': apis.setMenu,
    'desc': "开关角色对应菜单",
    'url': '/scmsapi/role/setMenu',
    'defaults': {
      role_id: '',
      menu_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
