import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/promotion';
import * as apis from '../../../api/scms/promotion';
import * as utils from "../../../utils/utils";
import {infos as promotion_category} from './promotion_category';

async function getOneID(action) {
  let data = (await infos.index.make(action)).data.data;
  return data.data[0].id;
}

async function createOne() {
  return (await infos.info.make('get_edit_id')).data.data;
}

export const infos = {

  'getProductSelect': {
    '__proto__': base.getProductSelect,
    'api': apis.getProductSelect,
    'desc': "获取已上架商品select",
    'url': '/scmsapi/promotion/getProductSelect',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': "创建促销活动",
    'url': '/scmsapi/promotion/create',
    'defaults': {
      product_id: 0,
      product_promotion_category_id: '',
      start_time: 0,
      end_time: 0,
      limit_number: 1,
      product_sku: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let product_promotion_category_id = (await promotion_category.create.make()).data.data.id;
          const time = 1000;
          await utils.sleep(time); // 避免时间段冲突
          let data = (await infos.getProductSku.make());
          let product_sku = data.data.data.product_sku.map(item=>{
            return {
              product_sku_id:item.id,
              promotion_price:item.price,
              promotion_number:10,
              status:1
            }
          })
          ctx.generated = {
            product_id: data.data.data.id,
            product_promotion_category_id: product_promotion_category_id,
            // 设置时间为10年后，当前商品已有时间段内的促销活动
            start_time: utils.timeNow(31536000 * 10),
            end_time: utils.timeNow(31536000 * 10 + time/1000),
            product_sku,
          };
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': "更新促销活动",
    'url': '/scmsapi/promotion/update',
    'defaults': {
      id: 0,
      product_id: 0,
      start_time: '',
      end_time: '',
      limit_number: 0,
      product_sku: [],
      product_promotion_category_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let data = (await createOne());
          let product_promotion_category_id = (await promotion_category.create.make()).data.data.id;
          ctx.generated = {
            ...data,
            end_time: data.end_time + 1,
            product_sku: data.promotion_sku,
            product_promotion_category_id: product_promotion_category_id,
          };
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "获取活动详情（用于查看或编辑）",
    'url': '/scmsapi/promotion/info',
    'defaults': {
      id: 0,
      action: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id:await getOneID()
          };
        }
      },
      {
        'scene': 'get_edit_id',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = {
            id:(await infos.create.make()).data.data.id,
            action:'edit'
          };
        }
      }
    ],
  },

  'getProductSku': {
    '__proto__': base.getProductSku,
    'api': apis.getProductSku,
    'desc': "获取对应商品sku",
    'url': '/scmsapi/promotion/getProductSku',
    'defaults': {
      id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id:(await infos.getProductSelect.make()).data.data[0].id
          };
        }
      }
    ],
  },

  'updateStatus': {
    '__proto__': base.updateStatus,
    'api': apis.updateStatus,
    'desc': "上下架",
    'url': '/scmsapi/promotion/updateStatus',
    'defaults': {
      id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: (await createOne()).id,
          };
        }
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': "活动删除",
    'url': '/scmsapi/promotion/delete',
    'defaults': {
      id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id:(await infos.create.make()).data.data.id,
          };
        }
      },
    ],
  },

  'index': {
    '__proto__': base.index,
    'api': apis.index,
    'desc': "活动列表",
    'url': '/scmsapi/promotion/index',
    'defaults': {
      id: '',
      start_time: '',
      end_time: '',
      product_title: '',
      status: '',
      page: 1,
      product_promotion_category_id: '',
    },
    "keyList": ['data.data.0.product'],
    'nullList': ['data.data.0.product'],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'close': {
    '__proto__': base.close,
    'api': apis.close,
    'desc': "关闭活动",
    'url': '/scmsapi/promotion/close',
    'defaults': {
      id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id:(await infos.create.make()).data.data.id,
          };
        }
      },
    ],
  }
};
