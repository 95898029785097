/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../../base';

export const infos = {
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': "背包列表\n",
    'url': '/api/blindbox/order/order-box-list',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码",
        'demo': '',
      },
      pageSize: {
        'pos': 1,
        'desc': "分页显示数据条数",
        'demo': '',
      },
      status: {
        'pos': 2,
        'desc': "状态 1:待处理 2:待发货 3:已发货 4:已完成 5:已兑换",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"totalPage\": 4,\n   \"currentPage\": 1,\n   \"__info\": \"i\",\n   \"info\": [\n     {\n       \"id\": \"43\", // 背包数据ID\n       \"status\": \"1\", // 状态 1:待处理 2:待发货 3:已发货 4:已完成 5:已兑换\n       \"bag_id\": \"177\", // 福袋 id\n       \"bag_type\": \"1\", // 福袋类型：1 常规福袋，2 超级福袋的一级福袋，3 超级福袋的二级福袋\n       \"name\": \"血色茉莉\", // 盲盒名字\n       \"img\": \"https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg\", // 盲盒图片\n       \"conch_value\": \"0\", // 可兑换的贝壳值，0表示不可兑换\n       \"condition\": \"1\", // 1 全新未拆盒，2 拆盒未拆袋，3 已拆袋\n       \"order_id\": \"135\", // 订单 id\n       \"order_no\": \"B1071375698\", // 内部订单编号\n       \"wx_no\": \"4200001833202304080624452113\", // 微信订单号\n     }\n   ]\n }\n}\n",
  },
  'conch': {
    '__proto__': base,
    'name': 'conch',
    'desc': "兑换贝壳\n",
    'url': '/api/blindbox/order/conch',
    'args': {
      id: {
        'pos': 0,
        'desc': "背包数据id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": []\n }\n}\n",
  },
  'dispatch': {
    '__proto__': base,
    'name': 'dispatch',
    'desc': "发货\n",
    'url': '/api/blindbox/order/dispatch',
    'args': {
      id: {
        'pos': 0,
        'desc': "背包数据id",
        'demo': '',
      },
      address_id: {
        'pos': 1,
        'desc': "收货地址ID",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": [\n     102\n   ]\n }\n}\n",
  },
  'confirmReceipt': {
    '__proto__': base,
    'name': 'confirmReceipt',
    'desc': "确认收货\n",
    'url': '/api/blindbox/order/confirm-receipt',
    'args': {
      dispatchIDs: {
        'pos': 0,
        'desc': "发货 ID 数组",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": [],\n }\n}\n",
  },
  'conchLog': {
    '__proto__': base,
    'name': 'conchLog',
    'desc': "贝壳明细\n",
    'url': '/api/blindbox/order/conch-log',
    'args': {
      page: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      pageSize: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 1,\n \"message\": \"success!\",\n \"data\": {\n   \"info\": [\n     {\n       \"id\": \"752\",\n       \"type\": \"1\",\n       \"transaction_amount\": \"90\",\n       \"conch\": \"1560\",\n       \"comment\": \"\",\n       \"create_time\": \"1694049180\"\n     }\n   ]\n }\n}\n",
  }
};
