import {dajxHttp} from '../../utils/request.js';
const post = dajxHttp.postScms;


/**
 * @desc 订单列表
 * @param id 子订单ID
 * @param order_status 订单状态 全部订单(不传默认全部)，待付款=0，已付款=1，待收货=2，已完成=3，已取消=4
 * @param order_no 订单编号(子订单编号)
 * @param start_time 下单开始时间
 * @param end_time 下单结束时间
 * @param phone 下单账号
 * @param product_title 商品名
 * @param pay_type 支付方式(微信=0，支付宝=1，微信小程序=2)
 * @param address_name 收件人姓名
 * @param address_tel 收件人电话
 * @param page 页码 默认1
 * @param page_size 每页数据量 默认10
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "total": 6,
        "per_page": 10,
        "current_page": 1,
        "last_page": 1,
        "data": [
            {
                "id": 39,
                "order_no": "S1049587360",
                "create_time": "2020-11-26 10:46:58",
                "status": 0, // 订单状态 0=未发货 1=已发货 3=已完成
                "pay_status": 1, // 支付状态: 0=未付款 1=已付款 2=退款中 3=已退款 4=已取消
                "pay_amount": 4, // 实际支付金额
                "freight_amount": 0, // 运费
                "orderAddress": {
                    "name": "黄翠刚",
                    "province": "四川省",
                    "city": "成都市",
                    "area": "武侯区",
                    "desc": "成都市武侯区天府大道北段1719号，新世纪环球购物中心asdfasfas",
                    "tel": "18111619306"
                },
                "pOrder": { // 关联的主订单
                  "user_id": 11689,
                  "source_id": 0, // 推广渠道 ID
                  "id": 9989, // 主订单 id
                  "order_no": "P1041245097", // 主订单号
                  "user": { // 关联的用户
                    "uid": 11689,
                    "nickname": "黄翠刚",
                    "phone": "18111619306"
                  }
                },
                "orderProduct": [
                    {
                        "id": 77,
                        "product_title": "测试商品d",
                        "product_images_path": "xxx",
                        "product_spec_text": [
                            {
                                "product_spec_key": {
                                    "id": 288,
                                    "title": "重量"
                                },
                                "product_spec_value": {
                                    "id": 593,
                                    "value": "2KG"
                                }
                            }
                        ],
                        "quantity": 1, // 数量
                        "order_no": "S1049587360"
                    }
                ],
                "orderRefund": [
                    {
                        "id": 1,
                        "order_no": "S1049587360",
                        "refund_no": "R1049587360",
                        "amount": 4,
                        "refund_reason": "尺寸不合适",
                        "refund_explain": "",
                        "status": 0,//退款订单状态 0=待审核(已提交) 1=审核通过 2=审核拒绝
                        "create_time": "",
                        "refundProduct": [
                            {
                                "order_product_id": 77 // 订单里的sku_id，前端通过该值判断这种部分退款情况
                            }
                        ]
                    }
                ],
                "orderPay": [
                    {
                        "id": 2,
                        "order_no": "S1049587360",
                        "pay_type": 1, // 状态:0=微信支付 1=支付宝 2=小程序
                        "create_time": "" // 支付时间
                    }
                ],
                "order_status": 1 // 根据查询里的order_status对应出来的参数,可能为null 订单状态 全部订单(不传默认全部)，待付款=0，已付款=1，待收货=2，已完成=3，已取消=4
            }
        ]
    }
}
 */
export function index(id,order_status,order_no,start_time,end_time,phone,product_title,pay_type,address_name,address_tel,page,page_size) {
  let params = {
    url: '/scmsapi/order/index',
    data: {
      id, order_status, order_no, start_time, end_time, phone, product_title, pay_type, address_name, address_tel, page,page_size
    }
  };
  return post(params)
}

/**
 * @desc 获取订单列表(对接外部订单打印机使用)
 * @param order_status 订单状态 全部订单(不传默认全部)，待付款=0，已付款=1，待收货=2，已完成=3，已取消=4
 * @param start_time 下单开始时间(Unix 时间戳)
 * @param end_time 下单结束时间(Unix 时间戳)
 * @param page 页码 默认1
 * @param page_size 每页数据量 默认10
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
         // 数据量
        "total": 6,
        // 每页数据量
        "per_page": 10,
        // 当前分页页码
        "current_page": 1,
        // 总页数
        "last_page": 1,
        // 分页数据
        "data": [
            {
                // 订单ID
                "id": 39,
                // 订单编号
                "order_no": "S1049587360",
                // 订单创建时间
                "create_time": "2020-11-26 10:46:58",
                // 收件人信息
                "orderAddress": {
                    // 收件人姓名
                    "name": "黄翠刚",
                    // 收件人地址所属省份
                    "province": "四川省",
                    // 收件人地址所属市
                    "city": "成都市",
                    // 收件人地址所属区县
                    "area": "武侯区",
                    // 收件人地址详情位置
                    "desc": "成都市武侯区天府大道北段1719号，新世纪环球购物中心asdfasfas",
                    // 收件人联系方式
                    "tel": "18111619306"
                },
                // 商品信息
                "orderProduct": [
                    "商品名称:百事可乐-test 规格:规格:500ML 数量:1"
                ],
                // 订单状态
                "order_status": 1 // 订单状态 待付款=0，已付款=1，待收货=2，已完成=3，已取消=4
            }
        ]
    }
}
 */
export function list(order_status,start_time,end_time,page,page_size) {
    let params = {
        url: '/scmsapi/order/list',
        data: {
            order_status,start_time,end_time,page,page_size
        }
    };
    return post(params)
}

/**
 * @desc 后台手动取消订单
 * @param id 订单id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function cancel(id) {
  let params = {
    url: '/scmsapi/order/cancel',
    data: {
      id
    }
  };
  return post(params)
}


/**
 * @desc 获取快递公司select
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": [{
        "id": 5,
        "name": "EMS"
    }]
}
 */
export function getExpressSelect() {
  let params = {
    url: '/scmsapi/order/getExpressSelect',
    data: {
    }
  };
  return post(params)
}


/**
 * @desc 订单详情
 * @param id 订单id
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "id": 44,
        "order_no": "S1067768374",
        "create_time": "2020-11-26 11:07:51",
        "pay_amount": 4,
        "freight_amount": 0, // 运费
        "coupon_amount":0, // 优惠券金额
        "status": 1, // 订单状态 0=未发货 1=已发货 3=已完成
        "pay_status": 1, // 支付状态: 0=未付款 1=已付款 2=退款中 3=已退款 4=已取消
        "cancel_type": 0, // 取消订单的操作类型 1=用户主动取消订单 2=后台管理员取消订单 3=系统自动取消订单(如超时未支付等等)
        "cancel_reason": '', // 用户主动取消的原因
        "coupon_children_id": 18, // 优惠券子码id
        "express_number": "V030344422", // 快递单号
        "receipt_time": 0, // 确认收货时间
        "dispatch_time": 0, // 发货时间
        "orderAddress": {
            "name": "黄翠刚a",
            "province": "四川省",
            "city": "成都市",
            "area": "武侯区",
            "desc": "成都市武侯区天府大道北段1719号，新世纪环球购物中心asdfasfas",
            "tel": "18111619306"
        },
        "orderProduct": [{
            "id": 87,
            "product_title": "测试商品f",
            "product_images_path": "xxx",
            "product_spec_text": [{
                "product_spec_key": {
                    "id": 288,
                    "title": "重量"
                },
                "product_spec_value": {
                    "id": 593,
                    "value": "2KG"
                }
            }],
            "quantity": 1,
            "order_no": "S1067768374",
            "product_sku_price": 4,
            "product_promotion_id": 0
        }],
        "__coupon": "i",
        "coupon": {
            "id": 9,
            "nature": "11", // 优惠券属性
            "type": 1 // 优惠券类型 1无门槛 2满减 3折扣
        },
        "orderPay": [], // 支付信息
        "orderRefund": [], // 退款信息
        "express": {
            "express_number": "V030344422",
            "context": [{
                "context": "上海分拨中心/下车扫描 ",
                "time": "2012-08-27 23:22:42",
                "ftime": "2012-08-27 23:22:42",
                "status": "测试",
                "areaCode": "310000000000",
                "areaName": "上海市"
            }]
        },
        "__order_status": 't',
        "order_status": 2 // 根据查询里的order_status对应出来的参数,可能为null 订单状态 全部订单(不传默认全部)，待付款=0，已付款=1，待收货=2，已完成=3，已取消=4
    }
}
 */
export function info(id) {
  let params = {
    url: '/scmsapi/order/info',
    data: {
      id
    }
  };
  return post(params)
}

/**
 * @desc 驳回退款
 * @param refund_id 退款信息id，在列表或详情orderRefund字段里
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function refundReject(refund_id) {
  let params = {
    url: '/scmsapi/order/refundReject',
    data: {
      refund_id
    }
  };
  return post(params)
}

/**
 * @desc 同意退款
 * @param refund_id 退款信息id，在列表或详情orderRefund字段里
 * @param debug 后端接口默认关闭，powder默认开启，开启后不会走真实退款
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function refundPass(refund_id,debug=true) {
  let params = {
    url: '/scmsapi/order/refundPass',
    data: {
      refund_id, debug
    }
  };
  return post(params)
}

/**
 * @desc 发货
 * @param id 订单id
 * @param type 订单类型,可不传,默认1, 1:小卖部订单 2:盲盒发货订单
 * @param express_id 快递公司id，由下拉快递公司的select提供
 * @param express_number 快递单号
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "id": 1733,
        "express_number": 1608519754,
        "__express_company": "k",
        "express_company": {
            "id": 5,
            "name": "EMS",
            "code": "ems"
        },
        "__order_status":"i",
        "order_status": 2
    }
}
 */
export function dispatch(id,type,express_id,express_number) {
  let params = {
    url: '/scmsapi/order/dispatch',
    data: {
      id,type,express_id,express_number
    }
  };
  return post(params)
}

/**
 * @desc 物流回调（仅测试使用）
 * @param express_number
 * @returns {Promise<*>}
 * {
  "result": true,
  "returnCode": "200",
  "message": "成功"
}
 */
export function testExpressNotify(express_number = 123123) {
  const param = '{\"status\":\"polling\",\"billstatus\":\"change\",\"message\":\"变化\",\"lastResult\":{\"message\":\"ok\",\"nu\":\"'+express_number+'\",\"ischeck\":\"0\",\"com\":\"yuantongkuaiyun\",\"status\":\"200\",\"data\":[{\"time\":\"2020-12-16 05:45:27\",\"context\":\"【成都转 运中心】 已发出 下一站 【四川省成都市双流县航空港公司】\",\"ftime\":\"2020-12-16 05:45:27\",\"areaCode\":\"CN510122000000\",\"areaName\":\"四川,成都市,双流区\",\"status\":\"在途\"},{\"time\":\"2020-12-16 04:58:10\",\"context\":\"【成都转运中心公司】 已收入\",\"ftime\":\"2020-12-16 04:58:10\",\"areaCode\":\"CN510100000000\",\"areaName\":\"四川,成都市\",\"status\":\"在途\"},{\"time\":\"2020-12-14 02:31:28\",\"context\":\"【金华转运中心】 已发出 下一站 【成都转运中心公司】\",\"ftime\":\"2020-12-14 02:31:28\",\"areaCode\":\"CN330700000000\",\"areaName\":\"浙江,金华市\",\"status\":\"在途\"},{\"time\":\"2020-12-14 02:29:25\",\"context\":\"【金华转运中心公司】 已>收入\",\"ftime\":\"2020-12-14 02:29:25\",\"areaCode\":\"CN330700000000\",\"areaName\":\"浙江,金华市\",\"status\":\"在途\"},{\"time\":\"2020-12-14 01:01:14\",\"context\":\"【浙江省金华市永康市】 已发出 下一站 【金华转运中心公司】\",\"ftime\":\"2020-12-14 01:01:14\",\"areaCode\":\"CN330784000000\",\"areaName\":\"浙江,金华市,永康市\",\"status\":\"在途\"},{\"time\":\"2020-12-14 00:48:11\",\"context\":\"【浙江省金华市永康市公司】 已收件 取件人: 卢岩松 (18716940629)\",\"ftime\":\"2020-12-14 00:48:11\",\"areaCode\":null,\"areaName\":null,\"status\":\"在途\"}],\"state\":\"0\",\"condition\":\"F00\"}}'
  let params = {
    url: '/scmsapi/notify/express',
    data: {
      param:param
    }
  };
  return post(params)
}
