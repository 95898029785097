/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': "获取 banner 列表",
    'url': '/scmsapi/banner/list',
    'args': {
      page: {
        'pos': 0,
        'desc': "分页页码(默认1)",
        'demo': '',
      },
      page_size: {
        'pos': 1,
        'desc': "分页每页数据条数(默认10)",
        'demo': '',
      },
      model_id: {
        'pos': 2,
        'desc': "所属模块id",
        'demo': '',
      },
      start_time: {
        'pos': 3,
        'desc': "时间范围的开始时间(时间戳)",
        'demo': '',
      },
      end_time: {
        'pos': 4,
        'desc': "时间范围的结束时间(时间戳)",
        'demo': '',
      },
      status: {
        'pos': 5,
        'desc': "0 禁用 1 启用",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"total\": 14,\n   \"per_page\": 3,\n   \"current_page\": 1,\n   \"last_page\": 5,\n   \"data\": [\n     {\n       \"id\": 17,\n       \"sort\": 10,\n       \"img_src\": \"http://dev.admin.ugo.uninote.com.cn/oss?path=img/49bfa73e4a203b105fc2ebd299f33261.jpg\",\n       \"jump_link\": \"{\\\"type\\\":0,\\\"id\\\":\\\"\\\"}\",\n       \"start_time\": 1683475200,\n       \"end_time\": 1735488000,\n       \"status\": 1,\n       \"__bannerModels\": \"k\",\n       \"bannerModels\": [\n         {\n           \"id\": 4,\n           \"pid\": 0,\n           \"title\": \"首页\"\n         }\n       ]\n     }\n   ]\n }\n}\n",
  },
  'modelList': {
    '__proto__': base,
    'name': 'modelList',
    'desc': "获取 banner model 列表",
    'url': '/scmsapi/banner/modelList',
    'args': {

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": [\n   {\n     \"id\": 1,\n     \"title\": \"首页 - 顶部\"\n   }\n ]\n}\n",
  },
  'addBanner': {
    '__proto__': base,
    'name': 'addBanner',
    'desc': "添加 banner",
    'url': '/scmsapi/banner/add',
    'args': {
      img_src: {
        'pos': 0,
        'desc': "* banner 图片 url",
        'demo': '',
      },
      start_time: {
        'pos': 1,
        'desc': "有效期开始时间(时间戳)",
        'demo': '',
      },
      end_time: {
        'pos': 2,
        'desc': "有效期结束时间(时间戳)",
        'demo': '',
      },
      sort: {
        'pos': 3,
        'desc': "排序，-100 ~ 100",
        'demo': '',
      },
      status: {
        'pos': 4,
        'desc': "0 禁用 1 启用",
        'demo': '',
      },
      model_ids: {
        'pos': 5,
        'desc': "模块 id 数组",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"banner_id\": 43,\n   \"__bmr_ids\": \"k\",\n   \"bmr_ids\": [\n     106\n   ]\n }\n}\n",
  },
  'updateBanner': {
    '__proto__': base,
    'name': 'updateBanner',
    'desc': "更新 banner，具体参数参见 add 接口",
    'url': '/scmsapi/banner/update',
    'args': {
      id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },
      img_src: {
        'pos': 1,
        'desc': "",
        'demo': '',
      },
      start_time: {
        'pos': 2,
        'desc': "",
        'demo': '',
      },
      end_time: {
        'pos': 3,
        'desc': "",
        'demo': '',
      },
      sort: {
        'pos': 4,
        'desc': "",
        'demo': '',
      },
      status: {
        'pos': 5,
        'desc': "",
        'demo': '',
      },
      model_ids: {
        'pos': 6,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"banner_id\": 43,\n   \"__bmr_ids\": \"k\",\n   \"bmr_ids\": [\n     106\n   ]\n }\n}\n",
  },
  'delBanner': {
    '__proto__': base,
    'name': 'delBanner',
    'desc': "删除 banner",
    'url': '/scmsapi/banner/del',
    'args': {
      id: {
        'pos': 0,
        'desc': "",
        'demo': '',
      },

    },
    'returns': "{Promise<*>} {\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": true\n}\n",
  }
};
