import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../consts';
import {infos as base} from '../generated/tools';
import * as apis from '../../api/tools';
import {infos as xx} from '@/powder/user/client/shop/product';
import {infos as client_order} from '@/powder/user/client/blindbox/order';
import {infos as client_bag} from '@/powder/user/client/blindbox/bag';
import {infos as admin_bag} from '@/powder/user/scms/blindbox/bag';
import * as utils from '@/utils/utils'
import { BAG_TYPE_NORMAL, BAG_TYPE_PLUS, BAG_TYPE_SCRATCH, BAG_TYPE_SUPER1, BAG_STATUS_IN_SALE } from '@/config/constant';

export const infos = {

  'init': {
    '__proto__': base.init,
    'api': apis.init,
    'desc': "初始化数据\n",
    'url': '/api/not-defined-url',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': '初始化库存',
        'desc': '',
        'type': GENE_ONLY,
        'func': async (ctx) => {
          await xx.productList.make('有库存', 10, 20);
          ctx.generated = {};
        }
      },
    ],
  },

  'parallel': {
    '__proto__': base.parallel,
    'api': apis.parallel,
    'desc': "并发测试\n",
    'url': '/api/not-defined-url',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'create-bags',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          for (let i = 0; i < 10; i++) {
            await admin_bag.create.make();
          }
        }
      },
      {
        'scene': 'create-orders',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          for (let i = 0; i < 10; i++) {
            await client_order.pay.make('pay-all-get')
          }
        }
      },
    ],
  },

  'randomAbortOrByAll': {
    '__proto__': base.randomAbortOrByAll,
    'api': apis.randomAbortOrByAll,
    'desc': "随机买完或者取消\n",
    'url': '/api/not-defined-url',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          async function one(type) {
            while(true) {
              let res = await client_bag.list.make('args', {type});
              let arr = res.data.data.info;
              if (!arr.length) {
                break;
              }
              for (let bag of arr) {
                if (Math.random() > 0.5) {
                  // 超级福袋可能返回位置选中状态的福袋
                  if (bag.status == BAG_STATUS_IN_SALE) {
                    await client_order.pay.make('pay-all-get', bag.id);
                  }
                } else {
                  await admin_bag.abort.make('default', bag.id);
                }
              }
            }
          }
          await one(BAG_TYPE_NORMAL);
          await one(BAG_TYPE_SUPER1);
          await one(BAG_TYPE_PLUS);
          await one(BAG_TYPE_SCRATCH);
        }
      },
    ],
  }
};
