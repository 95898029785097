import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/scms/blindbox/order';
import * as apis from '../../../../api/scms/blindbox/order';
import { infos as client_order } from "@/powder/user/client/blindbox/order";
import { infos as admin_bag } from "@/powder/user/scms/blindbox/bag";
import * as utils from "@/utils/utils";

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "福袋订单列表\n",
    'url': '/scmsapi/blindbox/order/list',
    'defaults': {
      page: 1,
      page_size: 10,
      id: 0,
      order_no: '',
      wx_no: '',
      uid: '',
      bag_ids: '',
      pay_status: 1,
      bag_name: '',
      bag_status: '',
      bag_type: '',
      start_time: 0,
      end_time: 0,
      advanced: false,
      is_export: false,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'noDef': true,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        scene: "bag_name",
        desc: "模糊匹配",
        type: GENE_ONLY,
        noDef: true,
        func: async ctx => {
          async function test(
            bag_name,
            suc_arr,
            fail_arr,
            suc_arr_advanced,
            fail_arr_advanced
          ) {
            let res = await admin_bag.create.make("args", {
              name: bag_name,
              start_time: parseInt(Date.now() / 1000) - 3600
            });
            let bag_id = res.data.data.id;
            res = await client_order.create.make("id-pos", bag_id, 0);

            async function testSearch(search_arr, advanced, suc) {
              if (!search_arr) {
                return;
              }
              for (let search_name of search_arr) {
                ctx.generated = {
                  bag_ids: bag_id, // 限定id，避其他bag产生干扰
                  bag_name: search_name,
                  advanced
                };
                res = await ctx.request2(true);
                utils.assert(
                  (suc && res.data.data.data.length > 0) ||
                    (!suc && res.data.data.data.length === 0),
                  "bag_name search error: " +
                    bag_name +
                    ", search: " +
                    search_name +
                    ", advanced: " +
                    advanced +
                    ", suc: " +
                    suc
                );
              }
            }
            await testSearch(suc_arr, false, true);
            await testSearch(fail_arr, false, false);
            await testSearch(suc_arr_advanced, true, true);
            await testSearch(fail_arr_advanced, true, false);
          }
          await test(
            "bag\\",
            ["\\"],
            ["bag%", "bag_"],
            [
              "%\\\\%", // 四个才能匹配一个
              "%\\" // 结尾可以省略一个 '\\'
            ],
            ["%\\%"]
          );
          await test(
            "bag%'",
            [
              "%",
              "'",
              "'" // \' 就是 '
            ],
            ["%%", "bag%_"],
            ['bag%_', '%bag\%_'],
            ['%bag\\%']
          );
        }
      }
    ]
  },
  'listGroupByUser': {
    '__proto__': base.listGroupByUser,
    'api': apis.listGroupByUser,
    'desc': "福袋订单列表-按用户分组\n",
    'url': '/scmsapi/blindbox/order/listGroupByUser',
    'defaults': {
      page: 1,
      page_size: 10,
      wx_no: '',
      uid: '',
      bag_ids: '',
      pay_status: 1,
      start_time: 0,
      end_time: 0,
      advanced: false,
      is_export: false,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'listGroupByDate': {
    '__proto__': base.listGroupByDate,
    'api': apis.listGroupByDate,
    'desc': "福袋订单列表-按日期分组\n",
    'url': '/scmsapi/blindbox/order/listGroupByDate',
    'defaults': {
      page: 1,
      page_size: 10,
      wx_no: '',
      uid: '',
      bag_ids: '',
      pay_status: 1,
      start_time: 0,
      end_time: 0,
      advanced: false,
      is_export: false,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
