import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/order';
import * as apis from '../../../api/scms/order';
import * as utils from "../../../utils/utils";
import * as shop_order from '../client/shop/order';
import * as order_box from './blindbox/order_box';
import * as client_order_box from '../client/blindbox/order_box';

//获取一条订单
async function getOneID(action) {
  let data = (await infos.index.make(action)).data.data;
  return data.data[0].id;
}

//获取快递公司id
async function getExpressID() {
  let data = (await infos.getExpressSelect.make()).data.data;
  return data[0].id;
}

//获取退款申请id
async function getRefundId() {
  let data = (await shop_order.infos.refund.make()).data.data;
  return data.info.id;
}

export const infos = {

  'index': {
    '__proto__': base.index,
    'api': apis.index,
    'desc': "订单列表",
    'url': '/scmsapi/order/index',
    'defaults': {
      id: '',
      order_status: '',
      order_no: '',
      start_time: '',
      end_time: '',
      phone: '',
      product_title: '',
      pay_type: '',
      address_name: '',
      address_tel: '',
      page: 1,
      page_size: 10,
    },
    "keyList": ["data.data.0.orderRefund.0","data.data.0.orderPay.0"],
    "typeList": ["data.data.0.order_status"],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '获取订单列表-全状态',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': 'get_dispatch_id',
        'desc': '获取订单列表-已付款',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {
            order_status:1
          };
        }
      },
      {
        'scene': 'get_cancel_id',
        'desc': '获取订单列表-未付款',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {
            order_status:0
          };
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取订单列表(对接外部订单打印机使用)",
    'url': '/scmsapi/order/list',
    'defaults': {
      order_status: '',
      start_time: '',
      end_time: '',
      page: '',
      page_size: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'cancel': {
    '__proto__': base.cancel,
    'api': apis.cancel,
    'desc': "后台手动取消订单",
    'url': '/scmsapi/order/cancel',
    'defaults': {
      id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '从已有列表中获取一条待支付的来操作',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {
             id: await getOneID('get_cancel_id'),
          };
        }
      },
      {
        'scene': 'create',
        'desc': '创建一条新的未付款记录来操作',
        'func': async (ctx) => {
          ctx.generated = {
            id: (await shop_order.create()).children_order_id[0],
          };
        }
      },
    ],
  },

  'getExpressSelect': {
    '__proto__': base.getExpressSelect,
    'api': apis.getExpressSelect,
    'desc': "获取快递公司select",
    'url': '/scmsapi/order/getExpressSelect',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "订单详情",
    'url': '/scmsapi/order/info',
    'defaults': {
      id: 1,
    },
    "keyList": ["data.orderProduct.0.product_sku_original_price"],
    "nullList": ["data.coupon", "data.express"],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '从已有列表中获取一条记录展示',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(),
          };
        }
      },
    ],
  },

  'refundReject': {
    '__proto__': base.refundReject,
    'api': apis.refundReject,
    'desc': "驳回退款",
    'url': '/scmsapi/order/refundReject',
    'defaults': {
      refund_id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            refund_id:await getRefundId(),
          };
        }
      },
    ],
  },

  'refundPass': {
    '__proto__': base.refundPass,
    'api': apis.refundPass,
    'desc': "同意退款",
    'url': '/scmsapi/order/refundPass',
    'defaults': {
      refund_id: 0,
      debug: true,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            refund_id:await getRefundId(),
            debug:true
          };
        }
      },
    ],
  },

  'dispatch': {
    '__proto__': base.dispatch,
    'api': apis.dispatch,
    'desc': "发货",
    'url': '/scmsapi/order/dispatch',
    'defaults': {
      id: 1,
      type: 1,
      express_id: 1,
      express_number: 'V030344422',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '从已有列表中获取一条已付款的来操作',
        'type': NO_BATCH,
        'func': async (ctx,express_number) => {
          ctx.generated = {
            id: await getOneID('get_dispatch_id'),
            express_id: await getExpressID(),
            express_number: express_number || utils.timeNow(0),
          };
        }
      },
      {
        'scene': 'create',
        'desc': '创建一条新的已付款订单来操作',
        'func': async (ctx,express_number) => {
          ctx.generated = {
            id: (await shop_order.createPay()).children_order_id[0],
            express_id: await getExpressID(),
            express_number: express_number || utils.timeNow(0),
          };
        }
      },
      {
        'scene': 'express-notify',
        'desc': '发货并且有物流回调数据',
        'type': GENE_ONLY,
        'func': async (ctx) => {
            let express_number = utils.timeNow(0)
            await ctx.make('create',express_number)
            await infos.testExpressNotify.make('with-no',express_number)
        }
      },
      {
        'scene': 'create-multi-sku',
        'desc': '只发第一个子订单',
        'func': async (ctx) => {
          ctx.generated = {
            id: (await shop_order.createPay('multi-sku')).children_order_id[0],
            express_id: await getExpressID(),
            express_number: utils.timeNow(0),
          };
        }
      },
      {
        'scene': 'with-id',
        'desc': '指定 id',
        'type': INNER,
        'func': async (ctx, id) => {
          ctx.generated = {
            id,
            express_id: await getExpressID(),
            express_number: utils.timeNow(0),
          };
        }
      },
      {
        'scene': 'create-multi-sku-dispatch-all',
        'desc': '全部子订单发货',
        'type': GENE_ONLY,
        'func': async (ctx) => {
          let data = (await shop_order.createPay('multi-sku'));
          for (let id of data.children_order_id) {
            await ctx.make('with-id', id);
          }
        }
      },
    ],
  },

  'testExpressNotify': {
    '__proto__': base.testExpressNotify,
    'api': apis.testExpressNotify,
    'desc': "物流回调（仅测试使用）",
    'url': '/scmsapi/notify/express',
    'defaults': {
      express_number: '123123',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': 'with-no',
        'desc': '用于发货的时候指定物流单号调用',
        'type': INNER,
        'noCode': true,
        'func': async (ctx,express_number) => {
          ctx.generated = {
            express_number
          };
        }
      }
    ],
  }
};
