import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/config';
import * as apis from '../../../api/scms/config';
import * as utils from '@/utils/utils'
import * as assert from '@/bb/assert';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取 config 列表",
    'url': '/scmsapi/config/list',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': "更新 config 项",
    'url': '/scmsapi/config/update',
    'defaults': {
      id: 0,
      value: '',
      comment: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'noDef':true,
        'func': async (ctx) => {
          let res = await infos.list.make();
          ctx.generated = {
            id: res.data.data[0].id,
            comment: res.data.data[0].comment,
            value: utils.randomContent() + "",
          };
        },
        validate: async (ctx, info) => {
          let res = await infos.list.make();
          utils.assert(res.data.data[0].value === ctx.generated.value, "config update fail");
        },
        'testSuites': [
          {
            'name': 'params empty',
            validate: assert.assertParamEmpty,
            'tests': ['id']
          },
        ],
      },
    ],
  }
};
