import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/banner';
import * as apis from '../../../api/scms/banner';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取 banner 列表",
    'url': '/scmsapi/banner/list',
    'defaults': {
      page: '',
      page_size: '',
      model_id: '',
      start_time: '',
      end_time: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'modelList': {
    '__proto__': base.modelList,
    'api': apis.modelList,
    'desc': "获取 banner model 列表",
    'url': '/scmsapi/banner/modelList',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'addBanner': {
    '__proto__': base.addBanner,
    'api': apis.addBanner,
    'desc': "添加 banner",
    'url': '/scmsapi/banner/add',
    'defaults': {
      img_src: '',
      start_time: '',
      end_time: '',
      sort: '',
      status: '',
      model_ids: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updateBanner': {
    '__proto__': base.updateBanner,
    'api': apis.updateBanner,
    'desc': "更新 banner，具体参数参见 add 接口",
    'url': '/scmsapi/banner/update',
    'defaults': {
      id: '',
      img_src: '',
      start_time: '',
      end_time: '',
      sort: '',
      status: '',
      model_ids: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'delBanner': {
    '__proto__': base.delBanner,
    'api': apis.delBanner,
    'desc': "删除 banner",
    'url': '/scmsapi/banner/del',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
