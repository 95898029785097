import {dajxHttp} from '../../utils/request.js';
const post = dajxHttp.postScms;


/**
 * @desc 注册用户
 * @param username 用户名/手机
 * @param password 密码
 * @param confirm_pwd 重复密码
 * @param nickname 昵称
 * @param role_id 角色id
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "username": "18100571481",
        "id": 29
    }
}
 */
export function register(username,password,confirm_pwd,nickname='',role_id) {
  let params = {
    url: '/scmsapi/admin/register',
    data: {
      username,
      password,
      confirm_pwd,
      nickname,
      role_id
    }
  };
  return post(params);
}

/**
 * @desc 用户列表
 * @param id 账号id
 * @param status 状态（0禁用，1正常）
 * @param username 用户名/手机
 * @param role_id 角色id
 * @param page 页码默认1
 * @returns {Promise<*>}
 * {
    "code": 200,
    "msg": "success",
    "data": {
        "total": 11,
        "per_page": 10,
        "current_page": 1,
        "last_page": 2,
        "data": [
            {
                "id": 5,
                "username": "18111619306",
                "nickname": "测试供应商",
                "creator_id": 3,
                "status": 1, // 状态(0禁用1正常)
                "role_id": 3,
                "create_time": "2020-11-06 16:26:22",
                "role_name": "供货商",
                "role_mark": "supplier_mark",
                "creator": {
                    "username": "admin",
                    "role_name": null
                }
            }
        ]
    }
}
 */
export function index(id,status,username,role_id,page) {
  let params = {
    url: '/scmsapi/admin/index',
    data: {
      id,
      status,
      username,
      role_id,
      page
    }
  };
  return post(params);
}

/**
 * @desc 用户启用禁用
 * @param id 用户id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function updateStatus(id) {
  let params = {
    url: '/scmsapi/admin/updateStatus',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc 重置密码-密码变为123456
 * @param id 用户id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function resetPwd(id) {
  let params = {
    url: '/scmsapi/admin/resetPwd',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc 用户删除
 * @param id 用户id
 * @returns {Promise<*>}
 * {
  "code": 200,
  "msg": "success",
  "data": []
}
 */
export function del(id) {
  let params = {
    url: '/scmsapi/admin/delete',
    data: {
      id
    }
  };
  return post(params);
}

/**
 * @desc  获取供应商列表
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": [
    {
      "id": 101,
      "nickname": "测试用户"
    }
  ]
}
 */
export function adminGetSupplierList() {
  let params = {
    url: '/scmsapi/admin/getSupplierList',
    data: {}
  };
  return post(params);
}


/**
 * @desc  图片上传接口
 * 注意:本接口实际上是请求的[客户端api项目]提供的/upload/image接口,
 * 所以接口的请求参数和返回参数，需要以[客户端api项目]的/upload/image接口文档为准
 * @returns {Promise<*>}
 {
  "code": 200,
  "msg": "success",
  "data": [
    {
      "url": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2021/09/e38a32dd876746af92d53bcb7bbce9e4.png",
      "file_key": "e38a32dd876746af92d53bcb7bbce9e4"
    }
  ]
}
 */
export function uploadImage(file_base64,scene) {
  let params = {
    url: '/scmsapi/upload/image',
    data: {file_base64,scene}
  };
  return post(params);
}
