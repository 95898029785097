<template>
  <el-dialog
      title="参数配置"
      :visible.sync="innerVisible"
      destroy-on-close
      :before-close="oncancel"
      width="80%"
  >
  <div>
    <el-row style="margin-bottom: 10px">
      <p>url参数</p> <el-button type="primary" size="small" @click="addOptions('url')">增加参数</el-button>
    </el-row>
    <el-row
        :gutter="20"
        v-for="item in handledOptions.url"
        :key="item.k"
    >
      <el-col :span="12" style="margin-bottom: 5px">
        <el-input v-model="item.key" size="small" @keydown.native.enter="onOk">
          <template slot="prepend">参数名：</template>
        </el-input>
      </el-col>
      <el-col :span="12">
        <el-input v-model="item.value" size="small" @keydown.native.enter="onOk">
          <template slot="prepend">参数值：</template>
          <el-button
              slot="append"
              icon="el-icon-remove-outline"
              @click="removeOption(item.key, 'url')"
          />
        </el-input>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 10px">
      <p>storage参数</p> <el-button type="primary" size="small" @click="addOptions('storage')">增加参数</el-button>
    </el-row>
    <el-row
      :gutter="20"
      v-for="item in handledOptions.storage"
      :key="item.k"
    >
      <el-col :span="12" style="margin-bottom: 5px">
        <el-input v-model="item.key" size="small" @keydown.native.enter="onOk">
          <template slot="prepend">参数名：</template>
        </el-input>
      </el-col>
      <el-col :span="12">
        <el-input v-model="item.value" size="small" @keydown.native.enter="onOk">
          <template slot="prepend">参数值：</template>
          <el-button
            slot="append"
            icon="el-icon-remove-outline"
            @click="removeOption(item.key, 'storage')"
          />
        </el-input>
      </el-col>
    </el-row>
    <el-row style="margin-bottom: 10px">
      <p>url rewrite 配置</p> <el-button type="primary" size="small" @click="addRewriteRules()">增加参数</el-button>
    </el-row>
    <el-row
      :gutter="20"
      v-for="item in rewriteConfig"
      :key="item.k"
    >
      <el-col :span="12" style="margin-bottom: 5px">
        <el-input v-model="item.key" size="small" @keydown.native.enter="onOk">
          <template slot="prepend">参数名：</template>
        </el-input>
      </el-col>
      <el-col :span="12">
        <el-input v-model="item.value" size="small" @keydown.native.enter="onOk">
          <template slot="prepend">参数值：</template>
          <el-button
            slot="append"
            icon="el-icon-remove-outline"
            @click="removeRewriteRules(item.key)"
          />
        </el-input>
      </el-col>
    </el-row>
  </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="oncancel">取 消</el-button>
      <el-button type="primary" @click="onOk">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
const spread = 'storage_';
export default {
  name: "urlParamsModal",
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    info: {
      type: Object,
      required: true,
      default: () => ({}),
    }
  },
  data() {
    const rewriteConfig = JSON.parse(localStorage.getItem('rewriteConfig') || '[]');
    return {
      options: [],
      handledOptions: [],
      innerVisible: false,
      originInfo: {},
      rewriteConfig,
      removeRewriteConfig: false,
    }
  },
  watch: {
    info(curVal) {
      this.originInfo = curVal;
      this.setOptions(JSON.parse(JSON.stringify(curVal)));
    },
    visible(curVal) {
      this.innerVisible = curVal;
    },
  },
  methods: {
    oncancel() {
      this.innerVisible = false;
      this.$emit('onCancel');
      this.setOptions(JSON.parse(JSON.stringify(this.originInfo)));
    },
    addRewriteRules() {
      this.rewriteConfig.push({ key: '', value: '' });
    },
    removeRewriteRules(key) {
      this.rewriteConfig = this.rewriteConfig.filter((item) => (key !== item.key));
    },
    setOptions(paramsObj) {
     const options = Object.keys(paramsObj).map((key) => ({ key, k: key, value: paramsObj[key] }));
      this.handledOptions = options.reduce((result, cur) => {
        const { k, key, value } = cur;
        const splitKeys = key.split(spread);
        if (splitKeys.length > 1) {
          result.storage = [...result.storage, { k, key: splitKeys[1], value}];
        } else {
          result.url = [
            ...result.url,
            cur
          ]
        }
        return result;
      }, { url: [], storage: [] });
    },
    onOk() {
      this.$emit('onChange', this.handledOptions);
      if (!this.removeRewriteConfig) {
        const effectiveRewriteRules = this.rewriteConfig.filter(({key}) => (key));
        localStorage.setItem('rewriteConfig', JSON.stringify(effectiveRewriteRules));
      }
      this.oncancel();
    },
    removeOption(key, target) {
      this.removeRewriteConfig = key === 'rewriteConfig' && target === 'storage';
      const remainOptions = this.handledOptions[target].filter((item) => (key !== item.key));
      this.handledOptions[target] = [
          ...remainOptions,
      ];
    },
    addOptions(target) {
      this.handledOptions[target] = [
          ...this.handledOptions[target],
        { key: '', value: '' },
      ];
    },
  }
}
</script>

<style scoped lang="less">

</style>
