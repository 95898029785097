import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/order';
import * as apis from '../../../api/scms/order';

export const infos = {

  'index': {
    '__proto__': base.index,
    'api': apis.index,
    'desc': "订单列表",
    'url': '/scmsapi/order/index',
    'defaults': {
      id: '',
      order_status: '',
      order_no: '',
      start_time: '',
      end_time: '',
      phone: '',
      product_title: '',
      pay_type: '',
      address_name: '',
      address_tel: '',
      page: '',
      page_size: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取订单列表(对接外部订单打印机使用)",
    'url': '/scmsapi/order/list',
    'defaults': {
      order_status: '',
      start_time: '',
      end_time: '',
      page: '',
      page_size: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'cancel': {
    '__proto__': base.cancel,
    'api': apis.cancel,
    'desc': "后台手动取消订单",
    'url': '/scmsapi/order/cancel',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getExpressSelect': {
    '__proto__': base.getExpressSelect,
    'api': apis.getExpressSelect,
    'desc': "获取快递公司select",
    'url': '/scmsapi/order/getExpressSelect',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "订单详情",
    'url': '/scmsapi/order/info',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'refundReject': {
    '__proto__': base.refundReject,
    'api': apis.refundReject,
    'desc': "驳回退款",
    'url': '/scmsapi/order/refundReject',
    'defaults': {
      refund_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'refundPass': {
    '__proto__': base.refundPass,
    'api': apis.refundPass,
    'desc': "同意退款",
    'url': '/scmsapi/order/refundPass',
    'defaults': {
      refund_id: '',
      debug: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'dispatch': {
    '__proto__': base.dispatch,
    'api': apis.dispatch,
    'desc': "发货",
    'url': '/scmsapi/order/dispatch',
    'defaults': {
      id: '',
      type: '',
      express_id: '',
      express_number: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'testExpressNotify': {
    '__proto__': base.testExpressNotify,
    'api': apis.testExpressNotify,
    'desc': "物流回调（仅测试使用）",
    'url': '/scmsapi/notify/express',
    'defaults': {
      express_number: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
