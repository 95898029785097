import {dajxHttp} from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc  [商城模块]商品分类列表
 * @param pageSize 分页显示数据条数 默认10
 * @param page 分页页码 默认1
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "totalPage": 1,
    "currentPage": 1,
    "info": [
      {
        "name": "自动生成的分类名称餂",
        "id": "11",
        // 商品分类图
        "product_category_image": ""
      }
    ]
  }
}
 */
export async function list(page,pageSize) {
  return await post({
    url: '/api/product-category/list',
    data: {
      "page":page,
      "pageSize":pageSize,
    }
  });
}
