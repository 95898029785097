import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/blindbox/bag';
import * as apis from '../../../../api/client/blindbox/bag';
import {infos as bbAdmin } from '@/powder/user/scms/blindbox/bag';
import * as utils from '@/utils/utils'
import * as constants from '@/config/constant';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "福袋列表\n",
    'url': '/api/blindbox/bag/list',
    'defaults': {
      page: 1,
      page_size: 10,
      type: 1,
      status: 2,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '默认进行中的',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            type: constants.BagType,
          };
        }
      }, {
        'scene': 'all',
        'desc': '',
        'type': NORMAL,
        'noDef': true,
        'func': async (ctx) => {
          ctx.generated = {
            status: '',
            type: constants.BagType,
          };
        }
      }, {
        'scene': 'create',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await bbAdmin.create.make();
          ctx.generated = {
            type: constants.BagType,
          };
        }
      }, {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, args) => {
          ctx.generated = args;
        }
      }
    ],
  },

  'luckyBagInfo': {
    '__proto__': base.luckyBagInfo,
    'api': apis.luckyBagInfo,
    'desc': "常规福袋详情",
    'url': '/api/blindbox/bag/lucky-bag-info',
    'defaults': {
      id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          let data = await infos.list.make();
          ctx.generated = {
            id: data.data.data.info[0].id,
          };
        }
      }, {
        'scene': 'create',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx, scene = 'default') => {
          let bag = await bbAdmin.create.make(scene);
          ctx.generated = {
            id: bag.data.data.id,
          };
        }
      }, {
        'scene': 'id',
        'desc': '',
        'type': INNER,
        'func': async (ctx, id) => {
          if (!id) {
            let data = await infos.list.make();
            id = data.data.data.info[0].id;
          }
          ctx.generated = {
            id,
          };
        }
      }
    ],
  }
};
