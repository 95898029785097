import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/client/wallet';
import * as apis from '../../../api/client/wallet';

export const infos = {

  'get_balance': {
    '__proto__': base.get_balance,
    'api': apis.get_balance,
    'desc': "用户钱包余额",
    'url': '/api/wallet/get-balance',
    'defaults': {
      uid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'billing_details': {
    '__proto__': base.billing_details,
    'api': apis.billing_details,
    'desc': "账单明细",
    'url': '/api/wallet/billing-details',
    'defaults': {
      uid: '',
      status: '',
      start: '',
      end: '',
      page: '',
      offset: '',
      type: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'is_wx_bind': {
    '__proto__': base.is_wx_bind,
    'api': apis.is_wx_bind,
    'desc': "判断该用户是否授权绑定",
    'url': '/api/wallet/is-wx-bind',
    'defaults': {
      uid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'supply_withdraw': {
    '__proto__': base.supply_withdraw,
    'api': apis.supply_withdraw,
    'desc': "发起提现",
    'url': '/api/wallet/supply-withdraw',
    'defaults': {
      uid: '',
      amount: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
