import { dajxHttp } from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc 背包列表
 *
 * @param page 分页页码
 * @param pageSize 分页显示数据条数
 * @param status 状态 1:待处理 2:待发货 3:已发货 4:已完成 5:已兑换
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "totalPage": 4,
    "currentPage": 1,
    "__info": "i",
    "info": [
      {
        "id": "43", // 背包数据ID
        "status": "1", // 状态 1:待处理 2:待发货 3:已发货 4:已完成 5:已兑换
        "bag_id": "177", // 福袋 id
        "bag_type": "1", // 福袋类型：1 常规福袋，2 超级福袋的一级福袋，3 超级福袋的二级福袋
        "name": "血色茉莉", // 盲盒名字
        "img": "https://admin.bb.uninote.com.cn/oss?path=bb/images/mh03.jpg", // 盲盒图片
        "conch_value": "0", // 可兑换的贝壳值，0表示不可兑换
        "condition": "1", // 1 全新未拆盒，2 拆盒未拆袋，3 已拆袋
        "order_id": "135", // 订单 id
        "order_no": "B1071375698", // 内部订单编号
        "wx_no": "4200001833202304080624452113", // 微信订单号
      }
    ]
  }
}
 */
export function list(page, pageSize, status) {
  let params = {
    url: "/api/blindbox/order/order-box-list",
    data: {
      page, pageSize, status
    }
  };
  return post(params);
}

/**
 * @desc 兑换贝壳
 *
 * @param id 背包数据id
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": []
  }
}
 */
export function conch(id) {
  let params = {
    url: "/api/blindbox/order/conch",
    data: {id}
  };
  return post(params);
}

/**
 * @desc 发货
 *
 * @param id 背包数据id
 * @param address_id 收货地址ID
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": [
      102
    ]
  }
}
 */
export function dispatch(id, address_id) {
  let params = {
    url: "/api/blindbox/order/dispatch",
    data: {id, address_id}
  };
  return post(params);
}

/**
 * @desc 确认收货
 *
 * @param dispatchIDs 发货 ID 数组
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": [],
  }
}
 */
export function confirmReceipt(dispatchIDs) {
  let params = {
    url: "/api/blindbox/order/confirm-receipt",
    data: {dispatchIDs}
  };
  return post(params);
}

/**
 * @desc 贝壳明细
 *
 * @param page
 * @param pageSize
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "info": [
      {
        "id": "752",
        "type": "1",
        "transaction_amount": "90",
        "conch": "1560",
        "comment": "",
        "create_time": "1694049180"
      }
    ]
  }
}
 */
export function conchLog(page, pageSize) {
  let params = {
    url: "/api/blindbox/order/conch-log",
    data: {page, pageSize}
  };
  return post(params);
}
