import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/promotion';
import * as apis from '../../../api/scms/promotion';

export const infos = {

  'getProductSelect': {
    '__proto__': base.getProductSelect,
    'api': apis.getProductSelect,
    'desc': "获取已上架商品select",
    'url': '/scmsapi/promotion/getProductSelect',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': "创建促销活动",
    'url': '/scmsapi/promotion/create',
    'defaults': {
      product_id: '',
      product_promotion_category_id: '',
      start_time: '',
      end_time: '',
      limit_number: '',
      product_sku: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': "更新促销活动",
    'url': '/scmsapi/promotion/update',
    'defaults': {
      id: '',
      product_id: '',
      start_time: '',
      end_time: '',
      limit_number: '',
      product_sku: '',
      product_promotion_category_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': "获取活动详情（用于查看或编辑）",
    'url': '/scmsapi/promotion/info',
    'defaults': {
      id: '',
      action: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getProductSku': {
    '__proto__': base.getProductSku,
    'api': apis.getProductSku,
    'desc': "获取对应商品sku",
    'url': '/scmsapi/promotion/getProductSku',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updateStatus': {
    '__proto__': base.updateStatus,
    'api': apis.updateStatus,
    'desc': "上下架",
    'url': '/scmsapi/promotion/updateStatus',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': "活动删除",
    'url': '/scmsapi/promotion/delete',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'index': {
    '__proto__': base.index,
    'api': apis.index,
    'desc': "活动列表",
    'url': '/scmsapi/promotion/index',
    'defaults': {
      id: '',
      start_time: '',
      end_time: '',
      product_title: '',
      status: '',
      page: '',
      product_promotion_category_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'close': {
    '__proto__': base.close,
    'api': apis.close,
    'desc': "关闭活动",
    'url': '/scmsapi/promotion/close',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
