import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/banner';
import * as apis from '../../../api/scms/banner';
import * as utils from '@/utils/utils';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取 banner 列表",
    'url': '/scmsapi/banner/list',
    'defaults': {
      page: 1,
      page_size: 10,
      model_id: 0,
      start_time: 0,
      end_time: 0,
      status: 1,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'modelList': {
    '__proto__': base.modelList,
    'api': apis.modelList,
    'desc': "获取 banner model 列表",
    'url': '/scmsapi/banner/modelList',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'addBanner': {
    '__proto__': base.addBanner,
    'api': apis.addBanner,
    'desc': "添加 banner",
    'url': '/scmsapi/banner/add',
    'defaults': {
      img_src: '',
      start_time: 0,
      end_time: 0,
      sort: 0,
      status: 1,
      model_ids: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let res = await infos.modelList.make();
          let model_ids = res.data.data.map((item) => {
            return item.id;
          })
          ctx.__max_id = model_ids[model_ids.length - 1];
          model_ids = model_ids.slice(0, 2);
          ctx.generated = {
            img_src: 'http://dev.admin.ugo.uninote.com.cn/oss?path=img/78b20ea010d3ebdb0ea6fc0acaad0b9d.jpg',
            model_ids,
          };
        },
        'testSuites': [
          {
            'name': 'fail',
            'tests': [
              (ctx) => {
                ctx.generated = {
                  model_ids: [ctx.__max_id + 1],
                };
                return (ctx, info) => {
                  utils.assert(info.data.code === 10000, "错误的 model id 应报错");
                }
              },
            ]
          },
        ],
      },
    ],
  },

  'updateBanner': {
    '__proto__': base.updateBanner,
    'api': apis.updateBanner,
    'desc': "更新 banner，具体参数参见 add 接口",
    'url': '/scmsapi/banner/update',
    'defaults': {
      id: 0,
      img_src: '',
      start_time: 0,
      end_time: 0,
      sort: 0,
      status: 1,
      model_ids: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          let res = await infos.list.make();
          ctx.generated = res.data.data.data[0];
        }
      },
      {
        'scene': 'create',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await infos.addBanner.make();
          let res = await infos.list.make();
          let first = res.data.data.data[0];
          ctx.generated = first;
          ctx.generated.model_ids = first.bannerModels.map((item) => {
            return item.id;
          });
        }
      },
    ],
  },

  'delBanner': {
    '__proto__': base.delBanner,
    'api': apis.delBanner,
    'desc': "删除 banner",
    'url': '/scmsapi/banner/del',
    'defaults': {
      id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await infos.addBanner.make();
          let res = await infos.list.make();
          ctx.generated.id = res.data.data.data[0].id;
        }
      },
    ],
  }
};
