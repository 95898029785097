import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/blindbox/job';
import * as apis from '../../../../api/client/blindbox/job';

export const infos = {

  'updateBagStatus': {
    '__proto__': base.updateBagStatus,
    'api': apis.updateBagStatus,
    'desc': "更新福袋状态\n",
    'url': '/api/blindbox/job/update-bag-status',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
