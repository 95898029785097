import {dajxHttp} from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc  [商城模块]添加购物车
 * @param product_sku_id 商品SKUID
 * @param quantity 商品数量
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": []
  }
}
 */
export async function cartAdd(product_sku_id,quantity) {
  return await post({
    url: '/api/cart/add',
    data: {
      "product_sku_id":product_sku_id,
      "quantity":quantity,
    }
  });
}

/**
 * @desc  [商城模块]统计购物车数量
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "quantity_count": 1
    }
  }
}
 */
export async function cartCount() {
  return await post({
    url: '/api/cart/count',
    data: {}
  });
}

/**
 * @desc  [商城模块]购物车列表
 * @param pageSize 分页显示数据条数 默认10
 * @param page 分页页码 默认1
 * @returns {Promise<any>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "totalPage": 1,
    "currentPage": 1,
    "info": [
      {
        //商品信息
        "product": {
          //商品ID
          "id": "12048",
          //商品名称
          "title": "猪头肉",
          //计量单位
          "unit": "斤",
          //状态 1=上架 2=下架
          "status": "1",
          //删除时间 (注意:前端根据本字段判断商品是否被删除,0:未删除 !0:已删除)
          "delete_time": "0"
        },
        //商品SKU列表,，包含多个对象，每个对象字段如展开所示
        "product_sku_list": [
          {
            //商品SKUID
            "id": "3756",
            //商品SKU的规格,包含多个对象，每个对象字段如展开所示
            "product_spec": [
              {
                "product_spec_key": {
                  "id": 517,
                  "title": "颜色"
                },
                "product_spec_value": {
                  "id": 945,
                  "value": "蓝"
                }
              }
            ],
            //状态 1=正常 2=禁用
            "status": "1",
            "price": "80",
            //购物车数量
            "cart_sku_quantity": "1"
          }
        ],
        //	商品图片
        "productImages": {
          //图片类型 1=商品入户图 2=商品bannner图 3=商品介绍图
          "type": "1",
          "url": "https://admin.bb.uninote.com.cn/oss?path=uploads/images/2020/12/f1cecad5ef235345f04934a9bacef0ef.png"
        },
        //商品促销活动
        "__productPromotion": "i",
        "productPromotion": {
          //促销活动ID
          "id": "7",
          //开始时间
          "start_time": "1606550460",
          //结束时间
          "end_time": "1606550460",
          //限购数量(注意: 这里是每个用户的限购数量, 不是每次限购数量)
          "limit_number": "5",
          //商品促销活动sku
          "productPromotionSku": [
            {
              //商品skuid
              "product_sku_id": "3756",
              //促销价格
              "promotion_price": "10",
              //促销数量
              "promotion_number": "10",
              //实际销售数量
              "used_number": "0"
            }
          ],
          //用户已参与过本活动的数量(如果本字段的值为1,活动的限购数量字段limit_number为2,说明当前用户还可以参与本活动1个数量,如果本字段的值为2,说明当前用户不可以参与本活动了,已经达到活动的限购数量了1个数量)
          "used_number": 2
        }
      }
    ]
  }
}
 */
export async function cartList(pageSize,page) {
  return await post({
    url: '/api/cart/list',
    data: {
      "pageSize":pageSize,
      "page":page,
    }
  });
}

/**
 * @desc  [商城模块]删除购物车
 * @param product_sku_id 商品SKUID
 * @param quantity 商品数量(0为删除,非0为减少数量,注意,数量减少到1将不能再减少)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": []
  }
}
 */
export async function cartDel(product_sku_id,quantity) {
  return await post({
    url: '/api/cart/del',
    data: {
      "product_sku_id":product_sku_id,
      "quantity":quantity,
    }
  });
}
