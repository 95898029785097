/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'create': {
    '__proto__': base,
    'name': 'create',
    'desc': " 创建商品分类",
    'url': '/scmsapi/product_category/create',
    'args': {
      name: {
        'pos': 0,
        'desc': "分类名称",
        'demo': '',
      },
      sort: {
        'pos': 1,
        'desc': "排序序号",
        'demo': '',
      },
      product_category_image: {
        'pos': 2,
        'desc': "商品分类图片\n product_category_image: {\n     // 图片key[/upload/image接口上传图片后获得]\n     \"file_key\": \"xxx\",\n     // 图片可访问路径[/upload/image接口上传图片后获得]\n     \"url\": \"xxx\"\n },",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": {\n       \"id\": 2,\n   }\n}\n",
  },
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': " 获取商品分类列表",
    'url': '/scmsapi/product_category/list',
    'args': {
      id: {
        'pos': 0,
        'desc': "商品类型id",
        'demo': '',
      },
      name: {
        'pos': 1,
        'desc': "分类名称(模糊查询)",
        'demo': '',
      },
      page: {
        'pos': 2,
        'desc': "分页页码 默认1",
        'demo': '',
      },
      page_size: {
        'pos': 3,
        'desc': "分页每页数据条数 默认10",
        'demo': '',
      },
      status: {
        'pos': 4,
        'desc': "状态 1=启用 2=禁用",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"total\": 5,\n   \"per_page\": 15,\n   \"current_page\": 1,\n   \"last_page\": 1,\n    \"__data\": \"k\",\n   \"data\": [\n     {\n       // 分类ID\n       \"id\": 1,\n       //分类名称\n       \"name\": \"日用品\",\n       //排序序号\n       \"sort\": 1,\n       //状态 1=启用 2=禁用\n       \"status\": 2,\n       //创建时间\n       \"create_time\": \"2021-03-08 12:26:04\",\n       //创建者\n       \"creator\": {\n           //创建者账号\n         \"username\": \"admin\",\n           //创建者角色\n         \"role_name\": \"超级管理员\"\n       },\n       // 商品分类图片\n       product_category_image: \"https://admin.bb.uninote.com.cn/oss?path=xxx3\"\n     }\n   ]\n }\n}\n",
  },
  'update': {
    '__proto__': base,
    'name': 'update',
    'desc': " 编辑商品分类",
    'url': '/scmsapi/product_category/update',
    'args': {
      name: {
        'pos': 0,
        'desc': "分类名称",
        'demo': '',
      },
      sort: {
        'pos': 1,
        'desc': "排序序号",
        'demo': '',
      },
      id: {
        'pos': 2,
        'desc': "分类ID",
        'demo': '',
      },
      product_category_image: {
        'pos': 3,
        'desc': "商品分类图片\n{\n     // 图片key,新增的图片必传[/upload/image接口上传图片后获得],原商品分类的图片可传空字符串\n     \"file_key\": \"xxx\",\n     // 图片可访问路径,新增的图片从[/upload/image接口上传图片后获得],原商品分类的图片从商品分类列表信息[/product_category/list]接口获得\n     \"url\": \"xxx\"\n },",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": null\n}\n",
  },
  'updateStatus': {
    '__proto__': base,
    'name': 'updateStatus',
    'desc': " 更新商品分类状态(启用|禁用|删除)",
    'url': '/scmsapi/product_category/updateStatus',
    'args': {
      status: {
        'pos': 0,
        'desc': "状态 1=启用 2=禁用 3=删除",
        'demo': '',
      },
      id: {
        'pos': 1,
        'desc': "分类ID",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": null\n}\n",
  }
};
