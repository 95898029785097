import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/scms/blindbox/order_box';
import * as apis from '../../../../api/scms/blindbox/order_box';
import {infos as clientOrderBox} from '@/powder/user/client/blindbox/order_box';
import * as utils from '@/utils/utils'
import { Message } from 'element-ui';

const geneDispatchIDs = async (ctx, gene = false) => {
  let info;
  let targetDispatchID;
  if (gene) {
    info = await clientOrderBox.dispatch.make();
    targetDispatchID = info.data.data.info[0];
  }
  info = await infos.combineList.make('args', utils.getParam('phone'));

  // 查找一个满足需求的发货请求
  let find = info.data.data.data.find((item) => {
    if (item.express_order_id === 0) {
      // 找到刚创建的那一个 dispatch (gene === true 时)
      if (targetDispatchID) {
        let find = item.dispatches.find((d) => {
          return d.id === targetDispatchID;
        });
        return find !== undefined;
      } else {
        return item;
      }
    }
    return false;
  });
  if (find) {
    let dispatches = find.dispatches;
    let ids = dispatches.map((d) => {
      return d.id;
    })
    ctx.generated = {
      dispatchIDs: ids,
    };
  } else {
    if (gene) {
      utils.assert(false, "没有找到发货请求");
    } else {
      Message.error("没有找到发货请求");
    }
  }
}

const geneExpressOrder = async(ctx, gene = false) => {
  let info;
  if (gene) {
    info = await infos.expressAddOrder.make('geneFirst');
  }
  info = await infos.combineList.make('args', utils.getParam('phone'));;

  let find = info.data.data.data.find((item) => {
    return item.express_order_id !== 0;
  });
  if (find) {
    ctx.generated = {
      orderIDs: [find.express_order_id],
    };
  } else {
    if (gene) {
      utils.assert(false, "没有找到运单号");
    } else {
      Message.error("没有找到运单号");
    }
  }
}

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取盲盒发货列表\n",
    'url': '/scmsapi/order_box/list',
    'defaults': {
      status: '',
      name: '',
      phone: '',
      wx_no: '',
      bag_name: '',
      has_print: '',
      start_time: '',
      end_time: '',
      page: '',
      page_size: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': '待发货列表',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            status: 1,
          };
        }
      },
      {
        'scene': '已发货列表',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            status: 2,
          };
        }
      },
    ],
  },

  'combineList': {
    '__proto__': base.combineList,
    'api': apis.combineList,
    'desc': "获取盲盒发货列表(合并)\n",
    'url': '/scmsapi/order_box/combineList',
    'defaults': {
      status: '',
      name: '',
      phone: '',
      wx_no: '',
      bag_name: '',
      has_print: '',
      start_time: '',
      end_time: '',
      page: '',
      page_size: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {
            page_size: 500, // 避免找不到所需类型数据
          };
        }
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, phone) => {
          ctx.generated = {
            page_size: 500, // 避免找不到所需类型数据
            phone,
          };
        }
      },
    ],
  },

  'increasePrintCount': {
    '__proto__': base.increasePrintCount,
    'api': apis.increasePrintCount,
    'desc': "增加发货单打印次数\n",
    'url': '/scmsapi/order_box/increasePrintCount',
    'defaults': {
      dispatchIDs: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          let info = await infos.list.make();
          ctx.generated.dispatchIDs = [info.data.data.data[0].id];
        },
      },
      {
        'scene': 'geneFirst',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let info = await clientOrderBox.dispatch.make();
          ctx.generated.dispatchIDs = info.data.data.info;
        },
        validate: async (ctx, info) => {
          utils.assert(info.data.data > 0, "increasePrintCount 更新行数应该大于0");
        },
      },
    ],
  },

  'expressAddOrder': {
    '__proto__': base.expressAddOrder,
    'api': apis.expressAddOrder,
    'desc': "物流生成运单\n",
    'url': '/scmsapi/order_box/expressAddOrder',
    'defaults': {
      dispatchIDs: [],
      delivery_id: utils.getParam('delivery_id', 'TEST'),
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          await geneDispatchIDs(ctx);
        }
      },
      {
        'scene': 'geneFirst',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await geneDispatchIDs(ctx, true);
        }
      },
    ],
  },

  'expressBindOrder': {
    '__proto__': base.expressBindOrder,
    'api': apis.expressBindOrder,
    'desc': "物流手动绑定运单\n",
    'url': '/scmsapi/order_box/expressBindOrder',
    'defaults': {
      dispatchIDs: [],
      express_number: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          await geneDispatchIDs(ctx);
          ctx.generated.express_number = utils.randomContent();
        }
      },
      {
        'scene': 'geneFirst',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await geneDispatchIDs(ctx, true);
          ctx.generated.express_number = utils.randomContent();
        }
      },
    ],
  },

  'expressCancelOrder': {
    '__proto__': base.expressCancelOrder,
    'api': apis.expressCancelOrder,
    'desc': "物流取消运单\n",
    'url': '/scmsapi/order_box/expressCancelOrder',
    'defaults': {
      orderIDs: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          await geneExpressOrder(ctx);
        }
      },
      {
        'scene': 'geneFirst',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await geneExpressOrder(ctx, true);
        },
        validate: async (ctx, info) => {
          utils.assert(info.data.data.errIDs.length === 0, "expressCancelOrder errIDs 应为空");
        },
      }
    ],
  },

  'doDispatch': {
    '__proto__': base.doDispatch,
    'api': apis.doDispatch,
    'desc': "卖家发货\n",
    'url': '/scmsapi/order_box/doDispatch',
    'defaults': {
      orderIDs: [],
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          await geneExpressOrder(ctx);
        }
      },
      {
        'scene': 'geneFirst',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await geneExpressOrder(ctx, true);
        },
        validate: async (ctx, info) => {
          utils.assert(info.data.data.success, "doDispatch 失败");
        },
      }
    ],
  },

  'expressOrderPath': {
    '__proto__': base.expressOrderPath,
    'api': apis.expressOrderPath,
    'desc': "查询运单轨迹\n",
    'url': '/scmsapi/order_box/expressOrderPath',
    'defaults': {
      orderID: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          await infos.expressTestUpdateOrder.make('all');
          ctx.generated = {
            orderID: infos.expressAddOrder.lastResult.data.data.orderID,
          };
        }
      },
    ],
  },

  'expressTestUpdateOrder': {
    '__proto__': base.expressTestUpdateOrder,
    'api': apis.expressTestUpdateOrder,
    'desc': "模拟更新订单状态\n",
    'url': '/scmsapi/order_box/expressTestUpdateOrder',
    'defaults': {
      order_id: '',
      waybill_id: '',
      action_time: 1685373353,
      action_type: 100001,
      action_msg: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': '揽件',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let s = 'geneFirst';
          const info = await infos.expressAddOrder.make(s);
          const res = info.data.data.wxRes;
          ctx.generated = {
            order_id: res.order_id,
            waybill_id: res.waybill_id,
            action_time: utils.timeNow(0),
            action_msg: '已揽件',
          };
        },
        validate: async (ctx, info) => {
          utils.assert(info.data.data.errcode === 0, "expressTestUpdateOrder wx errcode 应为0");
        },
      },
      {
        'scene': 'all',
        'desc': '',
        'type': GENE_ONLY,
        'func': async (ctx) => {
          let s = 'geneFirst';
          const info = await infos.expressAddOrder.make(s);
          const res = info.data.data.wxRes;
          ctx.generated = {
            order_id: res.order_id,
            waybill_id: res.waybill_id,
            action_time: utils.timeNow(0),
            action_msg: '已揽件',
          };
          await ctx.request2();

          ctx.generated.action_type = 200001;
          ctx.generated.action_msg = "更新轨迹: 达到成都";
          await ctx.request2();

          ctx.generated.action_type = 300003;
          ctx.generated.action_msg = "已签收，蜂巢";
          await ctx.request2();
        }
      },
    ],
  },

  'expressAccountBind': {
    '__proto__': base.expressAccountBind,
    'api': apis.expressAccountBind,
    'desc': "绑定、解绑 物流公司\n",
    'url': '/scmsapi/order_box/expressAccountBind',
    'defaults': {
      is_bind: true,
      biz_id: 'K81319333',
      delivery_id: 'YTO',
      password: '',
      remark_content: 'test remark',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressAccountAll': {
    '__proto__': base.expressAccountAll,
    'api': apis.expressAccountAll,
    'desc': "获取所有绑定的物流账号\n",
    'url': '/scmsapi/order_box/expressAccountAll',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressPrinterBind': {
    '__proto__': base.expressPrinterBind,
    'api': apis.expressPrinterBind,
    'desc': "配置面单打印员\n",
    'url': '/scmsapi/order_box/expressPrinterBind',
    'defaults': {
      is_bind: true,
      openid: 'oNeLR5FAnHZmjXBQOiZr4-JX-HFs',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressPrinterAll': {
    '__proto__': base.expressPrinterAll,
    'api': apis.expressPrinterAll,
    'desc': "获取所有打印员\n",
    'url': '/scmsapi/order_box/expressPrinterAll',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressRid': {
    '__proto__': base.expressRid,
    'api': apis.expressRid,
    'desc': "查询rid信息\n",
    'url': '/scmsapi/order_box/expressRid',
    'defaults': {
      rid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'orderBoxList': {
    '__proto__': base.orderBoxList,
    'api': apis.orderBoxList,
    'desc': "背包盲盒列表\n",
    'url': '/scmsapi/order_box/orderBoxList',
    'defaults': {
      page: '',
      page_size: '',
      id: '',
      order_no: '',
      wx_no: '',
      uid: '',
      bag_ids: '',
      bag_name: '',
      bag_status: '',
      bag_type: '',
      box_name: '',
      advanced: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
