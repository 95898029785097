import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/shop/coupon';
import * as apis from '../../../../api/client/shop/coupon';

export const infos = {

  'shopCouponList': {
    '__proto__': base.shopCouponList,
    'api': apis.shopCouponList,
    'desc': " [商城模块]我的优惠券",
    'url': '/api/shop-coupon/list',
    'defaults': {
      type: '',
      pageSize: '',
      page: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'shopCouponPick': {
    '__proto__': base.shopCouponPick,
    'api': apis.shopCouponPick,
    'desc': " [商城模块]领取优惠券",
    'url': '/api/shop-coupon/pick',
    'defaults': {
      id: '',
      code: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
