import { dajxHttp } from "../../utils/request";
const post = dajxHttp.postScms;

/**
 * @desc 获取 banner 列表
 * @param page 分页页码(默认1)
 * @param page_size 分页每页数据条数(默认10)
 * @param model_id 所属模块id
 * @param start_time 时间范围的开始时间(时间戳)
 * @param end_time 时间范围的结束时间(时间戳)
 * @param status 0 禁用 1 启用
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": {
    "total": 14,
    "per_page": 3,
    "current_page": 1,
    "last_page": 5,
    "data": [
      {
        "id": 17,
        "sort": 10,
        "img_src": "http://dev.admin.ugo.uninote.com.cn/oss?path=img/49bfa73e4a203b105fc2ebd299f33261.jpg",
        "jump_link": "{\"type\":0,\"id\":\"\"}",
        "start_time": 1683475200,
        "end_time": 1735488000,
        "status": 1,
        "__bannerModels": "k",
        "bannerModels": [
          {
            "id": 4,
            "pid": 0,
            "title": "首页"
          }
        ]
      }
    ]
  }
}
 */
export function list(page, page_size, model_id, start_time, end_time, status) {
  let params = {
    url: "/scmsapi/banner/list",
    data: {
      page,
      page_size,
      model_id,
      start_time,
      end_time,
      status
    }
  };
  return post(params);
}

/**
 * @desc 获取 banner model 列表
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": [
    {
      "id": 1,
      "title": "首页 - 顶部"
    }
  ]
}
 */
export function modelList() {
  let params = {
    url: "/scmsapi/banner/modelList",
    data: {}
  };
  return post(params);
}

/**
 * @desc 添加 banner
 * @param img_src * banner 图片 url
 * @param start_time 有效期开始时间(时间戳)
 * @param end_time 有效期结束时间(时间戳)
 * @param sort 排序，-100 ~ 100
 * @param status 0 禁用 1 启用
 * @param model_ids 模块 id 数组
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": {
    "banner_id": 43,
    "__bmr_ids": "k",
    "bmr_ids": [
      106
    ]
  }
}
 */
export function addBanner(img_src, start_time, end_time, sort, status, model_ids) {
  let params = {
    url: "/scmsapi/banner/add",
    data: { img_src, start_time, end_time, sort, status, model_ids }
  };
  return post(params);
}

/**
 * @desc 更新 banner，具体参数参见 add 接口
 * @param id
 * @param img_src
 * @param start_time
 * @param end_time
 * @param sort
 * @param model_ids
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": {
    "banner_id": 43,
    "__bmr_ids": "k",
    "bmr_ids": [
      106
    ]
  }
}
 */
export function updateBanner(id, img_src, start_time, end_time, sort, status, model_ids) {
  let params = {
    url: "/scmsapi/banner/update",
    data: { id, img_src, start_time, end_time, sort, status, model_ids }
  };
  return post(params);
}

/**
 * @desc 删除 banner
 * @param id
 * @returns {Promise<*>} {
  "code": 200,
  "msg": "success",
  "data": true
}
 */
export function delBanner(id) {
  let params = {
    url: "/scmsapi/banner/del",
    data: { id }
  };
  return post(params);
}
