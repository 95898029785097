/**
 * async validate using promise array
 */
export const avd = {
  // 异步验证 promise 数组
  _asyncValidations: [],

  // 添加异步验证
  addV(validator) {
    let promise = validator; // 最终放入数组的 promise
    if (typeof validator === 'function') {
      let _validator = validator; // 原始的或者封装后的 validator
      if (validator.constructor.name === "AsyncFunction") {
        // 当 promise 为异步函数时，如果函数内部异常，则 new Promise(promise) 得到的 promise 会永远处于 pending 状态
        // 需要封装一次，转为非 async 函数，处理异常
        _validator = (resolve, reject) => {
          // 这里可能存在两次调用 reject 的情况，但最终的值以第一次的调用为准
          validator(resolve, reject).catch((res) => {
            reject(res);
          });
        };
      }
      promise = new Promise(_validator);
    }
    else if (!(validator instanceof Promise)) {
      throw new TypeError('参数不是 Promise 或者回到函数: ' + validator);
    }
    this._asyncValidations.push(promise);
  },

  // 清空数组
  clearV() {
    this._asyncValidations = [];
  },

  // 开始检测异步验证结果，等待全部验证结束
  startV() {
    let startTime = Date.now();
    if (this._asyncValidations.length) {
      this.timerId = setInterval(() => {
        let waited = parseInt((Date.now() - startTime) / 1000);
        console.log('waited ' + waited + '(s) for validations', this._asyncValidations);
      }, 3000, this);
      Promise.all(this._asyncValidations).then(() => {
        console.log('异步验证全部结束，全部成功！', this._asyncValidations);
      }).catch(() => {
        console.error('异步验证全部结束，部分失败，请检查！', this._asyncValidations);
      }).finally(() => {
        clearInterval(this.timerId);
      });
    }
  },
};
