/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'create': {
    '__proto__': base,
    'name': 'create',
    'desc': " 创建活动分类",
    'url': '/scmsapi/promotion_category/create',
    'args': {
      name: {
        'pos': 0,
        'desc': "分类名称",
        'demo': '',
      },
      subtitle: {
        'pos': 1,
        'desc': "副标题",
        'demo': '',
      },
      sort: {
        'pos': 2,
        'desc': "排序序号",
        'demo': '',
      },
      status: {
        'pos': 3,
        'desc': "状态 状态 1=启用 2=禁用",
        'demo': '',
      },
      promotion_category_image: {
        'pos': 4,
        'desc': "分类图片\n{\n     // 图片key[/upload/image接口上传图片后获得]\n     \"file_key\": \"xxx\",\n     // 图片可访问路径[/upload/image接口上传图片后获得]\n     \"url\": \"xxx\"\n },",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"id\": 13\n }\n}\n",
  },
  'update': {
    '__proto__': base,
    'name': 'update',
    'desc': " 编辑促销活动分类",
    'url': '/scmsapi/promotion_category/update',
    'args': {
      id: {
        'pos': 0,
        'desc': "分类ID",
        'demo': '',
      },
      name: {
        'pos': 1,
        'desc': "分类名称",
        'demo': '',
      },
      subtitle: {
        'pos': 2,
        'desc': "副标题",
        'demo': '',
      },
      sort: {
        'pos': 3,
        'desc': "排序序号",
        'demo': '',
      },
      status: {
        'pos': 4,
        'desc': "状态 状态 1=启用 2=禁用",
        'demo': '',
      },
      promotion_category_image: {
        'pos': 5,
        'desc': "分类图片\n{\n     // 图片key[/upload/image接口上传图片后获得]\n     \"file_key\": \"xxx\",\n     // 图片可访问路径[/upload/image接口上传图片后获得]\n     \"url\": \"xxx\"\n },",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'del': {
    '__proto__': base,
    'name': 'del',
    'desc': " 删除促销活动分类",
    'url': '/scmsapi/promotion_category/del',
    'args': {
      id: {
        'pos': 0,
        'desc': "分类ID",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  },
  'list': {
    '__proto__': base,
    'name': 'list',
    'desc': " 获取促销活动分类列表",
    'url': '/scmsapi/promotion_category/list',
    'args': {
      id: {
        'pos': 0,
        'desc': "活动分类id",
        'demo': '',
      },
      name: {
        'pos': 1,
        'desc': "分类名称(模糊查询)",
        'demo': '',
      },
      page: {
        'pos': 2,
        'desc': "分页页码 默认1",
        'demo': '',
      },
      page_size: {
        'pos': 3,
        'desc': "分页每页数据条数 默认10",
        'demo': '',
      },
      status: {
        'pos': 4,
        'desc': "状态 1=显示 2=隐藏 不传此参数则查询全部",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": {\n   \"total\": 5,\n   \"per_page\": 15,\n   \"current_page\": 1,\n   \"last_page\": 1,\n    \"__data\": \"k\",\n   \"data\": [\n     {\n       // 分类ID\n       \"id\": 1,\n       //分类名称\n       \"name\": \"日用品\",\n       //排序序号\n       \"sort\": 1,\n       //状态 1=显示 2=隐藏\n       \"status\": 2,\n       //操作时间\n       \"update_time\": \"2021-03-08 12:26:04\",\n       // 分类图片\n       \"product_promotion_category_image\": \"https://admin.bb.uninote.com.cn/oss?path=xxx3\"\n     }\n   ]\n }\n}\n",
  }
};
