import { dajxHttp } from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc 更新福袋状态
 *
 * @returns {Promise<*>} {
  "code": 1,
  "message": "success!",
  "data": {
    "__info": "k",
    "info": [
      111
    ]
  }
}
 */
export function updateBagStatus() {
  let params = {
    url: "/api/blindbox/job/update-bag-status",
    data: {
    }
  };
  return post(params);
}
