import {dajxHttp} from "../../../utils/request";
const post = dajxHttp.post;

/**
 * @desc  确认订单
 * @param type 类型 1:来源于购物车 2:来源于立即购买
 * @param sku_list 商品SKU
 * @param address_id 地址 id，自取传 0
 * @param source_id 推广渠道 ID，默认 0，正常渠道
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 购物车选择的商品列表(具体返回参数请直接查看/cart/list接口,两边数据完全一致)
      "cart_list": [
        {
          "product": {
            "id": "12049",
            "title": "20201204测试商品",
            "unit": "个",
            "status": "1",
            "delete_time": "0"
          },
          "product_sku_list": [
            {
              "id": "3758",
              "product_spec": [
                {
                  "product_spec_key": {
                    "id": 519,
                    "title": "重量"
                  },
                  "product_spec_value": {
                    "id": 949,
                    "value": "1KG"
                  }
                }
              ],
              "status": "1",
              "price": "1",
              "cart_sku_quantity": "1"
            }
          ],
          "productImages": {
            "type": "1",
            "url": "https://admin.bb.uninote.com.cn/oss?path=xxx"
          },
          "__productPromotion": 'n',
          "productPromotion": {
          "id": "5", // 促销活动ID
          "start_time": "1605715800",
          "end_time": "1605715861",
          "__productPromotionSku": 'i',
          "productPromotionSku": [{
          "product_sku_id": "3758",
          "promotion_price": "1",
          "promotion_number": "10", // 促销数量
          "used_number": "0" // 实际销售数量
          }]
          }
        }
      ],
      // 优惠券列表(具体返回参数请直接查看/shop-coupon/list接口,两边数据完全一致)
      "__coupon_list": "i",
      "coupon_list": [],
      // 商品运费列表，包含多个对象，每个对象字段如展开所示
      "__product_freight_list": "i",
      "product_freight_list": [
        {
          // 商品ID
          "product_id": "12049",
          "product_freight": [
            {
              // 商品运费金额
              "amount": "1",
              // 省份城市ID数组
              "area_id": [
                "1"
              ]
            }
          ]
        }
      ],
      // 默认收货地址(具体返回参数请直接查看/address/default接口,两边数据完全一致)
      "__address":"i",
      "address": {
        "id": "524",
        "uid": "10022",
        "name": "黄翠刚",
        "adCode": "510100",
        "desc": "成都市武侯区天府大道北段1719号，新世纪环球购物中心asdfasfas",
        "tel": "18111619306",
        "province": "四川省",
        "city": "成都市",
        "is_default": "1",
        "lng": "104.062272",
        "lat": "30.569725",
        "province_city_id": "360",
        "province_city_key": "510000"
      }
    }
  }
}
 */
export async function confirm(type,sku_list,address_id,source_id) {
  return await post({
    url: '/api/shop-order/confirm',
    data: {type,sku_list,address_id,source_id}
  });
}

/**
 * @desc  提交订单(创建订单)
 * @param type 类型 1:来源于购物车 2:来源于立即购买
 * @param sku_list 商品SKU
 * @param address_id 收货地址ID
 * @param coupon 使用的优惠券 参数示例值[{"product_id":120,"coupon_id":1}]
 * @param source_id 渠道ID
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 主订单ID
      "id": 51,
      // 主订单编号
      "order_no": "P1054287897",
      // 子订单编号(主要是powder使用)
      "children_order_no": [
        "S1080825516"
      ],
      /// 子订单ID(主要是powder使用)(与子订单编号的顺序是一一对应的)
      "children_order_id": [
        396
      ],
      // 主订单金额
      "amount": 1,
      // 主订单实际支付金额
      "pay_amount": 1
    }
  }
}
 */
export async function create(type,sku_list,address_id,coupon,source_id) {
  return await post({
    url: '/api/shop-order/create',
    data: {type,sku_list,address_id,coupon,source_id}
  });
}

/**
 * @desc  支付订单
 * @param order_no 订单编号(主订单编号|子订单编号)
 * @param pay_type 状态:0=微信支付 1=支付宝 2=微信小程序 3:微信H5支付 4:微信公众号支付
 * @param wx_code 微信网页授权code(支付方式为微信公众号支付必传,其他支付方式可以不传)
 * @param wx_return_url 微信H5支付回调地址(本参数仅在支付方式为微信H5支付时有效,其他支付方式可以不传)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 订单价格是否改变
      "is_price_change": false,
      // 订单编号(价格改变后变化)
      "order_no": "P1085803086",
      // 订单支付金额
      "pay_amount": "1",
      // 支付参数
      "__pay_info": "i",
      "pay_info": {
        "appid": "wx1288370bcddf64a6",
        "partnerid": "1561352391",
        "prepayid": "wx071723074162042b33102e23ffc93c0000",
        "timestamp": "1607332987",
        "noncestr": "i5B6NsCDqXWu23wd",
        "package": "Sign=WXPay",
        "sign": "CAC7B52AE528B9CAE9E350F5CF76E830"
      }
    }
  }
}
 */
export async function pay(order_no,pay_type,wx_code, wx_return_url) {
  return await post({
    url: '/api/shop-order/pay',
    data: {order_no,pay_type,wx_code, wx_return_url}
  });
}

/**
 * @desc  订单列表
 * @param type 订单类型 默认0-全部 1-待付款 2-待收货 3-已完成 4-已取消
 * @param page 分页页码 默认1
 * @param pageSize 分页每页数据条数 默认10
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "totalPage": 2,
    "currentPage": 1,
    "info": [
      {
        // 子订单编号
        "order_no": "S1040048881",
        // 订单总价(包含运费)
        "amount": 1,
        // 子订单实际支付金额
        "pay_amount": "1",
        // 订单状态  0=未发货  1=已发货 2=已完成
        "status": "0",
        // 支付状态: 0=未付款  1=已付款  2=退款中  3=已退款  4=已取消
        "pay_status": "0",
        // 商品状态 1=上架 2=下架
        "product_status": "0",
        // 订单商品，包含多个对象，每个对象字段如展开所示
        "orderProduct": [
          {
            // shu价格
            "product_sku_price": "1",
            // 商品SKUID
            "product_sku_id": "3758",
            // 数量
            "quantity": "1",
            // 退款订单商品信息(不为空时,说明当前商品有退款记录)
            "__refund_order_product_info":  "i",
            "refund_order_product_info":  {
              // 退款订单状态 0=待审核(已提交) 1=审核通过
              "status": "0",
              // 退款编号
              "refund_no": "R1059171371"
            },
            // 图片
            "product_images_url": "https://admin.bb.uninote.com.cn/oss?path=xxx",
            // 商品SKU的规格,，包含多个对象，每个对象字段如展开所示
            "product_spec": [
              {
                // 规格名称对象
                "product_spec_key": {
                  // 规格名称ID
                  "id": 519,
                  // 规格名称
                  "title": "重量"
                },
                // 规格种类对象
                "product_spec_value": {
                  // 规格种类ID
                  "id": 949,
                  // 规格种类
                  "value": "1KG"
                }
              }
            ]
          }
        ],
        // 优惠金额
        "discount_amount": 2,
        // 订单商品数
        "order_product_sum": 1,
        // 商品名称
        "product_title": "20201204测试商品"
      }
    ]
  }
}
 */
export async function list(type,page,pageSize) {
  return await post({
    url: '/api/shop-order/list',
    data: {type,page,pageSize}
  });
}

/**
 * @desc  取消订单
 * @param order_no 订单编号(子订单编号)
 * @param cancel_reason 取消原因
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": []
  }
}
 */
export async function cancel(order_no,cancel_reason) {
  return await post({
    url: '/api/shop-order/cancel',
    data: {order_no,cancel_reason}
  });
}

/**
 * @desc  确认收货
 * @param order_no 订单编号(子订单编号)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": []
  }
}
 */
export async function confirmReceipt(order_no) {
  return await post({
    url: '/api/shop-order/confirm-receipt',
    data: {order_no}
  });
}

/**
 * @desc  获取订单退款页面聚合数据
 * @param order_no 订单编号(子订单编号)
 * @param product_sku_ids 商品sku数组(示例值:[1,2,3])
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "product_title": "20201204测试商品",
      "order_product": [
        {
          "id": "89",
          "product_sku_price": "1",
          "product_sku_id": "3758",
          "product_promotion_id": "0",
          "quantity": "1",
          "product_images_url": "https://admin.bb.uninote.com.cn/oss?path=xxx",
          "product_spec": [
            {
              "product_spec_key": {
                "id": 519,
                "title": "重量"
              },
              "product_spec_value": {
                "id": 949,
                "value": "1KG"
              }
            }
          ]
        }
      ],
      // 本次退款后子订单是否已经全部退款
      "is_all_refund": true,
      "refund_amount": 1,
      // 退款需退回用户账户的优惠券
      "__refund_coupon": "i",
      "refund_coupon": []
    }
  }
}
 */
export async function getRefund(order_no,product_sku_ids) {
  return await post({
    url: '/api/shop-order/get-refund',
    data: {order_no,product_sku_ids}
  });
}

/**
 * @desc  提交订单退款
 * @param order_no 订单编号(子订单编号)
 * @param product_sku_ids 商品sku数组(示例值:[1,2,3])
 * @param refund_reason 退款原因
 * @param refund_explain 退款说明
 * @param refund_images 退款凭证图片数组(示例值:[{file_key:'xxx',url:'xxx'}])
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      "id": 9
    }
  }
}
 */
export async function refund(order_no,product_sku_ids,refund_reason,refund_explain,refund_images) {
  return await post({
    url: '/api/shop-order/refund',
    data: {order_no,product_sku_ids,refund_reason,refund_explain,refund_images}
  });
}

/**
 * @desc  订单详情
 * @param order_no 订单编号(子订单编号)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 子订单编号
      "order_no": "S1037172260",
      // 子订单金额(商品总金额,未累计运费金额)
      "amount": "1",
      // 子订单实际支付金额
      "pay_amount": "1",
      // 子订单运费金额
      "freight_amount": "0",
      // 订单状态 0=未发货 1=已发货 2=已完成
      "status": "1",
      // 	支付状态: 0=未付款 1=已付款 2=退款中 3=已退款 4=已取消
      "pay_status": "2",
      // 订单创建时间
      "create_time": "1607420146",
      // 	订单商品数组
      "orderProduct": [
        {
          "product_sku_price": "1",
          "product_sku_id": "3758",
          // 订单商品数量
          "quantity": "1",
          // 退款订单商品信息(不为空时,说明当前商品有退款记录)
          "refund_order_product_info": {
            // 退款订单状态 0=待审核(已提交) 1=审核通过
            "status": "0",
            // 退款编号
            "refund_no": "R1072379260"
          },
          "product_images_url": "https://admin.bb.uninote.com.cn/oss?path=xxx",
          "product_spec": [
            {
              "product_spec_key": {
                "id": 519,
                "title": "重量"
              },
              "product_spec_value": {
                "id": 949,
                "value": "1KG"
              }
            }
          ]
        }
      ],
      // 订单收货地址
      "order_address": {
        "order_id": "116",
        "name": "黄翠刚",
        "province": "四川省",
        "city": "成都市",
        "area": "武侯区",
        "desc": "成都市武侯区天府大道北段1719号，新世纪环球购物中心asdfasfas",
        "tel": "18111619306"
      },
      // 订单使用的优惠券信息
      "__coupon_info": "i",
      "coupon_info": [],
      // 商品名称
      "product_title": "20201204测试商品",
      // 	取消/退款进度的最新一条(有可能为空)
      "__cancel_progress": "i",
      "cancel_progress": {
        "progress": "申请已提交",
        "description": "您的退款申请已提交",
        "create_time": "1607420293"
      }
    }
  }
}
 */
export async function info(order_no) {
  return await post({
    url: '/api/shop-order/info',
    data: {order_no}
  });
}

/**
 * @desc  子订单的退款订单列表
 * @param order_no 订单编号(子订单编号)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 子订单编号
      "order_no": "S1037172260",
      // 	退款订单列表
      "list": [
        {
          // 退款编号
          "refund_no": "R1072379260",
          // 退款订单的商品
          "orderProduct": [
            {
              "product_sku_price": "1",
              "quantity": "1",
              "product_images_url": "https://admin.bb.uninote.com.cn/oss?path=xxx",
              "product_spec": [
                {
                  "product_spec_key": {
                    "id": 519,
                    "title": "重量"
                  },
                  "product_spec_value": {
                    "id": 949,
                    "value": "1KG"
                  }
                }
              ]
            }
          ]
        }
      ],
      // 总计退款商品数量
      "quantity": 1,
      // 取消/退款进度的最新一条(有可能为空)
      "cancel_progress": {
        "progress": "申请已提交",
        "description": "您的退款申请已提交",
        "create_time": "1607420293"
      },
      // 商品名称
      "product_title": "20201204测试商品"
    }
  }
}
 */
export async function refundList(order_no) {
  return await post({
    url: '/api/shop-order/refund-list',
    data: {order_no}
  });
}

/**
 * @desc  取消/退款进度详情
 * @param order_no 订单编号(子订单编号)
 * @param refund_no 退款编号
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 子订单编号
      "order_no": "S1037172260",
      // 	取消/退款进度列表
      "cancel_progress": [
        {
          "progress": "申请已提交",
          "description": "您的退款申请已提交",
          "create_time": "1607420293"
        }
      ],
      // 总计退款商品数量
      "quantity": 1,
      // 退款明细(退款编号不为空时返回本字段,否则返回空)
      "__order_refund": "i",
      "order_refund": {
        // 退款订单状态 0=待审核(已提交) 1=审核通过 2=审核拒绝
        "status": "0",
        // 退款金额
        "amount": "2",
        // 支付方式
        "pay_type": "微信",
        // 退款账户信息(本字段目前只有微信有返回内容,其他支付方式时返回空字符串)(本子段只有退款订单状态为审核通过时才返回)
        "refund_recv_accout": "支付用户零钱",
        // 到账时间(本子段只有退款订单状态为审核通过时才返回)
        "success_time": 1608867115
      }
    }
  }
}
 */
export async function cancelProgressInfo(order_no, refund_no) {
  return await post({
    url: '/api/shop-order/cancel-progress-info',
    data: {order_no, refund_no}
  });
}

/**
 * @desc  查看物流
 * @param order_no 订单编号(子订单编号)
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 物流编号
      "express_number": "V030344422",
      // 	物流信息
      "__express": "i",
      "express": {
        // 	物流内容
        "context": [
          {
            "context": "上海分拨中心/下车扫描 ",
            "time": 1346080962,
            "status": "测试",
            "areaName": "上海市"
          }
        ],
        // 物流公司名称
        "express_company_name": "京东物流"
      },
      // 	收货地址
      "order_address": {
        "name": "黄翠刚",
        "province": "四川省",
        "city": "成都市",
        "area": "武侯区",
        "desc": "成都市武侯区天府大道北段1719号，新世纪环球购物中心asdfasfas",
        "tel": "18111619306"
      }
    }
  }
}
 */
export async function expressInfo(order_no) {
  return await post({
    url: '/api/shop-order/express-info',
    data: {order_no}
  });
}
