import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/promotion_category';
import * as apis from '../../../api/scms/promotion_category';
import {infos as admin} from "./admin";

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': " 创建活动分类",
    'url': '/scmsapi/promotion_category/create',
    'defaults': {
      name: '测试',
      subtitle: 'xxx',
      sort: '1',
      status: '1',
      promotion_category_image: {},
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            promotion_category_image: (await admin.uploadImage.make()).data.data[0]
          };
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': " 编辑促销活动分类",
    'url': '/scmsapi/promotion_category/update',
    'defaults': {
      id: '',
      name: '测试2',
      subtitle: 'xxx2',
      sort: '1',
      status: '1',
      promotion_category_image: {},
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
         ctx.generated = {
            id:(await infos.create.make()).data.data.id,
            promotion_category_image: (await admin.uploadImage.make()).data.data[0]
          };
        },
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': " 删除促销活动分类",
    'url': '/scmsapi/promotion_category/del',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id:(await infos.create.make()).data.data.id
          };
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': " 获取促销活动分类列表",
    'url': '/scmsapi/promotion_category/list',
    'defaults': {
      id: '',
      name: '',
      page: '1',
      page_size: '10',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
