import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/coupon';
import * as apis from '../../../api/scms/coupon'
import * as utils from '../../../utils/utils';
import * as scms from '../../../tests/scms';

// 公共缓存数据
let scmsCommon;

// 异步初始化接口
export const __init = async(force = false) => {
  scmsCommon = await scms.scmsCommon(force);
};

// 获取最新一个优惠券 ID
async function getOneID() {
  let data = (await infos.index.make()).data.data;
  return data.data[0].id;
}

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': "创建优惠券",
    'url': '/scmsapi/coupon/create',
    'defaults': {
      type: 1,
      nature: '10',
      product_ids: [],
      num: 20,
      pick_start_time: 0,
      pick_end_time: 0,
      use_start_time: 0,
      use_end_time: 0,
      is_use: 1,
      is_pick: 1,
      remarks: '',
      is_child: '1',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            product_ids: scmsCommon.productIDs,
            pick_start_time: utils.timeNow(-10), // 避免不在领取时间问题
            pick_end_time: utils.timeNow(3600*24),
            use_start_time: utils.timeNow(-10),
            use_end_time: utils.timeNow(3600*24),
          };
        },
        'validate': (ctx, info) => {
        },
        'testSuites': [
          {
            'name': 'suc',
            'validate': null,
            'tests': [
              (ctx) => {
                ctx.generated.is_pick = 1;
              },
              (ctx) => {
                ctx.generated.type = 2;
                ctx.generated.nature = '3|3';
              },
            ],
          },
          {
            'name': 'fail',
            'validate': (ctx, info) => {
              utils.assert(info.data.code === 10000, '参数验证失败');
            },
            'tests': [
              (ctx) => {
                ctx.generated.product_ids = [];
              },
              (ctx) => {
                ctx.generated.is_pick = 2;
              },
              (ctx) => {
                ctx.generated.type = 2;
                ctx.generated.nature = '3|4';
              },
            ]
          }
        ],
      },
    ],
  },

  'getOne': {
    '__proto__': base.getOne,
    'api': apis.getOne,
    'desc': "获取优惠券信息",
    'url': '/scmsapi/coupon/getOne',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'before': (ctx, info) => {
      // console.debug('top level before', ctx, info);
    },
    'after': (ctx, info) => {
      // console.debug('top level after', ctx, info);
    },    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(),
          };
        },
        'validate': (ctx, info) => {
        },
        'before': (ctx, info) => {
          ctx.before(ctx, info);
          // console.debug('gene level before', ctx, info);
        },
        'after': (ctx, info) => {
          ctx.after(ctx, info);
          // console.debug('gene level after', ctx, info);
        },
        'testSuites': [
          {
            'name': 'fail',
            'validate': (ctx, info) => {
              utils.assert(info.data.code === 10000, '参数验证失败');
            },
            'tests': [
              // (ctx) => {
              //   ctx.generated.id = 'xx';
              // },
              (ctx) => {
                ctx.generated.id = 10000000000;
                return (ctx, info) => {
                  utils.assert(info.data.code === 40400, '优惠券不存在验证失败');
                }
              },
            ]
          }
        ],
      },
    ],
  },

  'index': {
    '__proto__': base.index,
    'api': apis.index,
    'desc': "优惠券列表",
    'url': '/scmsapi/coupon/index',
    'defaults': {
      id: '',
      type: '',
      code: '',
      is_use: '',
      is_pick: '',
      page: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
          };
        },
        'validate': (ctx, info) => {
          ctx.__info = info;
        },
        'testSuites': [
          {
            'name': 'suc',
            'tests': [
              (ctx) => {
                ctx.generated.type = '1';
              },
              (ctx) => {
                ctx.generated.code = ctx.__info.data.data.data[0].code;
              },
              (ctx) => {
                ctx.generated.is_use = '0';
              },
              (ctx) => {
                ctx.generated.code = 'xxxx';
                return (ctx, info) => {
                  // 返回空数组
                  utils.assert(info.data.code === 200, 'index coupon 失败');
                };
              },
            ]
          },
          {
            'name': 'fail',
            'validate': (ctx, info) => {
              utils.assert(info.data.code === 10000, '参数验证失败');
            },
            'tests': [
              (ctx) => {
                ctx.generated.type = '0';
              },
              (ctx) => {
                ctx.generated.is_use = '2';
              },
              (ctx) => {
                ctx.generated.is_pick = '2';
              },
            ]
          }
        ],
      },
    ],
  },

  'couponExport': {
    '__proto__': base.couponExport,
    'api': apis.couponExport,
    'desc': "导出优惠券列表(本接口请采用get请求，请求参数与/scmsapi/coupon/index接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)",
    'url': '/scmsapi/coupon/export',
    'defaults': {
      type: '',
      code: '',
      is_use: '',
      is_pick: '',
      export_num: '2',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'children': {
    '__proto__': base.children,
    'api': apis.children,
    'desc': "子码列表",
    'url': '/scmsapi/coupon/children',
    'defaults': {
      id: '',
      code: '',
      username: '',
      status: '',
      is_pick: '',
      page: '',
    },
    "nullList": ["data.data.0.user"],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(),
          };
        },
        'validate': (ctx, info) => {
        },
        'testSuites': [
          {
            'name': 'suc',
            'tests': [
              (ctx) => {
                ctx.generated.code = '';
              },
              (ctx) => {
                ctx.generated.xx = '';
                return (ctx, info) => {
                  utils.assert(info.data.code === 200, 'index coupon 失败');
                };
              },
            ]
          },
          {
            'name': 'fail',
            'validate': (ctx, info) => {
              utils.assert(info.data.code === 10000, '参数验证失败');
            },
            'tests': [
              (ctx) => {
                ctx.generated.status = '0';
              },
              (ctx) => {
                ctx.generated.is_pick = '0';
              },
            ]
          }
        ],
      },
    ],
  },
  'couponChildrenExport': {
    '__proto__': base.couponChildrenExport,
    'api': apis.couponChildrenExport,
    'desc': "导出优惠券子码列表(本接口请采用get请求，请求参数与/scmsapi/coupon/children接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)",
    'url': '/scmsapi/coupon/childrenExport',
    'defaults': {
      id: '',
      code: '',
      username: '',
      status: '',
      is_pick: '',
      export_num: '2',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(),
          };
        }
      },
    ],
  },
  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': "优惠券更新",
    'url': '/scmsapi/coupon/update',
    'defaults': {
      id: 0,
      product_ids: [],
      num: 0,
      nature: '',
      type: '',
      pick_start_time: '',
      pick_end_time: '',
      use_start_time: '',
      use_end_time: '',
      is_use: 0,
      is_pick: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let id = ctx.user.id;
          let data;
          // 如果用户填写了 id，则直接调用 api，注意这样没有返回值 validate
          if (id) {
            data = (await infos.getOne.api(id)).data.data;
          } else {
            data = (await infos.getOne.make()).data.data;
          }
          ctx.generated = {
            ...data,
            product_ids: data.product.map(item => item.id),
          };
        }
      },
    ],
  },

  'del': {
    '__proto__': base.del,
    'api': apis.del,
    'desc': "删除优惠券",
    'url': '/scmsapi/coupon/delete',
    'defaults': {
      id: 0,
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(),
          };
        },
        'validate': (ctx, info) => {
        },
        'testSuites': [
          {
            'name': 'fail',
            'validate': (ctx, info) => {
              utils.assert(info.data.code === 10000, '参数验证失败');
            },
            'tests': [
              // (ctx) => {
              //   ctx.generated.id = 'aaa';
              // },
            ]
          }
        ],
      },
    ],
  }
};
