import * as admin from "../powder/user/scms/admin";
import * as product from "../powder/user/scms/product";
import * as utils from "../utils/utils";

/**
 * scms 接口公共数据，默认使用缓存
 * @param noCache
 * @return {Promise<*>}
 */
export const scmsCommon = async (noCache = false) => {
  let scmsCommon;
  if (!noCache && ((scmsCommon = utils.getLSItem('scmsCommon')))) {
    console.log(scmsCommon);
    return scmsCommon;
  }

  let supplier = (await admin.infos.adminGetSupplierList.make()).data.data[0].id;
  let info = await product.infos.productList.make();
  let productIDs = info.data.data.data.map(item => item.id);
  scmsCommon = {
    supplier,
    productIDs,
  };
  utils.setLSItem('scmsCommon', scmsCommon);
  return scmsCommon
};
