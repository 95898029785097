import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/blindbox/bag';
import * as apis from '../../../../api/client/blindbox/bag';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "福袋列表\n",
    'url': '/api/blindbox/bag/list',
    'defaults': {
      page: '',
      page_size: '',
      type: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'luckyBagInfo': {
    '__proto__': base.luckyBagInfo,
    'api': apis.luckyBagInfo,
    'desc': "常规福袋详情",
    'url': '/api/blindbox/bag/lucky-bag-info',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
