/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'getRoles': {
    '__proto__': base,
    'name': 'getRoles',
    'desc': "所有角色",
    'url': '/scmsapi/role/getRoles',
    'args': {

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": [{\n       \"id\": 0, // 角色id,\n       \"title\": \"供货商\",\n       \"mark\": \"supplier_mark\" // 唯一标记\n   }]\n}\n",
  },
  'menu': {
    '__proto__': base,
    'name': 'menu',
    'desc': "角色对应菜单",
    'url': '/scmsapi/role/menu',
    'args': {
      id: {
        'pos': 0,
        'desc': "角色id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n   \"code\": 200,\n   \"msg\": \"success\",\n   \"data\": [{\n       \"id\": 1,\n       \"title\": \"菜单名\",\n       \"mark\": \"菜单标记\",\n       \"checked\": true, // 对应角色菜单是否被选中 true=选中 false=未选中\n   }]\n}\n",
  },
  'setMenu': {
    '__proto__': base,
    'name': 'setMenu',
    'desc': "开关角色对应菜单",
    'url': '/scmsapi/role/setMenu',
    'args': {
      role_id: {
        'pos': 0,
        'desc': "角色id",
        'demo': '',
      },
      menu_id: {
        'pos': 1,
        'desc': "菜单id",
        'demo': '',
      },

    },
    'returns': "{Promise<*>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": []\n}\n",
  }
};
