import * as apicms from "../api/cms";
import * as utils from '../utils/utils'
import {infos as infoscms} from "../powder/user/cms"; 

let serviceLast

const getServiceInfo = async () => {
  let info = await infoscms.serviceList.make();
  let serviceData = info.data.data[0];
  utils.setLSItem('serviceLast', serviceData);
  return serviceData;
};

// 返回上一次下单缓存的对象，如果没有，则新建一个
const getLastService = async () => {
  if (serviceLast || (serviceLast = utils.getLSItem('serviceLast'))) {
    //console.log(serviceLast);
    return serviceLast;
  }
  serviceLast = (await getServiceInfo());
  return serviceLast;
};

const getServiceSubOrderSpecsInfo = async (serviceId) => {
  if (!serviceId) {
    let service = await getLastService();
    serviceId = service.id
  }
  let serviceSubOrderSpecsData = await apicms.getServiceSubOrderSpecs(serviceId);
  return serviceSubOrderSpecsData.data.data;
};

const addServiceSubOrderSpecs = async (serviceId) => {
  if (!serviceId) {
    let service = await getLastService();
    serviceId = service.id
  }
  await delAllServiceSubOrderSpecs(serviceId);
  await infoscms.editServiceSubOrderSpecs.make();
  let serviceSubOrderSpecsData = await getServiceSubOrderSpecsInfo(serviceId);
  return serviceSubOrderSpecsData;
};

const delAllServiceSubOrderSpecs = async (serviceId) => {
  let serviceSubOrderSpecsData = await getServiceSubOrderSpecsInfo(serviceId);
  for (let i = 0; i < serviceSubOrderSpecsData.length; i++) {
    let item = serviceSubOrderSpecsData[i];
    await apicms.delServiceSubOrderSpecs(item.id);
  }
};

export {
  getServiceInfo, getLastService, getServiceSubOrderSpecsInfo, addServiceSubOrderSpecs, delAllServiceSubOrderSpecs
}