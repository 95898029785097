import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/shop/order';
import * as apis from '../../../../api/client/shop/order';

export const infos = {

  'confirm': {
    '__proto__': base.confirm,
    'api': apis.confirm,
    'desc': " 确认订单",
    'url': '/api/shop-order/confirm',
    'defaults': {
      type: '',
      sku_list: '',
      address_id: '',
      source_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': " 提交订单(创建订单)",
    'url': '/api/shop-order/create',
    'defaults': {
      type: '',
      sku_list: '',
      address_id: '',
      coupon: '',
      source_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'pay': {
    '__proto__': base.pay,
    'api': apis.pay,
    'desc': " 支付订单",
    'url': '/api/shop-order/pay',
    'defaults': {
      order_no: '',
      pay_type: '',
      wx_code: '',
      wx_return_url: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': " 订单列表",
    'url': '/api/shop-order/list',
    'defaults': {
      type: '',
      page: '',
      pageSize: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'cancel': {
    '__proto__': base.cancel,
    'api': apis.cancel,
    'desc': " 取消订单",
    'url': '/api/shop-order/cancel',
    'defaults': {
      order_no: '',
      cancel_reason: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'confirmReceipt': {
    '__proto__': base.confirmReceipt,
    'api': apis.confirmReceipt,
    'desc': " 确认收货",
    'url': '/api/shop-order/confirm-receipt',
    'defaults': {
      order_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getRefund': {
    '__proto__': base.getRefund,
    'api': apis.getRefund,
    'desc': " 获取订单退款页面聚合数据",
    'url': '/api/shop-order/get-refund',
    'defaults': {
      order_no: '',
      product_sku_ids: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'refund': {
    '__proto__': base.refund,
    'api': apis.refund,
    'desc': " 提交订单退款",
    'url': '/api/shop-order/refund',
    'defaults': {
      order_no: '',
      product_sku_ids: '',
      refund_reason: '',
      refund_explain: '',
      refund_images: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'info': {
    '__proto__': base.info,
    'api': apis.info,
    'desc': " 订单详情",
    'url': '/api/shop-order/info',
    'defaults': {
      order_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'refundList': {
    '__proto__': base.refundList,
    'api': apis.refundList,
    'desc': " 子订单的退款订单列表",
    'url': '/api/shop-order/refund-list',
    'defaults': {
      order_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'cancelProgressInfo': {
    '__proto__': base.cancelProgressInfo,
    'api': apis.cancelProgressInfo,
    'desc': " 取消/退款进度详情",
    'url': '/api/shop-order/cancel-progress-info',
    'defaults': {
      order_no: '',
      refund_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressInfo': {
    '__proto__': base.expressInfo,
    'api': apis.expressInfo,
    'desc': " 查看物流",
    'url': '/api/shop-order/express-info',
    'defaults': {
      order_no: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
