import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/product_category';
import * as apis from '../../../api/scms/product_category';

export const infos = {

  'create': {
    '__proto__': base.create,
    'api': apis.create,
    'desc': " 创建商品分类",
    'url': '/scmsapi/product_category/create',
    'defaults': {
      name: '',
      sort: '',
      product_category_image: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': " 获取商品分类列表",
    'url': '/scmsapi/product_category/list',
    'defaults': {
      id: '',
      name: '',
      page: '',
      page_size: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'update': {
    '__proto__': base.update,
    'api': apis.update,
    'desc': " 编辑商品分类",
    'url': '/scmsapi/product_category/update',
    'defaults': {
      name: '',
      sort: '',
      id: '',
      product_category_image: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updateStatus': {
    '__proto__': base.updateStatus,
    'api': apis.updateStatus,
    'desc': " 更新商品分类状态(启用|禁用|删除)",
    'url': '/scmsapi/product_category/updateStatus',
    'defaults': {
      status: '',
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
