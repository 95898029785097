/** ======================================= 注意，此文件为自动生成，不要手动修改！！================================ **/
import {base} from '../../base';

export const infos = {
  'cityGetProvinceList': {
    '__proto__': base,
    'name': 'cityGetProvinceList',
    'desc': " 获取省份列表",
    'url': '/scmsapi/city/getProvinceList',
    'args': {

    },
    'returns': "{Promise<unknown>}\n{\n \"code\": 200,\n \"msg\": \"success\",\n \"data\": [\n   {\n     // 省份ID\n     \"id\": 334,\n     // 省份名称\n     \"city\": \"上海市\"\n   }\n ]\n}\n",
  }
};
