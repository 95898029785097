import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/scms/blindbox/order';
import * as apis from '../../../../api/scms/blindbox/order';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "福袋订单列表\n",
    'url': '/scmsapi/blindbox/order/list',
    'defaults': {
      page: '',
      page_size: '',
      id: '',
      order_no: '',
      wx_no: '',
      uid: '',
      bag_ids: '',
      pay_status: '',
      bag_name: '',
      bag_status: '',
      bag_type: '',
      start_time: '',
      end_time: '',
      advanced: '',
      is_export: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'listGroupByUser': {
    '__proto__': base.listGroupByUser,
    'api': apis.listGroupByUser,
    'desc': "福袋订单列表-按用户分组\n",
    'url': '/scmsapi/blindbox/order/listGroupByUser',
    'defaults': {
      page: '',
      page_size: '',
      wx_no: '',
      uid: '',
      bag_ids: '',
      pay_status: '',
      start_time: '',
      end_time: '',
      advanced: '',
      is_export: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'listGroupByDate': {
    '__proto__': base.listGroupByDate,
    'api': apis.listGroupByDate,
    'desc': "福袋订单列表-按日期分组\n",
    'url': '/scmsapi/blindbox/order/listGroupByDate',
    'defaults': {
      page: '',
      page_size: '',
      wx_no: '',
      uid: '',
      bag_ids: '',
      pay_status: '',
      start_time: '',
      end_time: '',
      advanced: '',
      is_export: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
