import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/product';
import * as apis from '../../../api/scms/product';
import * as shop from "../../../tests/shop";
import * as utils_product_category  from '@/utils/product_category';

// 异步初始化接口：初始化商品数据
export const __init = async(force = false) => {
  // 获取商品列表
  let product_list_data = (await infos.productList.make('获取已上架的商品列表')).data.data;
  // 没有商品时,创建商品并上架商品
  if (product_list_data.total === 0) {
    for (let i = 0; i < 10; i++) {
      await infos.productCreate.make();
      await infos.productUpdateStatus.make();
    }
  }
};

async function getOneID(scene = 'default') {
  let data = (await infos.productList.make(scene)).data.data;
  return data.data[0].id;
}

export const infos = {

  'productInfo': {
    '__proto__': base.productInfo,
    'api': apis.productInfo,
    'desc': " 查看商品(获取商品详情)",
    'url': '/scmsapi/product/info',
    'defaults': {
      id: '120',
      model: 'edit',
    },
    'keyList':['data.supplier_info'],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(),
          };
        }
      },
      {
        'scene': '已下架的商品',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID('获取已下架的商品列表'),
          };
        }
      },
      {
        'scene': 'id',
        'desc': '',
        'type': INNER,
        'func': async (ctx, id) => {
          ctx.generated = {
            id,
          };
        }
      },
    ],
  },

  'productCreate': {
    '__proto__': base.productCreate,
    'api': apis.productCreate,
    'desc': " 创建商品",
    'url': '/scmsapi/product/create',
    'defaults': {
      "is_top": '0',
      "sort": "1",
      "title": "测试商品",
      "unit": "个",
      "supplier_id": "",
      "description": "xxxxxxxxxxxxxxxxxxxxxxxx",
      "product_images": [{
        "file_key": "xxx",
        "url": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg",
        "type": "1"
      },
        {
          "file_key": "xxx",
          "url": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg",
          "type": "2"
        },
        {
          "file_key": "xxx",
          "url": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg",
          "type": "3"
        }
      ],
      "product_spec": [{
        "product_spec_key": "重量",
        "product_spec_value": [
          "1KG",
          "2KG"
        ]
      },
        {
          "product_spec_key": "材质",
          "product_spec_value": [
            "牛皮",
            "鳄鱼皮"
          ]
        }
      ],
      "product_freight": [{
        "amount": 1,
        "area_id": [
          360,
          361,
          362
        ]
      },
        {
          "amount": 2,
          "area_id": [
            365,
            366,
            367
          ]
        }
      ],
      "product_category_id": '',
      "limit_number": '1000',
      shipping_mode: 1,
      shipping_days: 3,
      is_7day_return_supported: 1,
      "product_sku": [{
          "product_spec": [{
          "product_spec_key": "重量",
          "product_spec_value": "1KG"
        },
          {
            "product_spec_key": "材质",
            "product_spec_value": "牛皮"
          }
        ],
        "price": 1,
        "status": 1,
        "inventory_quantity": 1000,
        "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
      },
        {
          "product_spec": [{
            "product_spec_key": "重量",
            "product_spec_value": "1KG"
          },
            {
              "product_spec_key": "材质",
              "product_spec_value": "鳄鱼皮"
            }
          ],
          "price": 1,
          "status": 1,
          "inventory_quantity": 1000,
          "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
        },
        {
          "product_spec": [{
            "product_spec_key": "重量",
            "product_spec_value": "2KG"
          },
            {
              "product_spec_key": "材质",
              "product_spec_value": "牛皮"
            }
          ],
          "price": 1,
          "status": 1,
          "inventory_quantity": 1000,
          "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
        },
        {
          "product_spec": [{
            "product_spec_key": "重量",
            "product_spec_value": "2KG"
          },
            {
              "product_spec_key": "材质",
              "product_spec_value": "鳄鱼皮"
            }
          ],
          "price": 1,
          "status": 1,
          "inventory_quantity": 1000,
          "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
        }
      ]
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            product_category_id: await utils_product_category.getCategoryOneID()
          };
        }
      },
      {
        'scene': '有供货商',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            supplier_id: await shop.getSupplier(),
            product_category_id: await utils_product_category.getCategoryOneID()
          };
        }
      },
    ],
  },

  'productUpdate': {
    '__proto__': base.productUpdate,
    'api': apis.productUpdate,
    'desc': " 编辑商品",
    'url': '/scmsapi/product/update',
    'defaults': {
      "is_top": '1',
      "id": 123,
      "sort": 2,
      "title": "测试商品",
      "unit": "个",
      "supplier_id": 5,
      "description": "这是一个开发自测编辑商品的商品",
      "product_images": [{
        "type": 1,
        "url": "https://admin.bb.uninote.com.cn/oss?path=img/58ec5568199749c991fc848e85bd7516.jpg"
      }, {
        "type": 2,
        "url": "https://admin.bb.uninote.com.cn/oss?path=img/58ec5568199749c991fc848e85bd7516.jpg"
      }, {
        "type": 3,
        "url": "https://admin.bb.uninote.com.cn/oss?path=img/58ec5568199749c991fc848e85bd7516.jpg"
      }],
      "product_spec": [{
        "product_spec_key": "重量",
        "product_spec_value": ["3KG", "5KG"]
      }, {
        "product_spec_key": "材质",
        "product_spec_value": ["牛皮", "鳄鱼皮"]
      }, {
        "product_spec_key": "颜色",
        "product_spec_value": ["白色", "黑色"]
      }],
      "product_freight": [{
        "amount": 10,
        "area_id": [1]
      }, {
        "amount": 20,
        "area_id": [4, 5, 6,7,8,9]
      }],
      "product_category_id": '',
      "limit_number": '100',
      shipping_mode: 1,
      shipping_days: 3,
      is_7day_return_supported: 1,
      "product_sku": [{
        "product_spec": [{
          "product_spec_key": "颜色",
          "product_spec_value": "白色"
        }, {
          "product_spec_key": "重量",
          "product_spec_value": "3KG"
        }, {
          "product_spec_key": "材质",
          "product_spec_value": "牛皮"
        }],
        "price": 1,
        "status": 1,
        "inventory_quantity": 1000,
        "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
      },{
        "product_spec": [{
          "product_spec_key": "材质",
          "product_spec_value": "牛皮"
        },{
          "product_spec_key": "颜色",
          "product_spec_value": "黑色"
        }, {
          "product_spec_key": "重量",
          "product_spec_value": "3KG"
        }],
        "price": 2,
        "status": 1,
        "inventory_quantity": 1000,
        "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
      }, {
        "product_spec": [{
          "product_spec_key": "颜色",
          "product_spec_value": "白色"
        },{
          "product_spec_key": "重量",
          "product_spec_value": "3KG"
        }, {
          "product_spec_key": "材质",
          "product_spec_value": "鳄鱼皮"
        }],
        "price": 3,
        "status": 1,
        "inventory_quantity": 1000,
        "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
      }, {
        "product_spec": [{
          "product_spec_key": "颜色",
          "product_spec_value": "黑色"
        }, {
          "product_spec_key": "重量",
          "product_spec_value": "3KG"
        }, {
          "product_spec_key": "材质",
          "product_spec_value": "鳄鱼皮"
        }],
        "price": 4,
        "status": 1,
        "inventory_quantity": 1000,
        "image": "https://admin.bb.uninote.com.cn/oss?path=img/WechatIMG1047041.jpeg"
      },{
        "product_spec": [{
          "product_spec_key": "颜色",
          "product_spec_value": "白色"
        }, {
          "product_spec_key": "重量",
          "product_spec_value": "5KG"
        }, {
          "product_spec_key": "材质",
          "product_spec_value": "牛皮"
        }],
        "price": 5,
        "status": 1
      },{
        "product_spec": [{
          "product_spec_key": "颜色",
          "product_spec_value": "黑色"
        }, {
          "product_spec_key": "重量",
          "product_spec_value": "5KG"
        }, {
          "product_spec_key": "材质",
          "product_spec_value": "牛皮"
        }],
        "price": 6,
        "status": 1
      }, {
        "product_spec": [{
          "product_spec_key": "颜色",
          "product_spec_value": "白色"
        },{
          "product_spec_key": "重量",
          "product_spec_value": "5KG"
        }, {
          "product_spec_key": "材质",
          "product_spec_value": "鳄鱼皮"
        }],
        "price": 7,
        "status": 1
      }, {
        "product_spec": [{
          "product_spec_key": "颜色",
          "product_spec_value": "黑色"
        }, {
          "product_spec_key": "重量",
          "product_spec_value": "5KG"
        }, {
          "product_spec_key": "材质",
          "product_spec_value": "鳄鱼皮"
        }],
        "price": 8,
        "status": 2
      }]
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = (await infos.productInfo.make('已下架的商品')).data.data;
        }
      },
      {
        'scene': '库存',
        'desc': '设置所有的sku库存为特定值',
        'type': NORMAL,
        'func': async (ctx, id = 0, quantity = 10) => {
          if (id) {
            // 先下架
            await infos.productUpdateStatus.make('args', id, 2);
            ctx.generated = (await infos.productInfo.make('id', id)).data.data;
          } else {
            ctx.generated = (await infos.productInfo.make('已下架的商品')).data.data;
          }
          ctx.generated.product_sku.forEach((sku) => {
            sku.inventory_quantity = quantity;
          })
        },
        async after(ctx) {
          // 再上架
          await infos.productUpdateStatus.make('args', ctx.generated.id, 1);
        },
      },
    ],
  },

  'productList': {
    '__proto__': base.productList,
    'api': apis.productList,
    'desc': " 获取商品列表",
    'url': '/scmsapi/product/list',
    'defaults': {
      id: '',
      start_time: '',
      end_time: '',
      supplier_name: '',
      title: '',
      status: '',
      page: '',
      page_size: '',
      product_category_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },{
        'scene': '获取已上架的商品列表',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = {
            status: 1
          };
        }
      },{
        'scene': '获取已下架的商品列表',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = {
            status: 2
          };
        }
      }
    ],
  },

  'productExport': {
    '__proto__': base.productExport,
    'api': apis.productExport,
    'desc': "导出商品列表(本接口请采用get请求，请求参数与/scmsapi/product/list接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)",
    'url': '/scmsapi/product/export',
    'defaults': {
      start_time: '',
      end_time: '',
      supplier_name: '',
      title: '',
      status: '',
      product_category_id: '',
      export_num: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productUpdateStatus': {
    '__proto__': base.productUpdateStatus,
    'api': apis.productUpdateStatus,
    'desc': " 更新商品状态(上架|下架|删除)",
    'url': '/scmsapi/product/updateStatus',
    'defaults': {
      id: '120',
      status: '2',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let data = (await infos.productList.make()).data.data;
          ctx.generated = {
            id : data.data[0].id,
            status : data.data[0].status == 1 ? 2 : 1
          };
        }
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, id, status) => {
          ctx.generated = {
            id,
            status,
          };
        }
      },
    ],
  },

  'productSetProductSort': {
    '__proto__': base.productSetProductSort,
    'api': apis.productSetProductSort,
    'desc': " 设置商品排序",
    'url': '/scmsapi/product/setProductSort',
    'defaults': {
      id: '120',
      pre_product_id: '119',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let data = (await infos.productList.make()).data.data;
          ctx.generated = {
            id: data.data[0].id,
            pre_product_id: data.data[1].id,
          };
        }
      },
    ],
  },

  'productProductRecommendList': {
    '__proto__': base.productProductRecommendList,
    'api': apis.productProductRecommendList,
    'desc': " 获取商品推荐列表",
    'url': '/scmsapi/product/productRecommendList',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productProductRecommend': {
    '__proto__': base.productProductRecommend,
    'api': apis.productProductRecommend,
    'desc': " 设置商品推荐",
    'url': '/scmsapi/product/productRecommend',
    'defaults': {
      "product_ids": [
        115,
        116,
        117,
        118
      ]
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          let data = (await infos.productList.make('获取已上架的商品列表')).data.data;
          ctx.generated = {
            product_ids:[data.data[0].id]
          };
        }
      },
    ],
  }
};
