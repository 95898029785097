import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../consts';
import {infos as base} from '../generated/cms';
import * as apis from '../../api/cms';

export const infos = {

  'login': {
    '__proto__': base.login,
    'api': apis.login,
    'desc': "登录",
    'url': '/cmsapi/admin/user/login.html',
    'defaults': {
      username: '',
      password: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'serviceList': {
    '__proto__': base.serviceList,
    'api': apis.serviceList,
    'desc': "服务列表",
    'url': '/cmsapi/admin/service/index.html',
    'defaults': {
      page: '',
      limit: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'dispatchOrder': {
    '__proto__': base.dispatchOrder,
    'api': apis.dispatchOrder,
    'desc': "派单",
    'url': '/cmsapi/admin/service/edit_order.html',
    'defaults': {
      orderId: '',
      staffId: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'createrCoupon': {
    '__proto__': base.createrCoupon,
    'api': apis.createrCoupon,
    'desc': "创建优惠券",
    'url': '/cmsapi/admin/coupon/add.html',
    'defaults': {
      type: '',
      nature: '',
      servicesIDs: '',
      num: '',
      useTime: '',
      useTime_start: '',
      useTime_end: '',
      pickTime: '',
      pickTime_start: '',
      pickTime_end: '',
      is_use: '',
      is_pick: '',
      is_child: '',
      is_show: '',
      remarks: '',
      layuiTreeCheck: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'editStaff': {
    '__proto__': base.editStaff,
    'api': apis.editStaff,
    'desc': "编辑员工",
    'url': '/cmsapi/admin/staff/edit.html',
    'defaults': {
      staffId: '',
      city_id: '',
      tag_ids: '',
      nick: '',
      tel: '',
      sex: '',
      hometown_name: '',
      identity_card: '',
      identity_card_a: '',
      identity_card_b: '',
      health: '',
      commission_ratio: '',
      desc: '',
      status: '',
      remarks: '',
      type: '',
      layuiTreeCheck: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'editService': {
    '__proto__': base.editService,
    'api': apis.editService,
    'desc': "编辑服务",
    'url': '/cmsapi/admin/service/edit.html',
    'defaults': {
      serviceId: '',
      title: '',
      adCode: '',
      hideAdCodeIds: '',
      spec_unit: '',
      spec_unit_time: '',
      spec_low: '',
      number1: '',
      spec: '',
      tag_ids: '',
      icon: '',
      tel: '',
      original_price: '',
      present_price: '',
      business_hours: '',
      place: '',
      subtitle: '',
      homeTitle: '',
      is_hour: '',
      sort: '',
      status: '',
      abstract: '',
      describe: '',
      layuiTreeCheck: '',
      integral_type: '',
      integral_num: '',
      advantage: '',
      service_spec: '',
      service_reward_specs: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'rewardAscension': {
    '__proto__': base.rewardAscension,
    'api': apis.rewardAscension,
    'desc': "佣金提升",
    'url': '/cmsapi/admin/service/rewardAscension.html',
    'defaults': {
      orderid: '',
      reward_ascension: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'getServiceSubOrderSpecs': {
    '__proto__': base.getServiceSubOrderSpecs,
    'api': apis.getServiceSubOrderSpecs,
    'desc': "获取补单规格信息",
    'url': '/cmsapi/admin/service/getServiceSubOrderSpecs.html',
    'defaults': {
      serviceId: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'editServiceSubOrderSpecs': {
    '__proto__': base.editServiceSubOrderSpecs,
    'api': apis.editServiceSubOrderSpecs,
    'desc': "添加/编辑服务补单规格",
    'url': '/cmsapi/admin/service/edit_service_sub_order_spec.html',
    'defaults': {
      serviceId: '',
      spec: '',
      peak_price: '',
      low_peak_price: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'delServiceSubOrderSpecs': {
    '__proto__': base.delServiceSubOrderSpecs,
    'api': apis.delServiceSubOrderSpecs,
    'desc': "删除补单规格信息",
    'url': '/cmsapi/admin/service/del_service_sub_order_spec.html',
    'defaults': {
      id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
