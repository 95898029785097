import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/client/shop/product';
import * as utils_product_category  from '@/utils/product_category';
import * as apis from '../../../../api/client/shop/product';
import {infos as scms_product} from '../../scms/product';

async function getOneID(index = 0) {
  let data = (await infos.productList.make()).data.data;
  return data.info[index].id;
}

export const infos = {

  'productInfo': {
    '__proto__': base.productInfo,
    'api': apis.productInfo,
    'desc': " [商城模块]商品详情",
    'url': '/api/product/info',
    'defaults': {
      id: '120',
    },
    'nullList': ['data.info.productPromotion'],
    'keyList':['data.info.coupon_list.0'],
    'typeList': ['data.info.coupon_list.0.coupon_limit_amount'],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(),
          };
        }
      },
    ],
  },

  'productSkuList': {
    '__proto__': base.productSkuList,
    'api': apis.productSkuList,
    'desc': " [商城模块]商品SKU列表",
    'url': '/api/product/product-sku-list',
    'defaults': {
      id: '120',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(),
          };
        }
      },
      {
        'scene': '2nd',
        'desc': '',
        'func': async (ctx) => {
          ctx.generated = {
            id: await getOneID(1),
          };
        }
      },
      {
        'scene': 'args',
        'desc': '',
        'type': INNER,
        'func': async (ctx, id) => {
          ctx.generated = {
            id,
          };
        }
      },
    ],
  },

  'productList': {
    '__proto__': base.productList,
    'api': apis.productList,
    'desc': " [商城模块]商品列表",
    'url': '/api/product/list',
    'defaults': {
      is_recommend: false,
      pageSize: '10',
      page: '1',
      product_category_id: '',
      keyword_search: '',
      product_promotion_category_id: '',
    },
    "nullList": ["data.info.0.productPromotion"],
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
      {
        'scene': 'product_category',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {
            product_category_id: await utils_product_category.getCategoryOneID()
          };
        }
      },
      {
        'scene': '有库存',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx, topN = 1, quantity = 10) => {
          let res = await ctx.request2();
          for (let i = 0; i < topN; i++) {
            let product = res.data.data.info[i];
            await scms_product.productUpdate.make('库存', product.id, quantity);
          }
          ctx.generated = {
          };
        }
      },
    ],
  },

  'hotKeywordSearchList': {
    '__proto__': base.hotKeywordSearchList,
    'api': apis.hotKeywordSearchList,
    'desc': " [商城模块]热搜关键词列表",
    'url': '/api/product/hot-keyword-search-list',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productPromotionCategory': {
    '__proto__': base.productPromotionCategory,
    'api': apis.productPromotionCategory,
    'desc': " [商城模块]商品活动分类列表",
    'url': '/api/product/product-promotion-category',
    'defaults': {
      pageSize: '10',
      page: '1',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
