import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/auth';
import * as apis from '../../../api/scms/auth';
import * as shop from "../../../tests/shop";

export const infos = {

  'login': {
    '__proto__': base.login,
    'api': apis.login,
    'desc': "登录",
    'url': '/scmsapi/auth/login',
    'defaults': {
      username: 'admin',
      password: '123456',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'logout': {
    '__proto__': base.logout,
    'api': apis.logout,
    'desc': "退出登录",
    'url': '/scmsapi/auth/logout',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NO_BATCH,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'menu': {
    '__proto__': base.menu,
    'api': apis.menu,
    'desc': "获取登录菜单",
    'url': '/scmsapi/auth/menu',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'updatePwd': {
    '__proto__': base.updatePwd,
    'api': apis.updatePwd,
    'desc': "修改密码",
    'url': '/scmsapi/auth/updatePwd',
    'defaults': {
      old_password: '',
      password: '',
      confirm_pwd: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
