/*引入封装好的请求方法*/
import {dajxHttp} from '../../utils/request.js';
const post = dajxHttp.post;

/**
 * @desc 用户钱包余额
 * @param uid 用户ID
 * @returns {Promise<*>}
 {
  "code": 1,
  "message": "success!",
  "data": {
    "info": {
      // 余额
      "balance": "100"
    }
  }
}
 */
export async function get_balance(uid) {
    return await post({
        url: '/api/wallet/get-balance',
        data: {uid}
    });
}

/**
 * @desc 账单明细
 * @param uid 用户ID
 * @param status 状态 0审核中 1已完成 2已驳回 3失败
 * @param start 开始时间
 * @param end 结束时间
 * @param page 当前页
 * @param offset 每页显示条数
 * @param type 账单类型 提现 or 返佣 0 未知 1 奖励 2提现
 * @returns {Promise<*>}
 {
	"code": 1,
	"message": "success!",
	"data": {
		"info": {
		    "__list":"k",
			"list": [
				{
				    // 流水id
					"id": "3",
					// 钱包id
					"wallet_id": "1",
					// 金额
					"amount": "99.00",
					// 备注
					"remark": "服务a返佣",
					// 状态 状态 0审核中 1已完成 2已驳回 3失败
					"status": "0",
					// 类型 0 未知 1 奖励 2提现
					"type": "1",
					// 创建时间
					"time": "1599422767",
					// 修改时间
					"update_time": "1599669767"
				}
			],
			"total_page": 1
		}
	}
}
 */
export async function billing_details(uid, status, start, end, page, offset, type) {
    return await post({
        url: '/api/wallet/billing-details',
        data: {uid, status, start, end, page, offset, type}
    });
}

/**
 * @desc 判断该用户是否授权绑定
 * @param uid 用户ID
 * @returns {Promise<*>}
 {
    "code": 1,
    "message": "success!",
    "data": {
        "info": {
            // 是否绑定
            "is_bind": true,
            "__user_info": "i",
            "user_info": {
                // 昵称
                "nick_name": "哄哄",
                // 头像地址
                "avatar_url": "https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM6ZibrWUEqcKP6MqFiajWKm0gibrvuoIqlICdN7ibaHtI7G2APPkZZvz0btsLkatakW9698Xmj2kSmKrg/132",
                // 微信unionid
                "unionid": "oTmHYvpnfTH0SX_ubIKfoFpzDdnE"
            }
        }
    }
}
 */
export async function is_wx_bind(uid) {
    return await post({
        url: '/api/wallet/is-wx-bind',
        data: {uid}
    });
}

/**
 * @desc 发起提现
 * @param uid 用户ID
 * @param amount 提现金额(单位分)
 * @returns {Promise<*>}
 {
	"code": 1,
	"message": "success!",
	"data": {
	    // true 提现成功 false提现失败
		"info": true
	}
}
 */
export async function supply_withdraw(uid, amount) {
    return await post({
        url: '/api/wallet/supply-withdraw',
        data: {uid, amount}
    });
}
