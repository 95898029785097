import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../consts';
import {infos as base} from '../../generated/scms/product';
import * as apis from '../../../api/scms/product';

export const infos = {

  'productInfo': {
    '__proto__': base.productInfo,
    'api': apis.productInfo,
    'desc': " 查看商品(获取商品详情)",
    'url': '/scmsapi/product/info',
    'defaults': {
      id: '',
      model: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productCreate': {
    '__proto__': base.productCreate,
    'api': apis.productCreate,
    'desc': " 创建商品",
    'url': '/scmsapi/product/create',
    'defaults': {
      is_top: '',
      sort: '',
      title: '',
      unit: '',
      supplier_id: '',
      description: '',
      product_images: '',
      product_spec: '',
      product_freight: '',
      product_sku: '',
      product_category_id: '',
      limit_number: '',
      shipping_mode: '',
      shipping_days: '',
      is_7day_return_supported: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productUpdate': {
    '__proto__': base.productUpdate,
    'api': apis.productUpdate,
    'desc': " 编辑商品",
    'url': '/scmsapi/product/update',
    'defaults': {
      is_top: '',
      id: '',
      sort: '',
      title: '',
      unit: '',
      supplier_id: '',
      description: '',
      product_images: '',
      product_spec: '',
      product_freight: '',
      product_sku: '',
      product_category_id: '',
      limit_number: '',
      shipping_mode: '',
      shipping_days: '',
      is_7day_return_supported: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productList': {
    '__proto__': base.productList,
    'api': apis.productList,
    'desc': " 获取商品列表",
    'url': '/scmsapi/product/list',
    'defaults': {
      id: '',
      start_time: '',
      end_time: '',
      supplier_name: '',
      title: '',
      status: '',
      page: '',
      page_size: '',
      product_category_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productExport': {
    '__proto__': base.productExport,
    'api': apis.productExport,
    'desc': "导出商品列表(本接口请采用get请求，请求参数与/scmsapi/product/list接口的请求参数一致，本接口暂不支持在powder请求，因为powder没法下载文件)",
    'url': '/scmsapi/product/export',
    'defaults': {
      start_time: '',
      end_time: '',
      supplier_name: '',
      title: '',
      status: '',
      product_category_id: '',
      export_num: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productUpdateStatus': {
    '__proto__': base.productUpdateStatus,
    'api': apis.productUpdateStatus,
    'desc': " 更新商品状态(上架|下架|删除)",
    'url': '/scmsapi/product/updateStatus',
    'defaults': {
      id: '',
      status: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productSetProductSort': {
    '__proto__': base.productSetProductSort,
    'api': apis.productSetProductSort,
    'desc': " 设置商品排序",
    'url': '/scmsapi/product/setProductSort',
    'defaults': {
      id: '',
      pre_product_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productProductRecommendList': {
    '__proto__': base.productProductRecommendList,
    'api': apis.productProductRecommendList,
    'desc': " 获取商品推荐列表",
    'url': '/scmsapi/product/productRecommendList',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'productProductRecommend': {
    '__proto__': base.productProductRecommend,
    'api': apis.productProductRecommend,
    'desc': " 设置商品推荐",
    'url': '/scmsapi/product/productRecommend',
    'defaults': {
      product_ids: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
