import {NORMAL, INNER, GENE_ONLY, NO_BATCH} from '../../../consts';
import {infos as base} from '../../../generated/scms/blindbox/order_box';
import * as apis from '../../../../api/scms/blindbox/order_box';

export const infos = {

  'list': {
    '__proto__': base.list,
    'api': apis.list,
    'desc': "获取盲盒发货列表\n",
    'url': '/scmsapi/order_box/list',
    'defaults': {
      status: '',
      name: '',
      phone: '',
      wx_no: '',
      bag_name: '',
      has_print: '',
      start_time: '',
      end_time: '',
      page: '',
      page_size: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'combineList': {
    '__proto__': base.combineList,
    'api': apis.combineList,
    'desc': "获取盲盒发货列表(合并)\n",
    'url': '/scmsapi/order_box/combineList',
    'defaults': {
      status: '',
      name: '',
      phone: '',
      wx_no: '',
      bag_name: '',
      has_print: '',
      start_time: '',
      end_time: '',
      page: '',
      page_size: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'increasePrintCount': {
    '__proto__': base.increasePrintCount,
    'api': apis.increasePrintCount,
    'desc': "增加发货单打印次数\n",
    'url': '/scmsapi/order_box/increasePrintCount',
    'defaults': {
      dispatchIDs: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressAddOrder': {
    '__proto__': base.expressAddOrder,
    'api': apis.expressAddOrder,
    'desc': "物流生成运单\n",
    'url': '/scmsapi/order_box/expressAddOrder',
    'defaults': {
      dispatchIDs: '',
      delivery_id: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressBindOrder': {
    '__proto__': base.expressBindOrder,
    'api': apis.expressBindOrder,
    'desc': "物流手动绑定运单\n",
    'url': '/scmsapi/order_box/expressBindOrder',
    'defaults': {
      dispatchIDs: '',
      express_number: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressCancelOrder': {
    '__proto__': base.expressCancelOrder,
    'api': apis.expressCancelOrder,
    'desc': "物流取消运单\n",
    'url': '/scmsapi/order_box/expressCancelOrder',
    'defaults': {
      orderIDs: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'doDispatch': {
    '__proto__': base.doDispatch,
    'api': apis.doDispatch,
    'desc': "卖家发货\n",
    'url': '/scmsapi/order_box/doDispatch',
    'defaults': {
      orderIDs: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressOrderPath': {
    '__proto__': base.expressOrderPath,
    'api': apis.expressOrderPath,
    'desc': "查询运单轨迹\n",
    'url': '/scmsapi/order_box/expressOrderPath',
    'defaults': {
      orderID: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressTestUpdateOrder': {
    '__proto__': base.expressTestUpdateOrder,
    'api': apis.expressTestUpdateOrder,
    'desc': "模拟更新订单状态\n",
    'url': '/scmsapi/order_box/expressTestUpdateOrder',
    'defaults': {
      order_id: '',
      waybill_id: '',
      action_time: '',
      action_type: '',
      action_msg: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressAccountBind': {
    '__proto__': base.expressAccountBind,
    'api': apis.expressAccountBind,
    'desc': "绑定、解绑 物流公司\n",
    'url': '/scmsapi/order_box/expressAccountBind',
    'defaults': {
      is_bind: '',
      biz_id: '',
      delivery_id: '',
      password: '',
      remark_content: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressAccountAll': {
    '__proto__': base.expressAccountAll,
    'api': apis.expressAccountAll,
    'desc': "获取所有绑定的物流账号\n",
    'url': '/scmsapi/order_box/expressAccountAll',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressPrinterBind': {
    '__proto__': base.expressPrinterBind,
    'api': apis.expressPrinterBind,
    'desc': "配置面单打印员\n",
    'url': '/scmsapi/order_box/expressPrinterBind',
    'defaults': {
      is_bind: '',
      openid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressPrinterAll': {
    '__proto__': base.expressPrinterAll,
    'api': apis.expressPrinterAll,
    'desc': "获取所有打印员\n",
    'url': '/scmsapi/order_box/expressPrinterAll',
    'defaults': {
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'expressRid': {
    '__proto__': base.expressRid,
    'api': apis.expressRid,
    'desc': "查询rid信息\n",
    'url': '/scmsapi/order_box/expressRid',
    'defaults': {
      rid: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  },

  'orderBoxList': {
    '__proto__': base.orderBoxList,
    'api': apis.orderBoxList,
    'desc': "背包盲盒列表\n",
    'url': '/scmsapi/order_box/orderBoxList',
    'defaults': {
      page: '',
      page_size: '',
      id: '',
      order_no: '',
      wx_no: '',
      uid: '',
      bag_ids: '',
      bag_name: '',
      bag_status: '',
      bag_type: '',
      box_name: '',
      advanced: '',
    },
    'generated': {},
    'user': {},
    'geneArgs': [
      {
        'scene': 'default',
        'desc': '',
        'type': NORMAL,
        'func': async (ctx) => {
          ctx.generated = {};
        }
      },
    ],
  }
};
